<!-- menu horizontal -->
<div class="d-flex">

    <button class="btn-menu bgcolor-indice pl-md-4 py-3" mat-flat-button (click)="drawer3.toggle()">Índice</button>

    <div class="col titulo-madriguera">
    </div>

    <button class="btn-menu bgcolor-volver pr-md-4 py-3" mat-flat-button (click)="volver()">Volver</button>
</div>

<mat-drawer-container class="indice-container" [hasBackdrop]="'true'">

    <!-- menu lateral -->
    <mat-drawer class="menu-indice sidenav-fixed" #drawer3 [mode]="'over'">
        <div class="contenedor-menu">

            <!-- cabezal del titulo -->
            <div class="cerrar-Indice-container">
                <h1 class="titulo-cerrar">Índice</h1>
                <a class="btn-cerrar" (click)="drawer3.toggle()">
                    <i class="fas fa-times color-naranja"></i>
                </a>
            </div>

            <!-- arbol de contenido -->
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

                <!-- esta es para nodos sin hijos -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>

                    <a (click)="scrollTo(node.id)" class="btn-nodo sin-hijos text-wrap" mat-flat-button>
                        <i class="fas fa-bookmark mr-2"></i> {{node.name}}
                    </a>
                </mat-tree-node>

                <!-- esta es para los nodos con hijos -->
                <mat-tree-node class="nodo-hijo" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                    <button class="btn-nodo" mat-flat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                        <span>
                            {{node.name}}
                        </span>
                    </button>

                </mat-tree-node>
            </mat-tree>
        </div>
    </mat-drawer>

    <!-- contenido -->
    <mat-drawer-content>
        <a id="indiceBtn" (click)="drawer3.toggle()" class="btn btn-indice-onscroll waves-effect wow fadeInLeft" hidden>
            Ver Índice
        </a>

        <a id="scrollBtn" (click)="scrollTop()" class="btn btn-floating btn-scrollUp waves-effect wow fadeInUp" hidden>
            <i class="fas fa-arrow-up"></i>
        </a>

        <!-- introduccion -->
        <section id="sec1" class="bg-blanco">
            <!--logo-->
            <div class="container d-flex justify-content-center">
                <div class="mt-sm-0 mt-5">
                    <img class="img-section" src="assets/images/tituloMadriguera.svg" alt="SURSOFT -  La Madriguera Libros">
                </div>
            </div>
            <br>

            <div class="container padding-sec">

                <h2 class="h2-section">Introducción</h2>

                <!--cuerpo-->
                <div class="d-xl-flex">

                    <!--descripcion-->
                    <article class="color-article">

                        <p>
                            La aplicación de lamadrigueralibros.com fue desarrollada originalmente como una <b>herramienta de gestión</b> para el manejo interno de la empresa, posteriormente el cliente solicito una expansión de la misma, en donde se pudiera
                            incorporar una funcionalidad de tipo <b>e-Commerce</b> y el agregado de una nueva categoría (juegos de mesa) con <b>integración a Mercado Pago</b> para la recepción de los pagos.
                        </p>

                        <h3 class="mb-0"><b><u>RESUMEN</u></b></h3>
                        <p>
                            Para llevar a cabo el proyecto utilice .Net Core 5, razor pages, MVC, Boostrap, SQL Server para la DB e integración con Mercado Pago para las compras online.
                        </p>
                        <p>En este proyecto se ofrecen las siguientes <b>funcionalidades</b>:</p>
                        <ul class="mb-0">
                            <li>Control de <b>usuarios y proveedores</b></li>
                            <li>Agilización de las tareas de <b>control de stock</b> libros, Cds y juegos de mesa</li>
                            <li>CRUD para <b>gestión de catálogos masivos</b></li>
                            <li>CRUD para <b>post de la página</b> principal</li>
                            <li>Marcado de precios de los productos a través de <b>códigos QR</b></li>
                            <li> <b>Administración de ventas</b> </li>
                            <li><b>Carrito de compras</b> integrado a Mercado Pago</li>
                            <li><b>Reportes</b> de:
                                <ul>
                                    <li>Artículos más vendidos</li>
                                    <li>Cantidad de ventas</li>
                                    <li>Recaudación de la empresa</li>
                                    <li>Ventas por proveedor</li>
                                    <li>Comparación de ventas Online vs Local</li>
                                    <li>Resumen de ganancias</li>
                                </ul>
                            </li>

                        </ul>
                    </article>

                    <!--imagenes-->
                    <div class="col-xl-5 d-xl-block text-center">
                        <a href="assets/images/madriguera.jpg" data-lightbox="photos">
                            <img class="img-section" src="assets/images/madriguera.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <a href="assets/images/madriguera2.png" data-lightbox="photos">
                            <img class="img-section mt-4" src="assets/images/madriguera2.png" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <!-- Usuarios del sistema -->
        <section id="sec2" class="bg-gris">
            <div class="container padding-sec">
                <h2 class="h2-section">Usuarios del sistema</h2>

                <article class="color-article">
                    <p>
                        Originalmente la aplicación contaba con un tipo de usuario llamado Admin el cual era el encargado de todo lo referente a la administración propia del sistema, como pueden ser gestión de proveedores, gestión de los productos, generación de reportes, etc.
                    </p>
                    <p>
                        En la versión 2 se incorpora un usuario de tipo cliente, que interactúa en el sistema realizando los pedidos.
                    </p>
                    <p>
                        <b>Actores del sistema:</b>
                    </p>
                    <ul>
                        <li>Super Administrador</li>
                        <li>Administradores</li>
                        <li>Clientes</li>
                    </ul>
                </article>
            </div>
        </section>

        <!-- Registro de clientes -->
        <section id="sec3" class="bg-blanco">
            <div class="container padding-sec">
                <!-- titulo -->
                <h2 class="h2-section">Registro de clientes</h2>

                <article class="color-article">
                    <p>
                        Los clientes deberán registrarse proporcionando una serie de datos personales que servirán para realizar los envíos de sus pedidos.
                    </p>
                    <p>
                        Para ello en el menú superior encontraran los botones de, <b>Registrarse</b> y <b>Entrar</b> en caso de que ya esten registrados.
                    </p>

                    <a href="assets/images/img-madriguera/1.jpg" data-lightbox="photos" class="img-container">
                        <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/1.jpg" alt="SURSOFT -  Madriguera Libros">
                    </a>

                    <h2>Registro</h2>
                    <p>Al darle al botón de registrarse se pasará al formulario de registro que se muestra a continuación:</p>

                    <p>
                        Una vez completados los datos requeridos, se le enviara al cliente un mail con un token de seguridad para que confirme su correo y así pueda activar su cuenta con el siguiente formato:
                    </p>

                    <div class="row justify-content-center">
                        <a href="assets/images/img-madriguera/2.jpg" data-lightbox="photos" class="img-container pr-md-4">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/2.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>

                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/3.jpg" data-lightbox="photos" class="img-container">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/3.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>
                    </div>
                </article>
            </div>

        </section>

        <!-- Logueo -->
        <section id="sec4" class="bg-gris">
            <div class="container padding-sec">
                <!-- titulo -->
                <h2 class="h2-section">Logueo</h2>

                <article class="color-article">
                    <p>Una vez completado el registro, cualquier usuario del sistema podrá ingresar a través del botón de entrar y completando el formulario con sus credenciales.</p>
                    <p>Según el nivel que tenga el mismo, el sistema mostrara mas o menos elementos de interacción.</p>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/4.jpg" data-lightbox="photos" class="img-container pr-md-4">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/4.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/5.jpg" data-lightbox="photos" class="img-container">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/5.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>
                    </div>
                </article>
            </div>

        </section>

        <!-- Secciones de la aplicación -->
        <section id="sec5" class="bg-blanco">
            <div class="container padding-sec">
                <!-- titulo -->
                <h2 class="h2-section">Secciones de la aplicación</h2>

                <article class="color-article">
                    <p>La aplicación cuenta con dos menús en su interfaz, una <b>horizontal</b> (img 3.1) y una <b>lateral</b> (img 3.2).</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/6.jpg" data-lightbox="photos" class="img-container">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/6.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>imagen 3</p>
                    </div>
                </article>
            </div>
        </section>

        <!-- Descripcion Menú horizontal -->
        <section id="sec6" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- titulo -->
                    <h2>Menú horizontal</h2>
                    <p>En este menú encontraremos <b>accesos rápidos</b> para:</p>
                    <ul>
                        <li><b>Escritorio</b>: aquí encontraremos todas las secciones de administración de la empresa (img. 4.1).</li>
                        <li><b>Carrito de compras</b>: aquí es donde los administradores finalizaran las compras de los clientes en el local y los clientes comunes encontraran sus productos seleccionados (img. 4.2).</li>
                        <li><b>Notificaciones</b>: aquí encontraremos notificaciones referentes a las compras de clientes y cancelaciones o cambios de estado de las mismas, tanto para administradores como para clientes (img. 4.3).</li>
                        <li><b>Perfil de usuario</b>: este desplegará la información de cada usuario, en donde podrán modificar sus datos incluido el password de su cuenta. En el caso de los clientes también se proporcionará un link a sus compras (anteriores)
                            para que puedan visualizar el estado de las mismas (img. 4.3).</li>
                    </ul>
                    <p>Estas secciones se describirán más adelante.</p>
                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/7.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/7.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 4 (visión Admin)</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/8.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/8.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>(visión Cliente)</p>
                        </div>
                    </div>
                </article>
            </div>

        </section>

        <!--Descripcion Menú lateral -->
        <section id="sec7" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- titulo -->
                    <h2>Menú lateral</h2>
                    <p>El menú lateral dispone de accesos a todas las páginas de la aplicación, tanto de <b>acceso público</b> para todos los usuarios (img. 5.1), como de <b>acceso privado</b> solo para administradores (img. 5.2).</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/9.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/9.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 5</p>
                    </div>
                </article>
            </div>

        </section>

        <!-- Páginas públicas -->
        <section id="sec8" class="bg-gris">
            <div class="container padding-sec">
                <!-- titulo -->
                <h2 class="h2-section">Páginas públicas</h2>

                <article class="color-article">
                    <p>Estas páginas contendrán información accesible para cualquier usuario.</p>
                    <ul>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec9')">Página de inicio</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec10')">Página de libros</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec11')">Página de CDs y Vinilos</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec12')">Página de Juegos de mesa</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec13')">Página de Recomendados</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec14')">Página de Destacados</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec15')">Página de Nosotros</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec16')">Página de Contacto</button> </li>
                    </ul>
                </article>
            </div>

        </section>

        <!-- Página de inicio -->
        <section id="sec9" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- titulo -->
                    <h2>Página de inicio</h2>
                    <p>Esta sección es la inicial del sistema, presenta el logo de la empresa y 4 secciones en donde se muestran (img. 6).:</p>
                    <ul>
                        <li>Productos <b>más vendidos</b> (10 productos)</li>
                        <li>Productos <b>recomendados</b> (10 productos en orden por fecha)</li>
                        <li>Productos <b>destacados</b> (10 productos en orden por fecha)</li>
                        <li><b>Posteos</b> recientes hechos por los administradores (10 posts más recientes). Dichos posteos y destacados serán descriptos más adelante en su sección correspondiente.</li>
                    </ul>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/10.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/10.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 6</p>
                    </div>

                </article>
            </div>

        </section>

        <!-- Página de libros -->
        <section id="sec10" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- titulo -->
                    <h2>Página de libros</h2>
                    <p>
                        En esta página se presentará un resumen de los libros más vendidos del sistema seguido de una lista con todos los libros del sistema.
                    </p>
                    <p>
                        Dicha lista podrá visualizarse en formato de tarjetas (por defecto) y en formato de lista pudiendo ser filtrada por cantidad, o búsqueda por palabras clave y en caso de administradores por Facturados o consignados.
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/11.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/11.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/12.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/12.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Vista de Tarjetas</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/13.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/13.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Vista de lista</p>
                        </div>
                    </div>
                </article>
            </div>

        </section>

        <!-- Página de música -->
        <section id="sec11" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- titulo -->
                    <h2>Página de música </h2>
                    <p>
                        En esta página al igual que en la de libros se presentará un resumen de los CDs más vendidos del sistema seguido de una lista con todos los Cds del sistema.
                    </p>
                    <p>
                        Dicha lista podrá visualizarse en formato de tarjetas (por defecto) y en formato de lista pudiendo ser filtrada por cantidad, o búsqueda por palabras clave y en caso de administradores por Facturados o consignados.
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/11.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/11.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/14.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/14.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Vista de Tarjetas</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/15.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/15.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Vista de lista</p>
                        </div>
                    </div>
                </article>
            </div>

        </section>

        <!-- Página de Juegos -->
        <section id="sec12" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- titulo -->
                    <h2>Página de Juegos</h2>
                    <p>
                        En esta página al igual que en la de libros y música se presentará un resumen de los Juegos más vendidos del sistema seguido de una lista con todos los Juegos de mesa del sistema.
                    </p>
                    <p>
                        Dicha lista podrá visualizarse en formato de tarjetas (por defecto) y en formato de lista pudiendo ser filtrada por cantidad, o búsqueda por palabras clave y en caso de administradores por Facturados o consignados como en los anteriores casos descriptos.
                    </p>
                </article>
            </div>

        </section>

        <!-- Página de Recomendados -->
        <section id="sec13" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- titulo -->
                    <h2>Página de Recomendados</h2>
                    <p>
                        En esta página se expondrán todos aquellos productos que los administradores hayan marcado como recomendados, sin límite, ordenados por fecha como en la página de inicio.
                    </p>
                    <p>
                        El formato de la página es la misma que en los anteriores contando con vistas de tipo Tarjetas o de tipo lista.
                    </p>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/16.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/16.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/17.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/17.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>
                    </div>
                </article>
            </div>

        </section>

        <!-- Página de Destacados -->
        <section id="sec14" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- titulo -->
                    <h2>Página de Destacados</h2>
                    <p>
                        En esta página se expondrán todos aquellos productos que los administradores hayan marcado como destacados, sin límite, ordenados por fecha como en la página de inicio.
                    </p>
                    <p>
                        El formato de la página es la misma que en la de recomendados contando con vistas de tipo Tarjetas o de tipo lista.
                    </p>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/18.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/18.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/19.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/19.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>
                    </div>
                </article>
            </div>

        </section>

        <!-- Página de Nosotros -->
        <section id="sec15" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Página de Nosotros</h2>
                    <p>
                        En esta sección se presenta una descripción de la empresa, objetivos, integrantes o cualquier información que se quiera dar de la misma.
                    </p>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/20.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/20.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>
                    </div>
                </article>
            </div>

        </section>

        <!-- Página de contacto -->
        <section id="sec16" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- titulo -->
                    <h2>Página de contacto</h2>

                    <p>
                        En esta página se ofrece a los usuarios toda la información de contacto de la empresa, dirección, teléfonos, correo electrónico y también un formulario de contacto directo en donde los usuarios podrán realizar sus consultas las cuales llegarán al correo
                        que el cliente elija (img. 8).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/21.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/21.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 8</p>
                    </div>
                </article>
            </div>

        </section>

        <!-- Paginas privadas introduccion -->
        <section id="sec17" class="bg-blanco">
            <div class="container padding-sec">
                <!-- titulo -->
                <h2 class="h2-section">Paginas privadas</h2>

                <article class="color-article">
                    <p>
                        Esta paginas les brindan a los administradores la posibilidad de manejar todo lo referente al sistema.
                    </p>
                    <p>Las mismas son:</p>
                    <ul>
                        <li><button (click)="scrollTo('#sec18')" class="link-pagina sin-borde">Página de escritorio</button> </li>
                        <li><button (click)="scrollTo('#sec19')" class="link-pagina sin-borde">Página de Usuarios</button> </li>
                        <li><button (click)="scrollTo('#sec20')" class="link-pagina sin-borde">Página de administradores</button> </li>
                        <li><button (click)="scrollTo('#sec21')" class="link-pagina sin-borde">Cambiar password</button> </li>
                        <li><button (click)="scrollTo('#sec22')" class="link-pagina sin-borde">Recuperar password</button> </li>
                        <li><button (click)="scrollTo('#sec23')" class="link-pagina sin-borde">Página de proveedores</button> </li>
                        <li>
                            <button (click)="scrollTo('#sec24')" class="link-pagina sin-borde">Página de catálogo</button>
                            <ul>
                                <li><button (click)="scrollTo('#sec25')" class="link-pagina sin-borde">Agregar productos con Excel</button></li>
                                <li><button (click)="scrollTo('#sec26')" class="link-pagina sin-borde">Editar productos con Excel</button></li>
                                <li><button (click)="scrollTo('#sec27')" class="link-pagina sin-borde">Eliminación de catálogo</button></li>
                                <li><button (click)="scrollTo('#sec28')" class="link-pagina sin-borde">Agregar un producto</button></li>
                                <li><button (click)="scrollTo('#sec29')" class="link-pagina sin-borde">Editar un producto</button></li>
                                <li><button (click)="scrollTo('#sec30')" class="link-pagina sin-borde">Eliminar un producto</button></li>
                                <li><button (click)="scrollTo('#sec31')" class="link-pagina sin-borde">Crear códigos QR</button></li>
                            </ul>
                        </li>
                        <li><button (click)="scrollTo('#sec32')" class="link-pagina sin-borde">Página de ventas</button> </li>
                        <li><button (click)="scrollTo('#sec33')" class="link-pagina sin-borde">Página de Recomendados</button> </li>
                        <li><button (click)="scrollTo('#sec34')" class="link-pagina sin-borde">Página de destacados</button> </li>
                        <li><button (click)="scrollTo('#sec35')" class="link-pagina sin-borde">Página de posts</button></li>
                        <li><button (click)="scrollTo('#sec36')" class="link-pagina sin-borde">Página de Deshabilitar compras web</button></li>
                    </ul>
                </article>
            </div>

        </section>

        <!-- Página de escritorio -->
        <section id="sec18" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- titulo -->
                    <h2>Página de escritorio</h2>
                    <p>Esta sección es accesible tanto del menú horizontal como del lateral como ya se indicó anteriormente.</p>
                    <p>A través de la misma se podrá acceder al resto de regiones del sistema de manera rápida.</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/22.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/22.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 9</p>
                    </div>
                </article>
            </div>

        </section>

        <!-- Página de Usuarios -->
        <section id="sec19" class="bg-blanco">
            <div class="container padding-sec">
                <article class="color-article">
                    <h2>Página de Usuarios</h2>
                    <p>En esta página se desplegará una tabla con todos los clientes del sistema.</p>
                    <p>En la misma los administradores tendrán acceso a los datos del cliente (img. 10.1, img. 11), excepto su password, y tendrán la posibilidad de Banear (deshabilitar) a un usuario si su comportamiento incumple con las reglas de la empresa
                        (img. 10.2).</p>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/23.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/23.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 10</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/25.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/25.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 11</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/24.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/24.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>

                    </div>
                </article>
            </div>

        </section>

        <!-- Página de administradores -->
        <section id="sec20" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <!-- descripcion -->
                    <h2>Página de administradores</h2>
                    <p>Aquí se desplegará una lista con todos los administradores del sistema, incluido el super administrador.
                    </p>
                    <p>En ella se podrá agregar (img. 12.1), modificar (img. 12.2) o eliminar (img. 12.3) un administrador del sistema.</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/26.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/26.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 12</p>
                    </div>

                    <!-- Agregar administrador -->
                    <h3><u>Agregar administrador</u> </h3>
                    <p>Para agregar un administrador deberemos hacer clic en el botón de agregar admin (img. 12.1), el cual nos desplegara una página con los datos requeridos para el mismo.</p>
                    <p>Una vez ingresados todos los datos del nuevo admin, se deberá dar clic en agregar admin (img. 13.1) para confirmar la acción. Si todo es correcto se nos devolverá a la página anterior y podremos ver en la lista al admin agregado (img.
                        14).
                    </p>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/27.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/27.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 13</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/28.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/28.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 14</p>
                        </div>
                    </div>

                    <!-- Editar administrador -->
                    <h3><u>Editar administrador</u> </h3>
                    <p>Para editar un administrador deberemos darle clic al botón de edición en la lista, el cual nos llevara a la página de edición del mismo (img. 15.1).</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/29.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/29.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 15</p>
                    </div>
                    <p>
                        Cabe destacar que no todos los campos pueden ser modificados, como se puede ver <b>el email no puede ser modificado</b> ya que es el identificador del usuario en el sistema. Si el mismo fue ingresado incorrectamente se deberá eliminar
                        al usuario directamente.
                    </p>
                    <p>
                        Tampoco es posible modificar el password por razones de seguridad. El único que puede realizar esa acción es el propietario de la cuenta para lo cual deberá estar logueado en el sistema o ingresar en la sección de recuperación de password, en donde se
                        le solicitara el mail que lo identifica en el sistema a donde se le enviaran instrucciones para la actualización del mismo.
                    </p>
                    <p>Una vez modificados los datos se deberá confirmar la acción haciendo clic en el botón de actualizar (img. 16.1). Se nos confirmara si la acción fue exitosa o no (img. 16.2).</p>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/30.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/30.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/31.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/31.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 16</p>
                        </div>
                    </div>

                    <!-- Eliminar administrador -->
                    <h3><u>Eliminar administrador</u> </h3>
                    <p>Para eliminar un administrador simplemente se deberá dar clic en el botón de eliminación (img. 17) de la lista respectivo el cual nos pedirá una confirmación de la acción por seguridad (img. 18).
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/32.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/32.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 17</p>
                    </div>
                    <p>Al presionar eliminar se nos informara si la acción tuvo éxito o no.</p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/33.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/33.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 18</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/34.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/34.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 19</p>
                        </div>
                    </div>
                </article>
            </div>

        </section>

        <!-- Cambiar de password -->
        <section id="sec21" class="bg-blanco">
            <div class="container padding-sec">
                <article class="color-article">
                    <h2>Cambiar de password</h2>
                    <p>Para actualizar el password del usuario se deberá hacer clic en el nombre del mismo (img. 20.1), en el menú horizontal e ingresar en la opción “Mi perfil” (img. 20.2)</p>
                    <p>Una vez en la página de actualización de datos del usuario se deberá hacer clic en el botón de cambiar password (img. 21.1), el cual nos desplegara la página donde se solicitarán: el password actual, el nuevo y la confirmación del
                        nuevo (img. 22).</p>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/35.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/35.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 20</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/36.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/36.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 21</p>
                        </div>
                    </div>

                    <p>Para confirmar el nuevo password solo deberemos dar clic en actualizar y listo.</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/37.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/37.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 22</p>
                    </div>
                </article>
            </div>

        </section>

        <!-- Recuperar password -->
        <section id="sec22" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Recuperar password</h2>
                    <p>En caso de haber <b>olvidado el password</b> de nuestra cuenta, en la página de logueo deberemos hacer clic en el link de <b>“¿Olvidaste tú password?”</b> (img. 23.1) el cual nos llevará a la página de recuperación que nos pedirá nuestro
                        mail de cuenta (img. 24).</p>
                    <p>Una vez ingresado nuestro mail y presionado el botón de recuperar password <b>se nos enviará un mail</b> con un link el cual nos llevará a la página de recuperación, en la cual ingresaremos el <b>nuevo password</b>.</p>
                    <p>Si todo fue correcto ya se habrá restaurado tu password y se podrá ingresar normalmente en la página de Login.</p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/38.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/38.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 23</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/39.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/39.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 24</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/40.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/40.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 25</p>
                        </div>
                    </div>

                </article>
            </div>

        </section>

        <!-- Página de proveedores -->
        <section id="sec23" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">

                    <!-- introduccion -->
                    <h2>Página de proveedores</h2>
                    <p>A través de esta página podremos agregar, editar o eliminar proveedores y manejar todo lo referente al catálogo de los mismos, tanto de manera individual como de forma masiva.</p>
                    <p>Para acceder a esta sección lo podremos hacer desde el <b>menú lateral</b> desde administración/Proveedores o desde la <b>página de escritorio</b> ingresando en el botón de proveedores.
                    </p>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/41.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/41.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 26</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/42.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/42.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 26</p>
                        </div>
                    </div>
                    <p>La página de proveedores nos desplegara una lista con todos los proveedores del sistema, mostrándonos sus datos básicos y opciones para <b>agregar nuevo</b> (img. 27.1), <b>detalles</b> (img. 27.2), <b>edición</b> (img. 27.3) o <b>eliminación</b>                        de los mismos (img. 27.4).</p>
                    <p>También presentara un botón de <b>Catalogo</b> en donde tendremos acceso a el mismo (img. 27.5).</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/43.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/43.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 27</p>
                    </div>

                    <!-- Agregar proveedor -->
                    <h3><u>Agregar proveedor</u></h3>
                    <p>
                        Como anteriormente se vio en <b>administradores</b>, para <b>agregar un nuevo proveedor</b> deberemos dar clic en el botón de <b>agregar nuevo</b> (img. 27.1). Esto nos llevara al formulario para ingresar los datos del mismo.
                    </p>
                    <p>
                        En el formulario (img. 28), vemos que solo podremos ingresar un teléfono, pero luego de confirmada la creación, mediante la <b>edición</b> se podrán agregar más teléfonos al proveedor, como veremos más adelante.
                    </p>
                    <p>Al crearse el proveedor, se redirigirá a la lista de proveedores indicándonos el éxito de la operación o no (img. 29.1).</p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/44.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/44.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 28</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/45.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/45.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 29</p>
                        </div>
                    </div>

                    <!-- Editar proveedor -->
                    <h3><u>Editar proveedor</u></h3>
                    <p>
                        En esta sección podremos editar los datos del proveedor y <b>agregar más teléfonos</b> de contacto para el mismo.
                    </p>
                    <p>
                        Para ingresar un nuevo teléfono tendremos que colocar el numero en el campo de teléfono y hacer clic en el botón de guardar.
                    </p>
                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/46.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/46.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 30</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/47.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/47.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                        </div>
                    </div>

                    <!-- Eliminar proveedor -->
                    <h3><u>Eliminar proveedor</u></h3>
                    <p>
                        Para eliminar un proveedor bastara con darle clic al botón de eliminar en la lista y confirmar la eliminación.
                    </p>
                </article>
            </div>
        </section>

        <!-- Catálogo de proveedor -->
        <section id="sec24" class="bg-gris">
            <div class="container padding-sec">
                <article class="color-article">
                    <h2>Catálogo de proveedor</h2>

                    <p>En esta sección podremos hacer el mantenimiento de los catálogos de los diferentes proveedores.
                    </p>
                    <p>Para acceder a los mismo, bastara con hacer clic en el <b>botón de catálogo</b> en la lista de proveedores. Esto nos desplegara una lista con los productos del proveedor seleccionado.</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/48.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/48.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 31</p>
                    </div>

                    <p>
                        En la página de catálogo tendremos la opción de <b>agregar productos</b> de a uno (img. 32.1) o <b>agregarlos masivamente</b> a través de un archivo <b>Excel</b>, el cual deberá tener una estructura que indicaremos más adelante
                        para que la inserción sea correcta en la base de datos.
                    </p>
                    <p>
                        <span class="resaltado">
                            La opción para utilizar Excel en la <b>carga masiva</b> solo está disponible para <b>libros y cds</b>.
                        </span>
                    </p>
                    <p>Para los <b>juegos de mesa</b> solo estará disponible la <b>carga manual</b>.</p>
                    <p>La página nos mostrara un resumen de la cantidad de libros, Cds y juegos de mesa que tiene el proveedor y un total de todo el catálogo del mismo (img. 32.2).</p>
                    <p>Las listas estarán divididas en <b>libros</b> (img. 32.3), <b>Cds y vinilos</b> (img. 32.4) y <b>Juegos de mesa</b> (img. 32.5) para su consulta y o edición en particular de cada elemento.</p>
                    <p>Desde aquí también se podrán <b>agregar productos al carrito</b> por medio del botón de agregar (img. 32.7).
                    </p>
                    <p>También podremos acceder a la generación de <b>códigos QR</b> para cada libro por medio del botón de stock (img. 32.6) que veremos en un momento.</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/49.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/49.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 32</p>
                    </div>

                    <p>
                        Si el proveedor no cuenta con <b>ningún libro</b> en su catálogo estará disponible el botón de <b>agregado masivo</b> por medio de un Excel (img. 33.1).
                        <span class="resaltado">
                            Recordamos que esta carga masiva es solo valida para
                            libros y Cds los juegos de mesa deberán ser cargados de manera individual en su pestaña.
                        </span>
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/50.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/50.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 33</p>
                    </div>
                </article>
            </div>

        </section>

        <!-- agregado con excel -->
        <section id="sec25" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <h3><u>Agregar productos con Excel</u></h3>

                    <p>Para agregar directamente cientos o miles de productos por medio de una tabla Excel se <b>deberán tener las siguientes consideraciones:</b> </p>

                    <ol>
                        <li>
                            La tabla deberá tener una <b>fila inicial</b> con los títulos de las columnas como se muestra en la imagen 34
                            <div class="div-imagenes">
                                <a href="assets/images/img-madriguera/51.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/51.jpg" alt="SURSOFT -  Madriguera Libros">
                                </a>
                                <p>Imagen 34</p>
                            </div>
                        </li>

                        <li>
                            <p>El campo de <b>código</b> debe ser de tipo <b>TEXTO</b>.</p>

                            <!-- procedimiento de cambio codigo a texto -->
                            <mat-accordion>

                                <mat-expansion-panel hideToggle class="color-article bg-gris">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Ver Procedimiento
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <ol class="ol-alpha">
                                        <li>
                                            Podemos determinar que la columna es de este tipo notando el pequeño triangulo en la esquina superior izquierda de la casilla.
                                            <div class="div-imagenes">
                                                <a href="assets/images/img-madriguera/52.jpg" data-lightbox="photos" class="img-container p-3">
                                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/52.jpg" alt="SURSOFT -  Madriguera Libros">
                                                </a>
                                            </div>
                                        </li>

                                        <li>
                                            Si no tuviéramos ese triangulo deberemos <b>convertir los valores</b>, para ello nos colocaremos en la casilla de código y daremos clic derecho, <b>insertar/ toda una
        columna</b>
                                            <div class="row justify-content-center">
                                                <div class="div-imagenes">
                                                    <a href="assets/images/img-madriguera/53.jpg" data-lightbox="photos" class="img-container p-3">
                                                        <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/53.jpg" alt="SURSOFT -  Madriguera Libros">
                                                    </a>
                                                </div>
                                                <div class="div-imagenes">
                                                    <a href="assets/images/img-madriguera/54.jpg" data-lightbox="photos" class="img-container p-3">
                                                        <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/54.jpg" alt="SURSOFT -  Madriguera Libros">
                                                    </a>
                                                </div>
                                            </div>
                                            <p>Esto nos creara una columna a la izquierda del código. Ahora tendremos que copiar el cabezal de la columna de código y pegarlo en la primera celda</p>
                                            <div class="div-imagenes">
                                                <a href="assets/images/img-madriguera/55.jpg" data-lightbox="photos" class="img-container p-3">
                                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/55.jpg" alt="SURSOFT -  Madriguera Libros">
                                                </a>
                                            </div>
                                        </li>

                                        <li>
                                            Luego nos colocaremos en la primera casilla debajo del título de código y pondremos <b>‘ (apostrofe)</b> y el primer número de código y daremos enter
                                            <div class="div-imagenes">
                                                <a href="assets/images/img-madriguera/56.jpg" data-lightbox="photos" class="img-container p-3">
                                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/56.jpg" alt="SURSOFT -  Madriguera Libros">
                                                </a>
                                            </div>
                                        </li>

                                        <li>
                                            Por ultimo en la segunda casilla colocaremos nuevamente <b>‘ (apostrofe)</b> y automáticamente se convertirán todos los valores de código al formato de texto requerido.
                                            <div class="div-imagenes">
                                                <a href="assets/images/img-madriguera/57.jpg" data-lightbox="photos" class="img-container p-3">
                                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/57.jpg" alt="SURSOFT -  Madriguera Libros">
                                                </a>
                                            </div>
                                        </li>

                                        <li>
                                            Ahora solo <b>eliminaremos</b> la columna de código que tuviera el <b>formato numérico</b> y listo, para ello haremos clic derecho sobre la misma y daremos en <b>eliminar y luego toda la columna</b>.
                                            <div class="row justify-content-center">
                                                <div class="div-imagenes">
                                                    <a href="assets/images/img-madriguera/58.jpg" data-lightbox="photos" class="img-container p-3">
                                                        <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/58.jpg" alt="SURSOFT -  Madriguera Libros">
                                                    </a>
                                                </div>
                                                <div class="div-imagenes">
                                                    <a href="assets/images/img-madriguera/59.jpg" data-lightbox="photos" class="img-container p-3">
                                                        <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/59.jpg" alt="SURSOFT -  Madriguera Libros">
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </mat-expansion-panel>

                            </mat-accordion>
                            <br>

                        </li>

                        <li>
                            Los campos título, descripción, autores, géneros y editorial también deberán ser <b>texto</b>, pero estos <b>no requieren conversión</b> ya que de por si ya son de ese tipo
                        </li>

                        <li>
                            Los campos <b>precio, stock, IsAudio e IsFacturado</b> deberán ser <b>numéricos</b>. Tampoco requieren de conversión.
                        </li>

                        <li>
                            <span class="resaltado">
    Se debe tener en cuenta que para que un artículo sea <b>marcado como audio</b> deberá tener un valor su celda <b>sin importar cual</b>, de lo contrario el mismo quedara marcado como libro <b>(SIN VALOR = Libro)</b>
</span>
                        </li>

                        <li>
                            <span class="resaltado">
                                Lo mismo ocurre para el campo <b>IsFacturado</b>, el cual para ser marcado como tal se deberá poner un valor numérico en si campo (img. 34.10), si el mismo queda vacío ese producto será tomado como consignado
                            </span>
                        </li>

                    </ol>

                    <div class="div-imagenes">
                        <p class="p-3 bg-danger text-white">
                            IMPORTANTE: asegurarse que al final de la tabla no hay filas vacías, para ellos ir al final de los datos y eliminar las últimas filas vacías de la tabla por seguridad.
                        </p>
                    </div>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/60.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/60.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <p>Una vez procesado correctamente el archivo Excel ya se podrá hacer la inserción masiva.</p>
                    <p>Para ello haremos clic en el botón de <b>añadir catalogo</b> (img. 35.1), cargaremos el archivo en la ventana (img. 35.2) y le daremos al botón de <b>subir</b> (img. 35.3).</p>
                    <p>Si todo es correcto se nos mostrará un <b>mensaje de éxito</b> y la lista estará cargada con los valores indicados en el Excel (img. 36.1).</p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/61.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/61.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 35</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/62.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/62.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 36</p>
                        </div>
                    </div>

                </article>
            </div>

        </section>

        <!-- Editar productos con Excel -->
        <section id="sec26" class="bg-gris">
            <div class="container padding-sec">
                <article class="color-article">
                    <h3><u>Editar productos con Excel</u></h3>

                    <p>La modificación masiva <b>es similar</b> al agregado por lo cual se deberán <b>tener los mismos recaudos</b> en cuanto al archivo Excel.</p>

                    <p>Cabe destacar que la actualización <b>actúa modificando los valores de los productos ya ingresados</b> con el mismo código de ese proveedor y agregando los nuevos que no se encuentren en la base de datos, pero si en el Excel.</p>

                    <p>En cuanto a la <b>actualización de los stocks</b> los mismos dependerán de si el producto en particular fue marcado como <b>facturado o consignado</b>. Si el producto es facturado el stock en actualizaciones de catálogo se sumará al
                        que exista en el sistema para ese producto. Si el producto es consignado el stock se reemplazará por el nuevo que tenga el Excel que se está subiendo.</p>
                </article>
            </div>

        </section>

        <!-- Eliminación de catálogo -->
        <section id="sec27" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <h3><u>Eliminación de catálogo</u></h3>

                    <p>La eliminación es muy sencilla, se deberá simplemente hacer click en el botón de eliminar catalogo y confirmar la acción. Esto eliminara <b>TODOS</b> los productos del catálogo de ese proveedor.</p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/63.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/63.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 37</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/64.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/64.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 37</p>
                        </div>
                    </div>

                </article>
            </div>

        </section>

        <!-- Agregar un producto -->
        <section id="sec28" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <h3><u>Agregar un producto</u></h3>
                    <p>Para agregar un nuevo producto de manera individual tendrá que hacer clic en el botón de <b>agregar un libro, cd o juego</b> en la pestaña correspondiente para cada uno (img. 38), y será desplegada la página con los campos requeridos
                        para ingresar el mismo.</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/65.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/65.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 38</p>
                    </div>

                    <p>En caso de que el producto sea un <b>cd de música</b> deberá marcarse el campo correspondiente (img. 39.1). Si el mismo queda desmarcado se asumirá que es un libro.</p>

                    <p>Lo mismo ocurrirá para el marcado de si es <b>facturado</b>, de no ser marcado el mismo será considerado como consignado (img. 39.2).</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/66.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/66.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 39</p>
                    </div>

                </article>
            </div>

        </section>

        <!-- Editar un producto -->
        <section id="sec29" class="bg-blanco">
            <div class="container padding-sec">
                <article class="color-article">
                    <h3><u>Editar un producto</u></h3>

                    <p>Para editar un producto en particular se deberá simplemente, en la lista (sea en la página que sea), dar click al botón de editar el cual nos cargará los datos de producto seleccionado permitiendo modificar sus valores.</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/67.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/67.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 40</p>
                    </div>
                </article>
            </div>

        </section>

        <!-- Eliminar un producto -->
        <section id="sec30" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <h3><u>Eliminar un producto</u></h3>

                    <p>Para eliminar un producto del catálogo simplemente se deberá hacer clic en el botón de eliminar y confirmar la acción.</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/68.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/68.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 41</p>
                    </div>
                </article>
            </div>

        </section>

        <!-- Creación de códigos QR -->
        <section id="sec31" class="bg-gris">
            <div class="container padding-sec">
                <article class="color-article">
                    <h3><u>Creación de códigos QR</u></h3>
                    <p>
                        En la página de catálogo de proveedor, la lista presentara un botón en la <b>columna de stock</b> el cual nos indicara el stock del producto y también nos redireccionara a la página para <b>crear los códigos QR</b> necesarios para
                        dicho producto (img. 42.1).
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/69.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/69.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 42</p>
                    </div>

                    <p>
                        La página de creación de los QR nos presentara el detalle del producto, una imagen QR del mismo (img. 43.1) que redireccionara a sus detalles al ser consultado en cualquier celular o tablet.
                    </p>
                    <p>
                        También dispondrá de un botón para <b>exportar</b> la cantidad necesaria de <b>códigos a pdf</b> (img. 43.3) para facilitar su impresión. Por defecto el valor marcado para el pdf será del stock que se tenga (img. 43.2), pero puede
                        ser modificado para colocar la cantidad que se desee.
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/70.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/70.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 43</p>
                    </div>
                </article>
            </div>

        </section>

        <!-- Página de ventas -->
        <section id="sec32" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Página de ventas</h2>

                    <p>
                        En esta página tendremos un panorama general de la actividad de la empresa.
                    </p>

                    <p>
                        Para acceder a la misma como en los anteriores casos podremos hacerlo a través del <b>menú la lateral</b> directamente o por el <b>botón de escritorio</b> y luego seleccionando ventas.
                    </p>

                    <p>
                        La sección nos presenta en su parte superior <b>5 botones</b> que nos darán una información básica sobre:
                    </p>

                    <ul>
                        <li><button (click)="scrollTo('#sec33')" class="link-pagina sin-borde">Ganancias periodo seleccionado (img. 44.1)</button></li>
                        <li><button (click)="scrollTo('#sec34')" class="link-pagina sin-borde">Cantidad de ventas (img. 44.2)</button></li>
                        <li><button (click)="scrollTo('#sec35')" class="link-pagina sin-borde">Productos más vendidos (img. 44.3)</button></li>
                        <li><button (click)="scrollTo('#sec36')" class="link-pagina sin-borde">Ventas por proveedor (img. 44.4)</button></li>
                        <li><button (click)="scrollTo('#sec37')" class="link-pagina sin-borde">Ventas Web vs Local (img. 44.5)</button></li>
                    </ul>

                    <p>
                        En el cuerpo de la sección tendremos una lista con los datos seleccionados, por defecto la lista mostrara los valores del mes y años actuales, pero se puede filtrar por año por mes (img. 44.6) o por todos los tiempos (img. 44.7).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/71.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/71.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 44</p>
                    </div>

                    <h3><u>Tabla General de ventas</u></h3>
                    <p>
                        En la tabla general encontraremos por defecto todas las ventas y pedidos web que se realizaron en el mes actual pudiendo ser filtradas por año y mes de ser requerido.
                    </p>

                    <p>
                        En el caso de las ventas web se indicará el número de Id del pago (img. 45.1) entregado por Mercado pago, el estado de la misma (img. 45.2) y si fue enviado (img. 45.3) o no dicho pedido.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/72.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/72.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 45</p>
                    </div>

                    <p>
                        Si seleccionamos Detalle del pedido (img. 45.4) obtendremos más datos del mismo si la orden esta aprobada, como:
                    </p>
                    <ul>
                        <li>Importe total del cliente (img. 46.1)</li>
                        <li>La comisión de mercado pago (img. 46.2)</li>
                        <li>El total a recibir por La Madriguera Libros (img. 46.3)</li>
                        <li>La fecha de liberación del pago por Mercado Pago (img. 46.4)</li>
                        <li>El estado del pago (img. 46.5)</li>
                        <li>Tipo de pago (img. 46.6)</li>
                    </ul>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/73.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/73.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 46</p>
                    </div>

                    <h3><u>Cancelación de pedido</u></h3>

                    <p>
                        Si la orden está en estado de <b>pendiente</b> de confirmación de pago, lo cual puede darse cuando el cliente selecciona pagar por abitab o red pagos, <b>la orden podrá ser cancelada</b> ya sea por el cliente o por el administrador.
                    </p>
                    <p>
                        <span class="resaltado">
                            Nunca se deberá enviar un pedido antes de que la compra tenga el estado de <b>APROBADA</b>.
                        </span>
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/74.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/74.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <P>Al cancelar una orden se le enviará un email al cliente informándole de la cancelación, el mismo tendrá el siguiente formato:</P>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/75.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/75.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <h3><u>Marcar Pedido Enviado</u></h3>

                    <P>
                        Cuando hayas enviado el pedido deberas marcar en la orden dicha acción para que se le notifique al cliente.
                    </P>
                    <P>
                        Esto lo podras realizar en los detalles del pedido, seleccionando Marcar como enviada (img. 47). Una vez marcada se le enviara al cliente un mail con el siguiente formato (img. 48):
                    </P>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/76.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/76.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 47</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/77.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/77.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 48</p>
                        </div>
                    </div>

                </article>
            </div>

        </section>

        <!-- Ganancias -->
        <section id="sec33" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Ganancias</h2>
                    <p>
                        Si hacemos clic en el botón de <b>ganancias</b> se nos presentara una página con una tabla de datos por año y mes, así como un gráfico de las mismas.
                    </p>

                    <p>
                        Esta nos mostrara las <b>ganancias por mes</b> de la empresa en un año determinado (img. 49.1) o de <b> todos los tiempos</b> (img. 49.2). En los resultados no se mostrarán los pedidos web que no estén aprobados, es decir que tengan
                        un estado de pago pendiente.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/78.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/78.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 49</p>
                    </div>

                </article>
            </div>

        </section>

        <!-- Cantidad de ventas -->
        <section id="sec34" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Cantidad de ventas</h2>
                    <p>
                        Este de manera similar al anterior nos presentar de manera cuantitativa la cantidad de ventas por mes de la empresa junto a su respectivo gráfico.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/79.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/79.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>
                </article>
            </div>

        </section>

        <!-- Más vendidos -->
        <section id="sec35" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Más vendidos</h2>
                    <p>En esta sección podremos ver un top 10 de los productos más vendidos en un mes, en un año o de todos los tiempos.</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/80.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/80.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>
                </article>
            </div>

        </section>

        <!-- Ventas por proveedor -->
        <section id="sec36" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Ventas por proveedor</h2>

                    <p>
                        En esta página podremos ver las ventas que hayamos realizado filtradas por un proveedor especifico en un periodo de fechas especifico.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/81.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/81.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <p>Una vez colocados las fechas y el proveedor se nos presentaran dos tablas, una para las ventas facturadas y otra para las ventas consignadas en las que podremos ver:</p>

                    <ul>
                        <li>Código del producto</li>
                        <li>Nombre del producto</li>
                        <li>Cantidad de ventas</li>
                        <li>Importe de las mismas</li>
                        <li>Totales</li>
                        <li>Gráfico</li>
                    </ul>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/82.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/82.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <p>En el cabezal de cada tabla tendremos un botón para generar un pdf con los resultados obtenidos de la misma (img. 50.1).</p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/83.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/83.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Imagen 50</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-madriguera/84.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/84.jpg" alt="SURSOFT -  Madriguera Libros">
                            </a>
                            <p>Reporte PDF</p>
                        </div>
                    </div>
                </article>
            </div>

        </section>

        <!-- Ventas Web vs Local -->
        <section id="sec37" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Ventas Web vs Local</h2>
                    <p>En esta página se nos mostrara una comparativa entre las ventas web y en el local junto con una tabla general referente a los totales obtenidos por la empresa, esto debido a que las compras online generan un gasto en cuanto a comisiones
                        que cobra Mercado pago por cada venta.</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/85.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/85.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <p>Al seleccionar un proveedor y un rango de fechas, se desplegarán dos tablas con los productos vendidos para el mismo también pudiendo ser exportadas a pdf cualquiera de las dos en caso que así se desee.</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/86.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/86.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                </article>
            </div>

        </section>

        <!-- Página Recomendados Admin -->
        <section id="sec38" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Página Recomendados Admin</h2>

                    <p>Esta página presentara una lista con todos los productos recomendados a fin de agilizar el manejo de los mismos pudiendo acceder rápidamente a sus opciones tanto para eliminar la recomendación o modificar sus atributos.</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/87.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/87.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>
                </article>
            </div>

        </section>

        <!-- Página Destacados Admin -->
        <section id="sec39" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Página Destacados Admin</h2>
                    <p>Esta página es similar a la de recomendados solo que en ella tendremos todos los productos marcados como destacados.</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/88.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/88.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>
                </article>
            </div>

        </section>

        <!-- Página Posts Admin -->
        <section id="sec40" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Página Posts Admin</h2>
                    <p>En esta página podremos manejar y administrar lo referente a los posts, publicaciones de promociones que aparecerán en la página de inicio del sistema.</p>
                    <p>
                        La misma presenta un botón superior para agregar un <b>nuevo post</b> (img. 51.1) y una lista de los que ya existen en el sistema (img. 51.2) con sus respectivas opciones de <b>edición</b> (img. 51.3) o <b>eliminación</b> (img.
                        51.4).
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/89.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/89.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                        <p>Imagen 51</p>
                    </div>

                    <p>Los posts que estén marcados como <b>activos</b> aparecerán, los <b>últimos 10 creados</b>, en la sección de posts recientes, en la página de inicio.</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/90.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-madriguera/90.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <h3><u>Crear un post</u></h3>
                    <p>Para crear un post como ya hemos visto anteriormente deberemos dar clic en el botón de agregar nuevo.</p>
                    <p>El mismo nos desplegara un formulario para ingresar los datos que serán:</p>
                    <ul>
                        <li>
                            <b>Una imagen</b>: se recomienda que la misma tenga las siguientes dimensiones 1200px por 700, para una óptima visualización.
                            <div class="div-imagenes">
                                <a href="assets/images/img-madriguera/91.jpg" data-lightbox="photos" class="img-container p-3 ">
                                    <img class="img-thumbnail img-articulo  z-depth-1 mb-3" src="assets/images/img-madriguera/91.jpg" alt="SURSOFT -  Madriguera Libros">
                                </a>
                            </div>
                        </li>
                        <li><b>Título</b>: título que tendrá la respectiva publicación.</li>
                        <li><b>Descripción</b>: cuerpo o texto que tendrá el post.</li>
                        <li><b>Pie del post</b>: pie final donde se coloca pequeños datos.</li>
                        <li><b>¿Es Activo?</b>: este campo se refiere a si el mismo deberá ser mostrado o no en la página inicial. Cabe destacar que si tenemos más de 10 activos el sistema mostrara solo los últimos 10 creados, si se requiere deberá desactivar
                            los que no desee mostrar editando el post.</li>
                    </ul>

                    <h3><u>Editar un post</u></h3>
                    <p>Para editar un post simplemente deberemos hacer clic en el botón de la lista respectivo de edición y se nos desplegaran los datos del mismo.</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/92.jpg" data-lightbox="photos" class="img-container p-3 ">
                            <img class="img-thumbnail img-articulo  z-depth-1 mb-3" src="assets/images/img-madriguera/92.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/93.jpg" data-lightbox="photos" class="img-container p-3 ">
                            <img class="img-thumbnail img-articulo  z-depth-1 mb-3" src="assets/images/img-madriguera/93.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <h3><u>Eliminar un post</u></h3>
                    <p>Para eliminar un post simplemente deberemos hacer clic en el botón de eliminación en la lista de posts y confirmar la acción.</p>
                </article>
            </div>

        </section>

        <!-- Deshabilitar compras web -->
        <section id="sec41" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Deshabilitar compras web</h2>
                    <p>En esta página podremos seleccionar un periodo de tiempo en el cual no se puedan generar nuevos pedidos web por parte de los clientes, ya sea porque los administradores del sistema se van de vacaciones o cualquier otra razón.</p>
                    <p>Para esto iremos al menú de administración y seleccionaremos Deshabilitar compras o desde el escritorio seleccionando Periodos desactivados.</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/94.jpg" data-lightbox="photos" class="img-container p-3 ">
                            <img class="img-thumbnail img-articulo  z-depth-1 mb-3" src="assets/images/img-madriguera/94.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <p>En esta sección encontraremos una lista de los periodos agregados</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/95.jpg" data-lightbox="photos" class="img-container p-3 ">
                            <img class="img-thumbnail img-articulo  z-depth-1 mb-3" src="assets/images/img-madriguera/95.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <h3><u>Crear un periodo de des habilitación de compras web</u></h3>
                    <p>Para crear un nuevo periodo haremos click en el botón de nuevo periodo</p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/96.jpg" data-lightbox="photos" class="img-container p-3 ">
                            <img class="img-thumbnail img-articulo  z-depth-1 mb-3" src="assets/images/img-madriguera/96.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <p>Se nos presentara un formulario en el cual deberemos colocar:</p>
                    <ul>
                        <li>Titulo</li>
                        <li>Descripción: porque no tomaran mas pedidos en dicho periodo</li>
                        <li>Fecha de inicio</li>
                        <li>Fecha de fin</li>
                    </ul>

                    <p>Estos datos serán los que se usaran en los carritos de los clientes para indicarles porque no se tomaran pedidos nuevos en dichos periodos</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-madriguera/97.jpg" data-lightbox="photos" class="img-container p-3 ">
                            <img class="img-thumbnail img-articulo  z-depth-1 mb-3" src="assets/images/img-madriguera/97.jpg" alt="SURSOFT -  Madriguera Libros">
                        </a>
                    </div>

                    <p>Una vez finalizado los periodos se volverá a activar la recepción de compras.</p>
                    <p>Puedes eliminar en cualquier momento un periodo para volver a recibir compras.</p>
                </article>
            </div>

        </section>

    </mat-drawer-content>
</mat-drawer-container>