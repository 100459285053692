
    import { animate, animation, style } from '@angular/animations';  
      
    export var slideAnimation = animation([  
        style(
            { opacity: 0, position: 'fixed', left: 0, right: 0, transform: 'translate3d(0,0,-100%)' }
        ),  
        animate('900ms ease-in-out', 
            style({ opacity: 1, transform: 'translate3d(0,0,0%)' })
        ),  
    ])  

    export var slide2Animation = animation([  
        style(
            { opacity: 0, position: 'fixed', left: 0, right: 0, transform: 'translate3d(-100%,0,0)' }
        ),  
        animate('900ms ease-in-out', 
            style({ opacity: 1, transform: 'translate3d(0%,0,0)' })
        ),  
    ])  
