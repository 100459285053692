<mat-drawer-container [hasBackdrop]="true">

    <!--Menu lateral-->
    <mat-drawer #drawer [mode]="'push'" class="mat-drawer">
        <ng-container>
            <div>
                <a routerLink="inicio" class="btn btn-menu"><i class="fas fa-home fa-2x mr-2"></i> <span>INICIO</span> </a>
            </div>

            <div>
                <a routerLink="tecnologias" (click)="cerrar()" class="btn btn-menu"><i class="fas fa-cog fa-2x mr-2"></i>TECNOLOGIAS</a>
            </div>
            <!--
            <div>
                <a routerLink="laboratorio" class="btn btn-menu"><i class="fas fa-flask fa-2x mr-2"></i>LABORATORIO</a>
            </div>
            -->
            <div>
                <a routerLink="portafolio" (click)="cerrar()" class="btn btn-menu"><i class="fas fa-briefcase fa-2x mr-2"></i>PORTAFOLIO</a>
            </div>

            <div>
                <a routerLink="about" (click)="cerrar()" class="btn btn-menu"><i class="fas fa-address-card fa-2x mr-2"></i>SOBRE MI</a>
            </div>

            <div>
                <a routerLink="contact" (click)="cerrar()" class="btn btn-menu"><i class="fas fa-envelope fa-2x mr-2"></i>CONTACTO</a>
            </div>
            <!--
            <div>
                <a routerLink="pruebas" class="btn btn-menu"><i class="fas fa-flask fa-2x mr-2"></i>Pruebas</a>
            </div>
            -->
        </ng-container>
    </mat-drawer>

    <!--Contenido-->
    <mat-drawer-content class="drawer-content">

        <!--Menu Horizontal-->
        <mat-toolbar id="menuHorizontal" class="menu-horizontal">
            <div>
                <a routerLink="inicio/app-inicio" mat-button>
                    <img class="logoMenu" src="assets/logo.svg" alt="SUR-Soft - Soluciones Informáticas" />
                </a>
            </div>

            <!--Botones-->
            <div class="ocultarMenu contenedor-Menu">
                <ng-container class="justify-content-end">
                    <div>
                        <a class="btn-menu" routerLink="tecnologias" mat-button>TECNOLOGIAS</a>
                    </div>
                    <!--
                    <div>
                        <a class="btn-menu" routerLink="laboratorio" mat-button>LABORATORIO</a>
                    </div>
 -->
                    <div>
                        <a class="btn-menu" routerLink="portafolio" mat-button>PORTAFOLIO</a>
                    </div>

                    <div>
                        <a class="btn-menu" routerLink="about" mat-button>SOBRE MI</a>
                    </div>

                    <div>
                        <a class="btn-menu" routerLink="contact" mat-button>CONTACTO</a>
                    </div>
                    <!--
                    <div>
                        <a class="btn-menu" routerLink="pruebas" mat-button>Pruebas</a>
                    </div>
                     -->

                </ng-container>

                <div class="ocultarBoton">
                    <a mat-mini-fab (click)="drawer.toggle()" class="btn-lateral-menu">
                        <mat-icon>menu</mat-icon>
                    </a>
                </div>
            </div>
            <div class="d-sm-none d-flex">
                <a mat-mini-fab (click)="drawer.toggle()" class="">
                    <mat-icon>menu</mat-icon>
                </a>
            </div>
        </mat-toolbar>

        <div class="container-principal" [@routerAnimations]="prepareRouteTransition(outlet)">

            <!--contenido de las paginas-->
            <div class="router-outlet-container">
                <router-outlet #outlet="outlet">

                </router-outlet>
            </div>

            <!--footer de la pagina-->
            <app-footer></app-footer>

        </div>

    </mat-drawer-content>
</mat-drawer-container>