import { Component, OnInit, OnDestroy } from '@angular/core';
import { CargarScriptsService, ScriptModel } from '../utilidades/cargar-scripts.service';

@Component({
  selector: 'app-tecnologias',
  templateUrl: './tecnologias.component.html',
  styleUrls: ['./tecnologias.component.css']
})
export class TecnologiasComponent implements OnInit, OnDestroy {

  constructor(private cargarScriptsService: CargarScriptsService) { }

  scriptsNecesarios: ScriptModel[] = [
    {name: "tooltips", src: "assets/bootstrap/js/InitTooltips.js", loaded: false}
  ];

  ngOnInit(): void {
    window.addEventListener("scroll", this.scrollEvent, true);
    this.cargarScriptsService.cargarScripts(this.scriptsNecesarios);
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  scrollEvent() {

    const position = document.body.getBoundingClientRect().top;
    const button = document.getElementById("scrollBtn");
    //console.log(position);
    if(position < -530){
      button.hidden = false;
    }else{
      button.hidden = true;
    }
  }
  
  scrollTop(){
    window.scrollTo(0, 0);
  }

  scrollTo(elementId: string){

    //obtenemos la posicion del scroll actual
   const scrollActual = window.scrollY;

   //obtenemos la posicion del elemento buscado y le restamos 63 del alto del menu y le sumamos el scroll actual que tuviera al momento de presionar el boton
   const scrollY = document.querySelector(elementId).getBoundingClientRect().top -63 +scrollActual;

    //nos desplazamos a la posicion dentro del div correspondiente
   window.scrollTo(0, scrollY);

  }

}
