<a id="scrollBtn" (click)="scrollTop()" class="btn btn-floating btn-scrollUp waves-effect wow fadeInUp" hidden>
    <i class="fas fa-arrow-up"></i>
</a>

<div id="tec" class="">
    <!--Contenido-->
    <div>
        <!--cabezal-->
        <div class="div-cabezal">

            <app-parallax class="text-center " [nameId]="'paralTec'" [imgUrl]="'url(assets/images/2.gif)'" [minHeight]=" '250px' " [titulo]=" 'Tecnologías utilizadas en mis proyectos' "></app-parallax>

            <div style="display: flex; flex-direction: column; align-items: center;">


                <div class="mt-4 px-2" data-aos="flip-up" data-aos-duration="1000">
                    <div class="container">
                        <p class="parrafo-descripcion">
                            Conoce un poco más sobre algunas de las tecnologías y herramientas que utilizo en mis proyectos, para ofrecerte el mejor resultado al materializar tus ideas.
                        </p>
                    </div>

                    <!--separador-->
                    <div class="separador col-sm-10 offset-sm-1">
                        <div class="col-5 p-0 mr-2">
                            <hr />
                        </div>
                        <div>
                            <a routerLink="inicio" data-toggle="tooltip" title="Inicio">
                                <img src="favicon.ico" style="height: 30px;" />
                            </a>
                        </div>
                        <div class="col-5 p-0 ml-2">
                            <hr />
                        </div>
                    </div>
                </div>

                <!--botones de seleccion-->
                <div class="row justify-content-center mb-4 p-2">

                    <!--.NET Framework-->
                    <div data-aos="fade-right" data-aos-duration="1000" class="btn-selector">
                        <a (click)="scrollTo('#netF')" class="waves-effect rounded text-center p-2 ">
                            <img src="assets/images/frmaeworChico.png" data-toggle="tooltip" title=".NET Framework" alt="SURSOFT - .NET Framework" class="img-size rounded-circle z-depth-1">
                        </a>
                    </div>
                    <!--.NET Core-->
                    <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100" class="btn-selector">
                        <a (click)="scrollTo('#core')" class="waves-effect rounded text-center p-2 ">
                            <img src="assets/images/coreChico.jpg" alt="SURSOFT - .NET Core" data-toggle="tooltip" title=".NET Core" class="img-size rounded-circle z-depth-1 ">
                        </a>
                    </div>
                    <!--.NET 5-->
                    <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200" class="btn-selector">
                        <a (click)="scrollTo('#net5')" class="waves-effect rounded text-center p-2 ">
                            <img src="assets/images/net5Chico.jpg" alt="SURSOFT - .NET 5" data-toggle="tooltip" title=".NET 5" class="img-size rounded-circle z-depth-1 ">
                        </a>
                    </div>
                    <!--C# 9-->
                    <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="300" class="btn-selector">
                        <a (click)="scrollTo('#c9')" class="waves-effect rounded text-center p-2 ">
                            <img src="assets/images/c9.png" alt="SURSOFT - Lenguaje C# 9" data-toggle="tooltip" title="Lenguaje C# 9" class="img-size z-depth-1 rounded-circle ">
                        </a>
                    </div>
                    <!--razor-->
                    <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="400" class="btn-selector">
                        <a (click)="scrollTo('#razor')" class="waves-effect rounded  text-center p-2 ">
                            <img src="assets/images/razorChico.png" alt="SURSOFT - Razor Pages" data-toggle="tooltip" title="Razor Pages" class="img-size z-depth-1 rounded-circle">
                        </a>
                    </div>
                    <!--angular-->
                    <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500" class="btn-selector">
                        <a (click)="scrollTo('#angular')" class="waves-effect rounded  text-center p-2 ">
                            <img src="assets/images/angularChico.png" alt="SURSOFT - Angular 11" data-toggle="tooltip" title="Angular 11" class="img-size z-depth-1 rounded-circle">
                        </a>
                    </div>
                    <!--postman-->
                    <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="600" class="btn-selector">
                        <a (click)="scrollTo('#postman')" class="waves-effect rounded  text-center p-2 ">
                            <img src="assets/images/postmanChico.png" alt="SURSOFT - Postman" data-toggle="tooltip" title="Postman" class="img-size z-depth-1 rounded-circle">
                        </a>
                    </div>
                    <!--corel-->
                    <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="700" class="btn-selector">
                        <a (click)="scrollTo('#corel')" class="waves-effect rounded  text-center p-2 ">
                            <img src="assets/images/corelChico.png" alt="SURSOFT - Corel DRAW" data-toggle="tooltip" title="Corel Draw" class="img-size z-depth-1 rounded-circle">
                        </a>
                    </div>
                    <!--maya-->
                    <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="800" class="btn-selector">
                        <a (click)="scrollTo('#maya')" class="waves-effect rounded  text-center p-2 ">
                            <img src="assets/images/mayaChico.png" alt="SURSOFT - Autodesk Maya 3D" data-toggle="tooltip" title="Autodesk Maya" class="img-size z-depth-1 rounded-circle">
                        </a>
                    </div>
                    <!--substance-->
                    <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="900" class="btn-selector">
                        <a (click)="scrollTo('#substance')" class="waves-effect rounded  text-center p-2 ">
                            <img src="assets/images/substanceChico.png" alt="SURSOFT - Substance Painter" data-toggle="tooltip" title="Substance Painter" class="img-size z-depth-1 rounded-circle">
                        </a>
                    </div>
                </div>

                <!--carousel inicial-->
                <div class="container mb-sm-0 mb-5">
                    <app-carousel2 [carouselId]="'car1'" [carHeight]="'200px'" [slides]="[
{slideNumber:0, image: 'assets/images/carousel/slide1.jpg', isActive: 'active', titulo: 'Diseño web profesional', descripcion: 'Diseños web profesionales, amoldados a tus necesidades para cumplir con los requisitos de tu negocio o nueva empresa.'},
{slideNumber:2, image: 'assets/images/carousel/slide3.jpg', isActive: '', titulo: 'Aplicaciones Responsivas', descripcion: 'Aplicaciones que se ajustan a la mayoria de los dispositivos ahorrandote tiempo y dinero.'},
{slideNumber:3, image: 'assets/images/carousel/slide4.jpg', isActive: '', titulo: 'Ingresa en la Red', descripcion: 'No esperes más para entrar al mundo virtual e impulsar a tu negocio o empresa.'}]">
                    </app-carousel2>
                </div>

            </div>
        </div>

        <!--.Net framework-->
        <section id="netF" class="section-medidas seccion-gris" name="netframework">
            <div data-aos="flip-up" data-aos-duration="2000" class=" text-center">
                <img class="img-logo-Section" src="assets/images/framework.png" alt="SURSOFT -  .NET Framework">
            </div>
            <br>
            <div class="col-sm-8 offset-sm-2 text-gris" data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <h2 class="orange-color">.NET Framework</h2>

                <p>
                    <b>.NET Framework</b> es una tecnología que admite la creación y ejecución de <b>servicios web</b> y <b>aplicaciones Windows</b>. El diseño de .NET Framework está enfocado a cumplir los objetivos siguientes:
                </p>

                <ul>
                    <li>
                        <p>
                            Proporcionar un entorno de <b>programación orientada a objetos</b> coherente en el que el código de los objetos se pueda almacenar y ejecutar de forma local, ejecutar de forma local pero distribuida en Internet o ejecutar de
                            forma remota.
                        </p>
                    </li>

                    <li>
                        <p>
                            Proporcionar un entorno de ejecución de código que:
                        </p>
                        <ul>
                            <li>
                                Minimice los conflictos de implementación de software y control de versiones.
                            </li>
                            <li>
                                Fomente la <b>ejecución segura de código</b>, incluso del creado por terceros desconocidos o que no son de plena confianza.
                            </li>
                            <li>
                                <p>
                                    Elimine los problemas de rendimiento de los entornos con scripts o interpretados.
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p>
                            Ofrecer al desarrollador una experiencia coherente entre tipos de aplicaciones muy diferentes, como las basadas en Windows o en web.
                        </p>
                    </li>

                    <li>
                        Basar toda la comunicación en estándares del sector para garantizar que el código basado en .NET Framework se integre con otro código.
                    </li>
                </ul>

                <div class="text-center">
                    <a href="assets/images/arquitectura.png" data-lightbox="photos">
                        <img src="assets/images/arquitectura.png" alt="" class="img-Section  z-depth-1">
                    </a>
                </div>
            </div>
        </section>

        <!--.Net Core-->
        <section id="core" class="section-medidas seccion-blanca">
            <div data-aos="flip-up" data-aos-duration="2000" class="text-center">
                <img class="img-logo-Section" src="assets/images/core.png" alt="SURSOFT -  .NET Core">
            </div>
            <br>
            <div class="col-sm-8 offset-sm-2 text-gris" data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine">

                <h2 class="orange-color">.NET Core</h2>
                <p>
                    <b>.NET Core</b> es la plataforma de desarrollo de Microsoft más moderna, de código fuente abierto, multiplataforma y de alto rendimiento para la creación de todo tipo de aplicaciones.
                </p>
                <h3><b>Arquitectura de .NET Core</b></h3>
                <p>
                    Esta arquitectura modular permite que podamos incluir únicamente lo necesario que requieren nuestras aplicaciones, haciendo que tengan un peso menor a la hora de ser desplegadas y que sea más sencilla su actualización a través de updates de NuGet.
                </p>
                <p>
                    Al ser multiplataforma, no depende de cosas específicas del sistema operativo, como sucede con el .NET Framework.
                </p>

                <h3 class="orange-color"><b>¿Para qué usamos .NET Core?</b></h3>

                <p>
                    <b>.NET Core</b> nos permite realizar todo tipo de aplicaciones, como aplicaciones <b>web</b> que podrás desplegar en <b>Windows, Linux, Mac Os.</b>
                </p>
                <p>
                    Podrás desplegar tus aplicaciones en distintas plataformas <b>Cloud</b> como <b>Azure, Amazon, GCP,</b> en particular utilizo <b style="font-size: 16px;text-decoration: underline;">smarterasp</b> que ofrece una exelente realción calida
                    precio para nuestros clientes.
                </p>
                <p class="mb-0">
                    Se puede utilizar para crear aplicaciones de escritorio UWP que permitirá correr tu aplicación en Windows 10, XBOX y HoloLens compartiendo el código.
                </p>
            </div>
        </section>

        <!--.Net 5-->
        <section id="net5" class="section-medidas seccion-gris">
            <div data-aos="flip-up" data-aos-duration="2000" class="text-center">
                <img class="img-logo-Section" src="assets/images/net5.png" alt="SURSOFT -  .NET 5">
            </div>
            <br>
            <div class="col-sm-8 offset-sm-2 text-gris" data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine">

                <h2 class="orange-color">.NET 5</h2>
                <p>
                    <b>.NET 5</b> es la unificación de <b>.NET Framework, .NET Core y Mono</b> en una sola plataforma de desarrollo multiplataforma.
                </p>
                <p>
                    Esta evolución de <b>.NET Core</b> contará con una sola biblioteca de clases basada en <b>.NET Standard</b>, herramientas unificadas. Además, <b>.NET 5</b> contará con la posibilidad de interoperar con código escrito en otros lenguajes,
                    como <b>Java o Swift</b>.
                </p>
                <p>
                    <b>.NET 5</b> será la plataforma de desarrollo para la construcción de todo tipo de aplicaciones.
                </p>
                <p>
                    Podremos crear aplicaciones multiplataforma como aplicaciones y servicios web, escritorio, juegos, aplicaciones cloud y móviles.
                </p>

                <div class="d-sm-flex justify-content-center">
                    <a href="assets/images/net5arch.png" data-lightbox="photos">
                        <img src="assets/images/net5arch.png" alt="" class="img-Section z-depth-1">
                    </a>
                </div>
            </div>
        </section>

        <!--C# 9-->
        <section id="c9" class="section-medidas seccion-blanca">
            <div data-aos="flip-up" data-aos-duration="2000" class="text-center">
                <img class="img-logo-Section" src="assets/images/csharp.png" alt="SURSOFT -  C#">
            </div>
            <br>

            <div class="col-sm-8 offset-sm-2 text-gris" data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <h2 class="orange-color">C# 9</h2>
                <p>
                    Con cada nueva versión de C# se está intentando una mayor claridad y simplicidad en los escenarios comunes de codificación, y C# 9.0 no es una excepción.
                    <b>C#</b> (léase C Sharp), es una evolución que <b>Microsoft</b> realizó de este lenguaje, tomando lo mejor de los lenguajes <b>C y C++</b>, y ha continuado añadiéndole funcionalidades hasta llevar a la version actual que es la 9.
                    Es un
                    <b>lenguaje orientado a objetos</b> y con el tiempo adaptó las facilidades de la creación de código que tenía otros lenguajes, haciéndolo muy <b>polivalente</b> y fácil de aprender, sin perder ni un ápice de la potencia original de
                    C. En la versión de .NET Core, se ha reconstruido por completo su compilador, haciendo las <b>aplicaciones un 600% más rápidas</b>.
                </p>

                <h2 class="orange-color">Caracteristicas</h2>

                <ul class="mb-0">
                    <li>
                        Sencillez
                    </li>
                    <li>
                        Modernidad
                    </li>
                    <li>
                        Seguridad
                    </li>
                    <li>
                        Sistemas de tipos unificados
                    </li>
                    <li>
                        Extensibilidad
                    </li>
                    <li>
                        Versionable
                    </li>
                    <li>
                        Compatible
                    </li>
                    <li>
                        Eficiente
                    </li>
                </ul>
            </div>
        </section>

        <!--razor -->
        <section id="razor" class="section-medidas seccion-gris">
            <div data-aos="flip-up" data-aos-duration="2000" class="text-center">
                <img class="img-logo-Section" src="assets/images/razor.png" alt="SURSOFT - razor pages">
            </div>
            <br>

            <div class="col-sm-8 offset-sm-2 text-gris" data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine">

                <h2 class="orange-color">Razor pages</h2>
                <p>
                    Razor Pages es un marco de desarrollo web de primera clase, que se encuentra en la parte superior del tiempo de ejecución, ligero multiplataforma y superrápido de ASP.NET Core.
                </p>

                <p>
                    Ofrece un modelo de desarrollo basado en páginas simplificado que atrae a una amplia audiencia.
                </p>

                <p>
                    El marco de Razor Pages aprovecha la potente y madura sintaxis de vista de Razor que ha sido popular desde su introducción a ASP.NET MVC en 2013.
                </p>

                <p>
                    Razor Pages está construido sobre ASP.NET Core MVC, por lo tanto, se pueden combinar controladores basados ​​en MVC con sus Razor Pages, o incluso comenzar a agregar algunas Razor Pages en aplicaciones ASP.NET Core MVC existentes.
                </p>
                <br/>
                <div class="d-sm-flex justify-content-center">
                    <a href="assets/images/Razor-Pages-Diagram.png" data-lightbox="photos">
                        <img src="assets/images/Razor-Pages-Diagram.png" alt="" class="img-Section z-depth-1">
                    </a>
                </div>
            </div>

        </section>

        <!--angular 11 -->
        <section id="angular" class="section-medidas seccion-blanca">
            <div data-aos="flip-up" data-aos-duration="2000" class="text-center">
                <img class="img-logo-Section" src="assets/images/angular.png" alt="SURSOFT - razor pages">
            </div>
            <br>

            <div class="col-sm-8 offset-sm-2 text-gris" data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine">

                <h2 class="orange-color">Angular 11</h2>
                <p>
                    Angular es un framework opensource desarrollado por <b>Google</b> para facilitar la creación y programación de aplicaciones web de una sola página, las webs SPA (Single Page Application).
                </p>

                <p>
                    En una web SPA aunque la velocidad de carga puede resultar un poco lenta la primera vez que se abre, navegar después es totalmente instantáneo, ya que se ha cargado toda la página de golpe.
                </p>

                <p>
                    Entre otras ventajas, este framework es <b>modular y escalable</b> adaptándose a nuestras necesidades y al estar basado en el estándar de componentes web, y con un conjunto de interfaz de programación de aplicaciones (API) permite
                    crear nuevas etiquetas HTML personalizadas que pueden reutilizarse.
                </p>

                <br/>
                <div class="d-sm-flex justify-content-center">
                    <a href="assets/images/angular2.png" data-lightbox="photos">
                        <img src="assets/images/angular2.png" alt="" class="img-Section z-depth-1">
                    </a>
                </div>
            </div>
        </section>

        <!--Postman-->
        <section id="postman" class="section-medidas seccion-gris">
            <div data-aos="flip-up" data-aos-duration="2000" class="text-center">
                <img class="img-logo-Section" src="assets/images/postman.png" alt="SURSOFT - postman">
            </div>
            <br>

            <div class="col-sm-8 offset-sm-2 text-gris" data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine">

                <h2 class="orange-color">Postman</h2>
                <p>
                    Postman es una herramienta que se utiliza, sobre todo, para el <b>testing de API REST</b>, aunque también admite otras funcionalidades que se salen de lo que engloba el testing de este tipo de sistemas.
                </p>

                <p>
                    Gracias a esta herramienta, además de <b>testear</b>, <b>consumir</b> y <b>depurar API REST</b>, podremos <b>monitorizarlas</b>, escribir pruebas automatizadas para ellas, documentarlas, mockearlas, simularlas, etc.
                </p>

                <br/>
                <div class="d-sm-flex justify-content-center">
                    <a href="assets/images/postImg.jpg" data-lightbox="photos">
                        <img src="assets/images/postImg.jpg" alt="" class="img-Section z-depth-1">
                    </a>
                </div>
            </div>
        </section>

        <!--corel -->
        <section id="corel" class="section-medidas seccion-blanca">
            <div data-aos="flip-up" data-aos-duration="2000" class="text-center">
                <img class="img-logo-Section" src="assets/images/corel.png" alt="SURSOFT - Corel Draw">
            </div>
            <br>

            <div class="col-sm-8 offset-sm-2 text-gris" data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine">

                <h2 class="orange-color">Corel DRAW</h2>
                <p>
                    Es la aplicación principal de la suite de programas <b>CorelDRAW Graphics Suite</b> y que está diseñada para suplir múltiples necesidades, como el <b>dibujo</b>, la <b>maquetación de páginas</b> para impresión y la publicación web,
                    todas incluidas en un mismo programa. Este es un excelente software diseñado para los amantes al <b>diseño gráfico</b>. Este programa permite realizar diseños de manera profesional, teniendo herramientas muy fáciles de usar.
                </p>

                <p>
                    Corel Draw como programa de diseño otorga a los usuarios varias herramientas para producir imágenes originales o editarlas con precisión.
                </p>

                <p>
                    Se pueden crear logotipos, motivos, letreros, etc. Además de esto, CorelDraw tiene la capacidad de trabajar con otros programas en el paquete de gráficos Corel Draw, como <b>Corel photo-paint</b>, que ayuda a producir imágenes compuestas.
                </p>
                <div class="mb-0 d-sm-flex justify-content-center text-center">
                    <a href="assets/images/corel2.png" data-lightbox="photos" class=" mr-sm-3  ">
                        <img src="assets/images/corel2.png" alt="" class="img-Section z-depth-1 mb-3">
                    </a>
                    <div>
                        <a href="assets/images/corel1.png" data-lightbox="photos">
                            <img src="assets/images/corel1.png" alt="" class="img-Section  z-depth-1">
                        </a>
                    </div>
                </div>
            </div>

        </section>

        <!--maya -->
        <section id="maya" class="section-medidas seccion-gris">
            <div data-aos="flip-up" data-aos-duration="2000" class="text-center">
                <img class="img-logo-Section" src="assets/images/maya.png" alt="SURSOFT - Corel Draw">
            </div>
            <br>

            <div class="col-sm-8 offset-sm-2 text-gris" data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine">

                <h2 class="orange-color">Autodesk Maya 3D</h2>
                <p>
                    Autodesk Maya es una solución de <b>animación en 3D</b> que proporciona un conjunto completo de funciones creativas para realizar en 3D animación por ordenador, modelado, simulación, renderización y composición dentro de una plataforma
                    de producción sumamente ampliable.
                </p>

                <p>
                    Ahora Maya incluye tecnología de visualización de última generación, flujos de trabajo de modelado más rápidos y nuevas herramientas para gestionar datos complejos.
                </p>


                <div class="mb-0 d-sm-flex justify-content-center">
                    <a href="assets/images/maya2.png" data-lightbox="photos" class=" mr-3 mb-3 ">
                        <img src="assets/images/maya2.png" alt="" class="img-Section  z-depth-1">
                    </a>
                </div>
            </div>

        </section>

        <!--substance -->
        <section id="substance" class="section-medidas seccion-blanca">
            <div data-aos="flip-up" data-aos-duration="2000" class="text-center">
                <img class="img-logo-Section" src="assets/images/substance.png" alt="SURSOFT - Substance Painter">
            </div>
            <br>

            <div class="col-sm-8 offset-sm-2 text-gris" data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine">

                <h2 class="orange-color">Substance Painter</h2>
                <p>
                    Este programa permite <b>pintar los modelos directamente</b> en 3D e incluye muchísimas funciones espectaculares, creando texturas para assets (o activos) 3D de la forma más fácil e innovadora.
                </p>

                <p>
                    Este programa permite crear detalles y personalizar pinceles, pero también crear materiales para después pintarlos o tapizarlos. Del mismo modo, podremos generar múltiples variaciones del mismo material y hacerlos inteligentes.
                </p>

                <p>
                    Substance Painter permite lanzar renders para presentar tus modelos dentro del mismo programa, pero también dispone de conexión con los paquetes 3D y motores de render más utilizados en la industria (como 3Ds Max, Maya, V-Ray…).
                </p>


                <div class="mb-0 d-sm-flex justify-content-center">
                    <a href="assets/images/substanceImg.jpg" data-lightbox="photos" class=" mr-3 mb-3 ">
                        <img src="assets/images/substanceImg.jpg" alt="" class="img-Section  z-depth-1">
                    </a>
                </div>
            </div>

        </section>
    </div>
</div>