<div class="card h-100" style="border-radius: 15px;">
    <a href="{{imageSrc}}" data-lightbox="photos">
        <img src="{{imageSrc}}" class="imagen w-100 p-2" alt="..." />
    </a>

    <div class="card-body">
        <h1 class="card-title">{{title}}</h1>
        <p class="card-text">
            {{description}}
        </p>

    </div>
    <div class="card-footer text-right">

        <ng-container *ngIf="srcSaberMas;">
            <a routerLink="{{srcSaberMas}}" class="btn btn-sm  btn-light btn-rounded  waves-effect">Saber más</a>
        </ng-container>


        <ng-container *ngIf="demoSrc; else elseDemo">
            <a target="{{targetDemo}}" href="{{demoSrc}}" class="btn btn-sm btn-orange rounded-pill  waves-effect">{{btnText}}</a>
        </ng-container>
        <ng-template #elseDemo>
            <a target="{{targetDemo}}" routerLink="{{targetDemo}}" class="btn btn-sm btn-orange rounded-pill  waves-effect">{{btnText}}</a>
        </ng-template>

    </div>
</div>