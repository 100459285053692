<div class="d-flex">

    <button class="btn-menu bgcolor-indice pl-md-4 py-3" mat-flat-button (click)="drawer2.toggle()">Índice</button>

    <div class="col titulo-madriguera">

    </div>

    <button class="btn-menu bgcolor-volver pr-md-4 py-3" mat-flat-button (click)="volver()">Volver</button>
</div>



<mat-drawer-container class="indice-container" [hasBackdrop]="'True'">

    <!-- menu lateral -->
    <mat-drawer id="menuIndice" class="menu-indice menuIndice sidenav-fixed" #drawer2 [mode]="'over'">
        <div class="contenedor-menu">

            <!-- cabezal del titulo -->
            <div class="cerrar-Indice-container">
                <h1 class="titulo-cerrar">Índice</h1>
                <a class="btn-cerrar" (click)="drawer2.toggle()">
                    <i class="fas fa-times color-naranja"></i>
                </a>
            </div>

            <!-- arbol de contenido -->
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

                <!-- esta es para nodos sin hijos -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>

                    <a (click)="scrollTo(node.id)" class="btn-nodo sin-hijos text-wrap" mat-flat-button>
                        <i class="fas fa-bookmark mr-2"></i> {{node.name}}
                    </a>
                </mat-tree-node>

                <!-- esta es para los nodos que tienen hijos -->
                <mat-tree-node class="nodo-hijo" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                    <button class="btn-nodo" mat-flat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                        <span>
                            {{node.name}}
                        </span>
                    </button>

                </mat-tree-node>
            </mat-tree>
        </div>
    </mat-drawer>

    <!-- contenido -->
    <mat-drawer-content>
        <a id="indiceBtn" (click)="drawer2.toggle()" class="btn btn-indice-onscroll waves-effect wow fadeInLeft" hidden>
            Ver Índice
        </a>

        <a id="scrollBtn" (click)="scrollTop()" class="btn btn-floating btn-scrollUp waves-effect wow fadeInUp" hidden>
            <i class="fas fa-arrow-up"></i>
        </a>

        <!-- introduccion -->
        <section id="sec1" class="bg-blanco p-3">
            <!--logo-->
            <div class="container d-flex justify-content-center">
                <div class="mt-sm-0 mt-5 ">
                    <img class="img-section" src="assets/images/tituloOdonto.svg" alt="SURSOFT -  Odonto Integral Proyect">
                </div>
            </div>
            <br>

            <div class="container padding-sec">

                <h2 class="h2-section">Proyecto Odonto Integral</h2>

                <!--cuerpo-->
                <div class="d-xl-flex">
                    <!--descripcion-->
                    <article class="color-article">
                        <p>
                            Este proyecto nació por la necesidad de contar con una aplicación que ofreciera a los administradores y doctores de la clínica, un <b>centrado en la información</b>, tanto en lo referente a el <b>control financiero</b> de esta,
                            como del <b>registro de historias clínicas</b> y actividades de los doctores.
                        </p>

                        <p>
                            Para llevar a cabo el proyecto utilice .Net Core 2.1 en principio la cual recibió actualizaciones a 3.1 y .Net Core 5 en la actualidad, implementando la interfaz de usuario mediante razor pages, MVC, Boostrap y con SQL Server para la persistencia de datos.
                        </p>
                        <p>En este proyecto desarrolle diferentes <b>funcionalidades</b> como:</p>
                        <ul class="mb-0">
                            <li>
                                <p>
                                    <b>Historias clínicas:</b> las mismas son totalmente personalizables en cuanto a anamnesis de las mismas.
                                </p>
                            </li>

                            <li>
                                <p>
                                    <b>Funcionalidades para laboratorios con los que se trabaje:</b> en esta se proporciona la posibilidad de registrar los aranceles de los laboratorios y se añade la posibilidad a cada doctor de calificar los trabajos
                                    entregados, dando una visión general de cuales son los mejor puntuados.
                                </p>
                            </li>

                            <li>
                                <p>
                                    <b>Agenda:</b> se proporciona a los doctores una agenda en la cual se puede sincronizar las citas, ya sea si trabajara en una o varias clínicas de la empresa, de esta manera se evita la superposición de consultas en
                                    las mismas
                                </p>
                            </li>

                            <li>
                                <p>
                                    <b>Gestión de gastos:</b> se proporciona a administradores la posibilidad de gestionar los servicios, impuestos, insumos y proveedores.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Aranceles de la clínica:</b> en esta sección se brinda la posibilidad de crear categorías y tratamientos para que los doctores puedan seleccionar en los planes de tratamiento de tal manera que se va generando el
                                    costo del mismo.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Resúmenes financieros:</b> se proporciona un dashboard desde el cual se puede acceder a toda la actividad y resúmenes financieros de la clínica como de los doctores en particular, también brindando listas de deudores,
                                    gastos, ingresos y ganancias.
                                </p>
                            </li>
                        </ul>
                    </article>

                    <!--imagenes-->
                    <div class="col-xl-5 d-xl-block text-center">
                        <a href="assets/images/odonto.png" data-lightbox="photos">
                            <img class="img-section" src="assets/images/odonto.png" alt="SURSOFT -  Odonto integral 1">
                        </a>
                        <a href="assets/images/odonto2.png" data-lightbox="photos">
                            <img class="img-section mt-4" src="assets/images/odonto2.png" alt="SURSOFT -  Odonto integral 1">
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <!-- Secciones Principales -->
        <section id="sec2" class="bg-gris p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Secciones Principales</h2>

                <article class="color-article">
                    <p>Una vez logueado en el sistema, <b>dependiendo del tipo de usuario</b>, se presentarán varias opciones.</p>
                    <p>Para <b>Super Admin</b> (SA en adelante), <b>Admins</b> y <b>doctores</b> el sistema desplegara en la pantalla principal como en el menu lateral los enlaces a las clínicas en donde trabaja (img. 1.1). También en el menú horizontal
                        tendrá los botones referentes a mails internos del sistema (img. 1.2), calendario de citas (img. 1.3), notificaciones (img. 1.4) y la administración de su cuenta (img. 1.5). </p>
                    <p>En el caso de los <b>pacientes</b> el sistema mostrará una bienvenida y le dará la opción de acceder a su perfil (img. 2.2) e historia clínica (img. 2.1).</p>
                    <p>Estas secciones se verán detalladas más adelante.</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/1.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/1.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 1</p>
                    </div>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/2.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/2.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 2</p>
                    </div>
                </article>
            </div>
        </section>

        <!-- usuarios del sistema -->
        <section id="sec3" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Usuarios del sistema</h2>

                <article class="color-article">

                    <p>El sistema cuenta con 4 tipos de usuario los cuales son:</p>
                    <ul>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec4')">Super Admin y Admin</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec5')">Doctor</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec6')">Paciente</button> </li>
                    </ul>

                    <p>Cada uno de ellos tendrá las vistas y opciones que le correspondan según su nivel y que serán descriptas más adelante. </p>
                </article>

            </div>
        </section>

        <!-- Super Admin -->
        <section id="sec4" class="bg-gris p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Super Admin</h2>

                <article class="color-article">
                    <p>
                        El Super Admin (SA) será el encargado de crear las clínicas del sistema y tendrá acceso a todos los datos de la misma y a realizar cualquier modificación sin restricción.
                    </p>
                    <p>
                        Para ello se le dará el enlace en el menú lateral de administración de clínicas (img. 3.1).
                    </p>

                    <p>
                        En la <b>sección de clínicas</b> del SA se le presentara una lista con las clínicas del sistema (img. 3.2), en donde tendrá la información de <b>cantidad de pacientes</b> (img. 3.7), cantidad de <b>consultorios</b> (img. 3.8) y
                        mediante el botón de detalles (img. 3.4) podrá ver los <b>datos básicos de la clínica</b> como los <b>doctores</b> que trabajan en la misma (img. 4)
                    </p>

                    <p>
                        También tendrá la posibilidad de <b>crear nuevas clínicas</b> (img. 3.3), <b>editar</b> las existentes (img. 3.5) o <b>eliminarlas</b> (img. 3.6).
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/3.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/3.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 3</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/4.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/4.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 4</p>
                        </div>
                    </div>

                    <br>

                    <p>
                        Al ingresar en alguna clínica se le presentara un escritorio con información general de la clínica. En caso de ser SA o Admin también dispondrá de una barra extra para el manejo de todas las opciones del sistema.
                    </p>

                    <p>
                        Cabe destacar que los administradores de clínica solo tienen ese privilegio en aquellas clínicas en donde se le otorgue ese rol, por tanto, un admin de clínica A puede o no ser admin de la B.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/5.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/5.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 5</p>
                    </div>
                </article>
            </div>
        </section>

        <!-- doctores -->
        <section id="sec5" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Doctores</h2>

                <article class="color-article">

                    <p>
                        Los doctores serán ingresados al sistema por los administradores, los cuales deberán confirmar sus cuentas mediante un correo.
                    </p>
                    <p>
                        Una vez registrados, podrán ingresar en sus clínicas en donde verán un menú con las opciones de, <b>inicio</b> (img. 6.1), <b>especialidades</b> agenda (img. 6.2), <b></b> pacientes (img. 6.3), <b></b> (img. 6.4), <b>arancel de tratamientos</b>                        (img. 6.5) y <b>aranceles de laboratorios</b> (img. 6.6).
                    </p>
                    <p>
                        También se le mostraran los pacientes en tratamiento, sus trabajos de laboratorio enviados y las citas para el día.
                    </p>

                    <p>En cuanto a su perfil de usuario, el doctor podrá ver toda su actividad en las diferentes clínicas, tanto como sus datos personales y la posibilidad de cambiar la contraseña de su cuenta (img. 7). </p>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/6.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/6.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 6</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/7.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/7.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 7</p>
                        </div>
                    </div>

                    <p>
                        En la <b>sección de pacientes</b>, (img. 8.1), el doctor podra <b>agregar pacientes</b> (img. 8.2), <b>ver sus datos</b> (img. 8.3 ), <b>editar</b> (img. 8.4) los mismos y acceder a su <b>historia clínica</b> (img. 8.5).
                    </p>
                    <p>
                        Se notará que el doctor no tendrá la posibilidad de <b>eliminar</b> pacientes por razones de seguridad de la clínica, en caso de agregar un paciente por error deberá ponerse en contacto con un admin de la clínica para proceder
                        a la eliminación.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/8.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/8.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 8</p>
                    </div>
                </article>
            </div>
        </section>

        <!-- pacientes -->
        <section id="sec6" class="bg-gris p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Pacientes</h2>

                <article class="color-article">

                    <p>
                        Los pacientes serán agregados al sistema por los doctores o administradores y podrán entenderse en 1 o más clínicas.
                    </p>
                    <p>
                        Una vez confirmadas sus cuentas a través del correo electrónico los mismos podrán ver todos los datos de su perfil con los tratamientos recibidos, pendientes, estado de cuenta con pagos entregados, deuda actual y totales, historial de citas y sesiones
                        clínicas (img. 9).
                    </p>
                    <p>
                        También tendrán la sección de historia clínica, en donde encontraran más detallado sus tratamientos, doctores que lo trataron, índices realizados, Imagenes, preguntas de su historia clínica etc. (img. 10).
                    </p>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/9.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/9.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 9</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/10.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/10.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 10</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- Administración de clínica - intro -->
        <section id="sec7" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Administración de clínica</h2>

                <article class="color-article">
                    <h2>Introducción</h2>

                    <p>
                        La gestión de la clínica esta a cargo de los <b>administradores</b>, lo cuales deberán setear ciertos parámetros para posibilitar el trabajo de los doctores en la misma.
                    </p>

                    <p>
                        Para ello dispondrán de un menú en el que encontrarán las siguientes opciones:
                    </p>

                    <ol>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec8')">Administración de doctores</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec9')">Administración de laboratorios</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec10')">Administración de insumos</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec11')">Administración de servicios </button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec12')">Administración de impuestos</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec13')">Administración de historias clínicas</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec14')">Administración de caras dentales del odontograma</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec15')">Administración de consultorios </button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec16')">Administración de métodos de pago</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec17')">Administración de proveedores</button> </li>
                    </ol>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/11.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/11.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 11</p>
                    </div>

                    <p>
                        A continuación, se describirán cada una de estas opciones.
                    </p>

                </article>
            </div>
        </section>

        <!-- doctores -->
        <section id="sec8" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Administración de doctores</h2>

                    <p>
                        En esta sección se presentará una lista de los doctores de la clínica, en donde podremos agregar nuevos doctores (img. 12.2), ver sus datos (img. 12.4), editarlos (img. 12.5), asignar días de trabajo (img. 12.3) o eliminar (img. 12.6) a los mismos.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/12.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/12.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 12</p>
                    </div>

                    <p>
                        Es particularmente <b>importante</b> en esta sección la <b>asignación de días y horas de trabajo</b> para los doctores, ya que al crear una cita se pedirá en la agenda que se especifique al doctor el cual deberá trabajar en ese
                        día y horario.
                    </p>

                    <h3><u><b>Agregar doctor</b></u></h3>
                    <p>
                        Para agregar un doctor hay <b>dos posibilidades</b>, si el doctor ya trabaja en otra clínica del sistema podremos presionar el botón de <b>agregar desde otra clínica</b> (img. 13.1) y se nos pedirá el mail del doctor, confirmaremos
                        y listo ya quedará habilitado para la actual clínica.
                    </p>

                    <p>
                        La otra opción es <b>agregar todos sus datos</b>, teniendo en cuenta que el mail (img. 13.2) del mismo deberá ser único en el sistema por lo que si el correo ya existe es que el doctor trabaja en otra clínica y deberá usarse la
                        opción anterior, también se deberá tener en cuenta el <b>porcentaje de comisión</b> que le cobrará la clínica por trabajar en la misma (img. 13.3).
                    </p>

                    <p>
                        Para terminar solo se deberá presionar guardar (img. 13.4).
                    </p>

                    <p>
                        Una vez terminado el registro del doctor, <b>se le enviara un mail</b> (img. 13b) para que confirme su cuenta de correo y se le proporcionara su password inicial generado de manera automática por el sistema.
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/13.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/13.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 13</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/73.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/73.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 13b</p>
                        </div>
                    </div>


                    <div id="secEditDoc">
                        <h3><u><b>Editar doctor</b></u></h3>

                        <p>
                            En la edición del doctor podremos cambiar todos los datos excepto el email y el password.
                        </p>
                        <p>
                            También podremos seleccionar las <b>especialidades</b> que posea, lo cual ayudara a los demás doctores a la hora de pedir una interconsulta, teniendo claro cual doctor es especialista de lo que necesita.
                        </p>
                        <p>
                            Las opciones que aparezcan estarán ligadas a las especialidades que los administradores hayan registrado para la clínica en la sección especifica para ello que veremos más adelante.
                        </p>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/15.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/15.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 14</p>
                        </div>
                    </div>

                    <h3><u><b>Diás que atiende</b></u></h3>

                    <p>
                        En esta sección se deberán indicar los días que el doctor atienda y la hora.
                    </p>
                    <p>
                        Como se dijo esto es importante ya que de lo contrario el mismo no aparecerá en el calendario a la hora de crear una cita.
                    </p>
                    <p>
                        También estará disponible una lista en donde el doctor podrá <b>cancelar un día</b> para que no sea posible asignarle una cita para el mismo. No podrá cancelarse un día que ya posea una cita, para esto se deberá eliminar esa cita
                        previamente e informar al paciente.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/14.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/14.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 15</p>
                    </div>

                    <h3><u><b>Eliminar doctor</b></u></h3>

                    <p>
                        Para la eliminación de un doctor basta con presionar el botón correspondiente y confirmar la acción.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/16.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/16.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 16</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- laboratorios -->
        <section id="sec9" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Administración de laboratorios</h2>

                    <p>
                        En esta sección se podrá agregar los laboratorios con los que trabaje la clínica, indicando para cada uno sus aranceles particulares, que servirán a los doctores para el calculo de sus gastos en este rubro.
                    </p>
                    <p>
                        Cada doctor podrá incluir una nota al trabajo recibido y esto conformara una puntuación general, la cual los administradores podrán ver para determinar cual es el mejor (img. 17).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/17.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/17.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 17</p>
                    </div>

                    <p>
                        En la lista de laboratorio tendremos la posibilidad de <b>agregar</b> uno nuevo (img. 18.1), <b>editar el arancel</b> (img. 18.2), ver los <b>detalles</b> de los trabajos encargados al mismo (img. 18.3), <b>editarlo</b> (img. 18.4)
                        o <b>eliminarlo</b> (img. 18.5).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/18.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/18.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 18</p>
                    </div>

                    <h3><u><b>Agregar Laboratorio</b></u></h3>

                    <p>
                        Para agregar un nuevo laboratorio bastara con hacer click en el botón de agregar (img. 18.1), colocar los datos requeridos y presionar en crear (img. 19).
                    </p>

                    <h3><u><b>Editar Laboratorio</b></u></h3>

                    <p>
                        Editar un laboratorio es similar a agregarlo, simplemente presionaremos el botón de editar (img. 18.4) modificaremos los datos y clickearemos en guardar (img. 20).
                    </p>

                    <h3><u><b>Eliminar Laboratorio</b></u></h3>
                    <p>
                        Para la eliminación presionaremos el botón de eliminar (img. 18.5) y confirmaremos la acción.
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/19.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/19.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 19</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/20.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/20.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 20</p>
                        </div>
                    </div>

                    <h3><u><b>Editar arancel del Laboratorio</b></u></h3>

                    <p>
                        Para editar el arancel del laboratorio presionaremos el botón de arancel (img. 18.2), esto nos llevara a la pagina donde se nos presentaran las listas del arancel.
                    </p>
                    <p>
                        Al agregar una categoría (img. 21.1) su tabla contendrá en el cabezal 3 botones que servirán para:
                    </p>
                    <ul>
                        <li>crear un ítem (img. 21.2)</li>
                        <li>editar la categoría (img. 21.3)</li>
                        <li>eliminar la categoría (img. 21.4)</li>
                    </ul>
                    <p>
                        A su vez en la tabla de la categoría encontraremos los detalles de cada item y la posibilidad de editarlos (img. 21.5) o eliminarlo (img. 21.6).
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/21.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/21.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 21</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- insumos -->
        <section id="sec10" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Administración de insumos</h2>

                    <p>
                        En esta sección encontraremos todos los insumos que adquiere la clínica para posibilitar el trabajo en la misma.
                    </p>
                    <p>
                        Se nos presentara un gráfico en el que veremos los insumos agregados y los gastos en el año para los mismos (img. 22.1).
                    </p>
                    <p>
                        También tendremos una lista donde podremos <b>agregar</b> nuevos insumos (img. 22.2), ver los <b>detalles de compras</b> (img. 22.3), <b>editar</b> el insumo (img. 22.4) o <b>eliminarlo</b> (img. 22.5).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/22.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/22.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 22</p>
                    </div>

                    <h3><u><b>Detalles de compras</b></u></h3>

                    <p>
                        Al presionar en los <b>detalles del insumo</b> (img. 22.3) encontraremos un gráfico con las compras del insumo y un promedio ajustado entre la cantidad y el precio de las compras para determinar cómo es la evolución del precio
                        (img. 23.1).
                    </p>

                    <p>
                        También encontraremos una tabla en donde podremos <b>agregar</b> nuevas compras (img. 23.2), <b>editar</b> las agregadas (img. 23.3) o <b>eliminarlas</b> (img. 23.4).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/23.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/23.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 23</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- servicios  -->
        <section id="sec11" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Administración de servicios</h2>

                    <p>
                        En la sección de servicios se presentará un <b>gráfico (img. 24.1)</b> en el cual podremos ver los servicios de la clínica en relación con lo pagado por los mismos.
                    </p>
                    <p>
                        También tendremos una tarjeta en donde podremos ver los <b>servicios</b> ingresados pero que aún <b>no</b> fueron <b>pagados</b> (img. 24.2).
                    </p>
                    <p>
                        Por último, tendremos una tabla en donde podremos <b>agregar</b> nuevos servicios (img. 24.3), ver <b>detalles</b> de los pagos (img. 24.4), <b>editar</b> los servicios (img. 24.5) o <b>eliminarlos</b> (img. 24.6).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/24.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/24.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 24</p>
                    </div>

                    <h3><u><b>Detalles de pagos</b></u></h3>

                    <p>
                        En los detalles de pagos encontraremos un grafico (img. 25.1) donde veremos la evolución de los pagos en el tiempo.
                    </p>
                    <p>
                        También tendremos una tabla donde podremos agregar (img. 25.2), editar (img. 25.3) o eliminar (img. 25.4) pagos.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/25.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/25.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 25</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- impuestos -->
        <section id="sec12" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Administración de impuestos</h2>

                    <p>
                        La sección se podrán ingresar los impuestos que pague la clínica.
                    </p>
                    <p>
                        La estructura de esta sección es similar a la de servicios por lo que no repetiremos lo mismo aquí.
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/26.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/26.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 26</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- historias clínicas -->
        <section id="sec13" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Administración de historias clínicas</h2>

                    <p>
                        En esta sección podremos crear los diferentes tipos de historias clínicas que tendrán disponibles los doctores para realizar las anamnesis.
                    </p>
                    <p>
                        Para esto al <b>crear</b> (img. 27.1) una nueva historia se nos pedirá un titulo para la misma y si es un anexo o no (img. 27.2).
                    </p>

                    <p>
                        Los <b>anexos</b> son aquellas historias complementarias, como por ejemplo los de endodoncia, en las cuales <b>un paciente puede tener varios anexos</b>, uno por cada pieza dental y deberá responder la sintomatología para cada
                        uno, al contrario que la <b>historia general</b> que son
                        <b>únicas</b> por paciente.
                    </p>

                    <p>
                        Esta diferenciación se vera reflejada en la pagina del paciente en su <b>historia clínica</b> donde las encontraremos como en la imagen 28.
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/27.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/27.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 27</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/28.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/28.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 28</p>
                        </div>
                    </div>

                    <p>
                        En la sección de historias clínicas del administrador tendremos una tabla en la que podremos <b>agregar</b>, <b>editar</b> o <b>eliminar</b> una plantilla.
                    </p>
                    <p>
                        Cabe destacar que en las ediciones <b>se puede convertir una historia clínica general en un anexo</b>, pero no a la inversa, ya que como se dijo antes, si hay varios anexos para un paciente sería incompatible con las generales
                        que son únicas.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/29.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/29.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 29</p>
                    </div>

                    <h3><u><b>Detalles de la historia</b></u></h3>

                    <p>
                        Una vez creada la plantilla de la historia ingresaremos en los <b>detalles</b> en donde se podrá <b>crear el cuestionario</b> (img. 30.1) para la misma.
                    </p>
                    <p>
                        Para esto encontraremos un botón para crear cuestionarios (img. 30.1) y debajo una lista con desplegables para cada cuestionario (img. 30.2).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/30.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/30.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 30</p>
                    </div>

                    <p>
                        Una vez creado un cuestionario, <b>desplegaremos el mismo</b> en la lista y tendremos la posibilidad de <b>editar el titulo</b> del cuestionario o <b>eliminarlo</b> y también podremos <b>crear</b>, <b>editar</b> o <b>eliminar</b>                        preguntas del mismo.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/31.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/31.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 31</p>
                    </div>
                    <br>
                    <p>Las preguntas podrán ser <b>verdadero - falso </b>(img. 33.2) o <b>abiertas</b> para escribir (img. 33.1)</p>
                    <p>Por defecto se marcan como VF y su visualización en las historias clínicas de los pacientes será como en la imagen 33.</p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/32.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/32.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 32</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/33.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/33.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 33</p>
                        </div>
                    </div>
                    <br>
                    <p>
                        Es importante destacar que <b>cada clínica tendrá sus propias anamnesis</b> de historias clínicas, independiente de si el paciente se atiende en varias al mismo tiempo, por lo que si un paciente responde la anamnesis de la clínica
                        A y luego va a la B esas respuestas no estarán disponibles en la B, debido a que la estructura de la anamnesis B podría ser diferente.
                    </p>
                    <p>
                        De todos modos, <b>los demás índices</b> como odontogramas, periodontogramas, HIOS, Imagenes, consultas, etc. <b>si estarán disponibles</b> para todas las clínicas donde se atienda el paciente.
                    </p>

                </article>
            </div>
        </section>

        <!-- caras dentales del odontograma -->
        <section id="sec14" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Administración de caras dentales del odontograma</h2>

                    <p>
                        En esta sección podremos personalizar los estados de las caras dentales que aparecen como opción en los odontogramas (img. 35.1).
                    </p>
                    <p>
                        Por defecto siempre existirán los <b>3 tipos básicos</b>, que se utilizan para la creación de los índices de CPOD o CEOD, los cuales <b>no podrán ser eliminados</b>, pero si se podrán modificar sus colores representativos.
                    </p>
                    <p>
                        En cuanto a los nuevos estados, no serán contabilizados para los anteriores índices.
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/34.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/34.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 34</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/35.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/35.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 35 (sección historia clínica paciente)</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- consultorios  -->
        <section id="sec15" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Administración de consultorios</h2>

                    <p>
                        En esta sección podremos agregar la cantidad de consultorios que tengamos disponibles en la clínica.
                    </p>
                    <p>
                        Para ello tendremos la tabla donde contaremos con los botones de <b>agregar</b> (img. 36.1), <b>editar</b> (img. 36.2) y <b>eliminar</b> (img. 36.3) los consultorios.
                    </p>
                    <p>
                        Los mismos son necesarios para la creación de citas en la agenda y su color será el reflejado en la agenda.
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/36.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/36.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 36</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/37.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/37.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 37 (sección agenda)</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- métodos de pago -->
        <section id="sec16" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Administración de métodos de pago</h2>

                    <p>
                        En esta sección podremos agregar los diferentes métodos de pago que se ingresaran por los doctores a la hora de crear una sesión clínica.
                    </p>

                    <p>
                        Para esto tendremos una <b>tabla con los métodos disponibles</b> (img. 38) los cuales serán mostrados en la <b>creación de sesiones</b> (img. 39).
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/38.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/38.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 38</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/39.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/39.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 39 (sección de sesiones clinicas de pacientes)</p>
                        </div>
                    </div>

                    <p>Como vemos tenemos los botones para <b>agregar</b> (img. 38.1), <b>editar</b> (img. 38.2)o <b>eliminar</b> (img. 38.3) un método de pago y además un botón para especificar la <b>cantidad de cuotas</b> que posea (img. 38.4), si fuera
                        necesario.
                    </p>

                    <p>
                        Si el método <b>tiene cuotas</b>, se ingresaran los <b>días</b> que son requeridos <b>para la acreditación</b> del mismo (img. 40), ya que eso será utilizado a la hora de hacer los cálculos de ganancias de los doctores y por tanto
                        de la clínica en cuanto a su porcentaje.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/40.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/40.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 40</p>
                    </div>

                    <p>
                        El botón de <b>detalles</b> del metodo (img. 38.5) nos dará información sobre los pagos que fueron registrados para cada método, pudiendo ser <b>filtrados por fecha, tipo de cobro y doctores</b>.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/41.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/41.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 41</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- proveedores -->
        <section id="sec17" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Administración de proveedores</h2>

                    <p>
                        En esta sección se ingresarán todos los proveedores de la clínica, necesarios para el ingreso de compras en las secciones de servicios, impuestos e insumos.
                    </p>
                    <p>
                        En la tabla tendremos botones para <b>agregar</b> (img. 42.1), <b>editar</b> (img. 42.2) o <b>eliminar</b> (img. 42.3) a los proveedores, así como un botón de <b>detalles</b> (img. 42.4) donde encontraremos una tabla con los detalles
                        de compras o pagos para cada uno.
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/42.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/42.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 42</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/43.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/43.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 43</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- Secciones para Doctores y Admins - introduccion -->
        <section id="sec18" class="bg-gris p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Secciones para Doctores y Admins</h2>

                <article class="color-article">
                    <h2>Introducción</h2>

                    <p>
                        En la página de home de las clínicas encontraremos, un menú que contendrá las opciones generales para doctores y administradores que dará acceso a:
                    </p>

                    <ol>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec19')">Inicio de la clínica</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec26')">Agenda</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec30')">Pacientes</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec22')">Especialidades</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec23')">Arancel de la clínica</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec24')">Aranceles de laboratorio</button> </li>
                    </ol>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/47.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/47.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 44</p>
                    </div>


                </article>
            </div>
        </section>

        <!-- Inicio de clinica -->
        <section id="sec19" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Página Inicio Clínica</h2>

                    <p>
                        En la página de inicio de las clínicas encontraremos un primer sector en el que se mostraran los <b>pacientes</b> que se encuentren <b>en tratamiento</b> (img. 45.1), los <b>trabajos de laboratorio</b> que fueron <b>enviados</b>                        (img. 45.2) y las <b>citas del día</b> (img. 45.3) que serán específicas para cada doctor.
                    </p>
                    <p>
                        Esta seccion estara <b>disponible</b> tanto para <b>administradores</b> como para los <b>doctores</b> de la clínica.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/44.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/44.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 45</p>
                    </div>

                    <p>
                        Luego tendremos una sección <b>solo disponible para administradores</b> en la que se presentara un recordatorio de las <b>facturas sin pagar</b> de la clínica (img. 46.1), una <b>sección con reportes</b> (img. 46.2 que se describirán
                        más adelante) donde encontraremos:
                    </p>

                    <ul>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec20')">Reporte de doctores </button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec21')">Reporte de deudores</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec22')">Reporte de ingresos</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec23')">Reporte de gastos</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec24')">Reporte de ganancias</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec25')">Resumen general</button> </li>
                    </ul>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/45.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/45.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 46</p>
                    </div>

                    <p>
                        También se dispondrá de una <b>tabla general</b> (img. 47.1) en la que encontraremos los ingresos, gastos y ganancias de la clínica por mes y su <b>gráfico correspondiente</b> (img. 47.2).
                    </p>

                    <p>
                        Por último, encontraremos un <b>gráfico</b> que nos mostrara los <b>doctores mas activos</b> tomando en cuenta las actividades de sesión que haya tenido cada uno (img. 46.3).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/46.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/46.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 47</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Reporte de doctores -->
        <section id="sec20" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Reporte de doctores</h2>

                    <p>
                        En esta sección encontraremos una <b>tabla</b> (img. 48.2) con los datos generales para cada doctor, que podrá ser <b>filtrada por fecha</b> (img. 48.1).
                    </p>

                    <p>
                        En ella veremos sus ingresos, gastos de laboratorio, comisión que aporta a la clínica y sus ganancias.
                    </p>

                    <p class="resaltado">
                        Cabe destacar que la <b>comisión de la clínica</b> se cobra a partir de las <b>ganancias del doctor</b>, no de los ingresos, y que <b>las ganancias se producen una vez que en un tratamiento se han cumplido los pagos de trabajos de laboratorio</b>                        por parte del doctor.
                    </p>

                    <p>
                        Una vez <b>saldada esa deuda por parte del paciente se comienza a calcular las ganancias</b> , ya que un paciente puede entregarle al doctor un montón insuficiente para los gastos de laboratorio y no se podría cobrarle por un tratamiento
                        sin que el mismo tuviera el dinero disponible.
                    </p>

                    <p>
                        También si queremos profundizar en los <b>detalles</b> de los datos de la tabla podremos hacerlo con el botón correspondiente de detalles (img. 48.3).
                    </p>

                    <p>
                        Por ultimo se presenta un <b>grafico con las ganancias por mes</b> de todos los doctores (img. 48.4).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/48.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/48.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 48</p>
                    </div>
                    <br>

                    <h3><u><b>Detalles de doctor</b></u></h3>

                    <p>
                        Al ingresar a los detalles del doctor tendremos una sección principal en la que veremos un <b>resumen de sus ingresos gastos y ganancias</b> (img. 49.1).
                    </p>

                    <p>
                        Debajo de esto encontraremos un <b>filtro de fechas</b> (img. 49.2) para la tablas de la pagina y más abajo una primera <b>tabla general</b> de la actividad del doctor (img. 49.3).
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/49.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/49.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 49</p>
                    </div>
                    <br>

                    <p>
                        Luego encontraremos dos tablas en azul con el detalle de los cobros realizados en las sesiones, una para <b>ingresos facturados</b> (img. 50.1) y otra para los <b>ingresos sin factura</b> (img. 50.2).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/50.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/50.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 50</p>
                    </div>
                    <br>

                    <p>
                        Siguiendo a las anteriores tablas, encontraremos una de color rojo que mostrara los <b>gastos de laboratorio</b> del doctor (img. 51.1) y una naranja en la que se nos mostraran los <b>trabajos realizados</b> que cobro el doctor,
                        pero <b>que sus pagos son retenidos</b> hasta una fecha en concreto según el método de pago que seleccionara (img. 51.2), ya que, si bien ese dinero esta acreditado al doctor, no estaría disponible para él por lo que no se tomaría
                        en cuenta como ganancia hasta su liberación.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/51.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/51.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 51</p>
                    </div>
                    <br>

                    <p>
                        Por último, se presenta un <b>gráfico con los ingresos, gastos y ganancias</b> del doctor por mes.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/52.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/52.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 52</p>
                    </div>
                </article>
            </div>
        </section>

        <!-- Reporte de deudores -->
        <section id="sec21" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Reporte de deudores</h2>

                    <p>
                        En esta sección encontraremos una tabla con todos los pacientes que posean una deuda en sus tratamientos con un acceso rápido a sus historias clínicas.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/53.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/53.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 53</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Reporte de ingresos -->
        <section id="sec22" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Reporte de ingresos de la clínica</h2>

                    <p>
                        En esta sección encontraremos un resumen de los <b>ingresos de la clínica</b>, los cuales no deben confundirse con los ingresos de los doctores.
                    </p>

                    <p>
                        Los <b>ingresos de la clínica son los que provienen del porcentaje que se les cobra a los doctores</b> por trabajar en la misma, los cuales ya vimos que son seteados en la sección de doctores/edición/comisión de la clínica
                        <button class="link-pagina sin-borde" (click)="scrollTo('#sec8')">(ver detalles aquí)</button>.
                    </p>

                    <p>
                        Primero encontraremos un <b>filtro de fechas</b> (img. 54.1) y seguido a el tendremos un <b>resumen de los ingresos generales</b> (img. 54.2) (todo lo que ingresan como pago los doctores) y los <b>ingresos de la clínica reales</b>                        (que son los que se calculan por el porcentaje cobrado a los doctores sobre sus ganancias).
                    </p>

                    <p>
                        Debajo de este resumen general encontraremos una <b>tabla general</b> (img. 54.3) con el detalle de los doctores, similar al de reporte de doctores.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/54.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/54.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 54</p>
                    </div>
                    <br>
                    <p>
                        Luego de la anterior tabla encontraremos dos mas de color azul, en la que veremos los detalles de <b>ingresos con factura</b> (img. 55.1) y <b>sin factura</b> (img. 55.2).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/55.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/55.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 55</p>
                    </div>
                    <br>
                    <p>
                        Por último, tendremos la tabla de <b>trabajos realizados con pagos retenidos</b> (img. 56.1) por el método de pago y un <b>gráfico de ingresos de la clínica</b> (img. 56.2) en la que se pueden ver los ingresos totales y los ingresos
                        reales de la clínica.
                    </p>
                    <br>
                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/56.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/56.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 56</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Reporte de gastos -->
        <section id="sec23" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Reporte de gastos</h2>

                    <p>
                        En esta sección encontraremos un <b>filtro de fechas</b> (img. 57.1) como siempre, luego un <b>resumen general</b> (img. 57.2) de gastos para insumos, impuestos y servicios junto con un total.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/57.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/57.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 57</p>
                    </div>
                    <br>
                    <p>
                        Debajo de esto encontraremos las tablas con los detalles de <b>gastos en insumos</b> (img. 57.3), <b>impuestos</b> (img. 58.1), <b>servicios</b> (img. 58.2).
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/58.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/58.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 58</p>
                        </div>

                    </div>

                    <p>
                        Por último, tendremos un <b>gráfico con los gastos</b> de las 3 categorías por mes (img. 59).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/59.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/59.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 59</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Reporte de ganancias -->
        <section id="sec24" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Reporte de ganancias</h2>

                    <p>
                        En esta sección encontraremos un <b>filtro de años</b> (img. 60.1) seguido de un <b>resumen general</b> (img. 60.2) de ingresos, gastos y ganancias de la clínica.
                    </p>

                    <p>
                        Seguido a lo anterior tendremos una <b>tabla general</b> (img. 60.3) en la que veremos las 3 categorías por mes y sus totales.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/60.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/60.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 60</p>
                    </div>

                    <p>
                        Finalmente tendremos un <b>gráfico</b> representando la tabla anterior (img. 61).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/61.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/61.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 61</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Resumen general -->
        <section id="sec25" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Resumen general</h2>

                    <p>
                        La última sección de la pagina de inicio de las clínicas es la de <b>reporte general</b> en la que encontraremos simplemente un <b>filtro por fechas</b> (img. 62.1) y la <b>tabla</b> con los datos de <b>ingresos y gastos</b> tanto
                        de doctores como de la clínica (img. 62.2).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/62.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/62.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 62</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- agenda - intro -->
        <section id="sec26" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Agenda</h2>

                    <p>
                        La agenda del sistema se puede acceder tanto desde el <b>menú superior</b> (img. 63.2) como desde el <b>menú de accesos directos</b> (img. 63.1) una vez ingresado a una clínica.
                    </p>

                    <p>
                        Si el doctor trabaja en más de una clínica al presionar el botón del menú superior se desplegará las agendadas para cada una.
                    </p>

                    <p>
                        En la agenda encontraremos 3 botones superiores, en los que podremos ver todas las <b>citas del doctor</b> (img. 63.3) loqueado para todas las clínicas, luego encontraremos un acceso rápido a los <b>días de trabajo</b> del doctor
                        (img. 63.4) en donde, como ya vimos, el mismo podrá especificar días en lo que no acepte nuevas citas y por último un botón de <b>doctores</b> (img. 63.5) en la que se podrá acceder a una lista con los demás doctores de la clínica
                        y sus horarios de trabajo (img. 64) en caso de querer agendar una cita con algún especialista para algún paciente.
                    </p>

                    <p>
                        Luego tendremos un <b>calendario</b> donde veremos las citas de la clínica y en el cual agregaremos las mismas (img. 63.6).
                    </p>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/63.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/63.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 63</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/64.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/64.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 64</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- Agrega cita -->
        <section id="sec27" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">

                    <h3><u><b>Agregar Cita</b></u></h3>

                    <p>
                        Para <b>agregar una cita</b> presionaremos en algún <b>espacio libre</b> de un día sobre el calendario y se nos llevara al formulario para la misma.
                    </p>

                    <p>
                        En la página de creación veremos el <b>formulario</b> y una <b>tabla con las citas agendadas</b> para ese día con el horario de inicio y fin de las mismas a modo de ayuda.
                    </p>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/65.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/65.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 65</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/66.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/66.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 66</p>
                        </div>
                    </div>
                    <br>
                    <p>
                        En el formulario deberemos indicar el <b>titulo de la cita</b>, el <b>doctor que atiende</b> el cual será seleccionado desde un desplegable que mostrara los nombres y horarios de los que estuvieran disponibles para ese día. Si
                        el <b>doctor actual</b> atendiera ese día sera <b>seleccionado por defecto</b>.
                    </p>

                    <p>
                        También será <b>necesario indicar el consultorio</b> en el cual se atenderá para hacer la reserva del mismo y se proporcionará, de manera automática, el día de la fecha de inicio, al cual deberá el doctor agregarle la hora de la
                        cita.
                    </p>

                    <p>
                        Cabe destacar que si no se proporciona una fecha y hora de fin, se colocara de manera automática la fecha de inicio con una duración de 30 minutos.
                    </p>

                    <p>
                        El sistema se encargará de controlar todo lo referente a la disponibilidad del doctor al que se le asigna la cita, ya que, si bien puede no tener una cita en ese día para ese horario en esa clínica, si podría tener una cita en ese horario y día en otra.
                    </p>

                    <p>
                        Una vez agregada la cita se redirigirá al calendario en donde veremos la misma.
                    </p>

                    <p>
                        Si un <b>doctor agrega una cita para otro</b>, el segundo recibirá una <b>notificación</b> de que otro doctor le agendo una cita (img. 67).
                    </p>

                    <p>
                        A los <b>pacientes</b> se le enviara un <b>correo electrónico el día antes</b> de la cita para recordarles la misma (img. 68).
                    </p>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/67.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/67.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 67</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/70.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/70.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 68</p>
                        </div>
                    </div>


                </article>
            </div>
        </section>

        <!-- Editar cita -->
        <section id="sec28" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">

                    <h3><u><b>Editar una Cita</b></u></h3>

                    <p>
                        Para ver los detalles y editar una cita tendremos que dar click en la misma (img. 69.1) y veremos las opciones editar (img. 69.2) y eliminar (img. 69.2).
                    </p>

                    <p>
                        Cabe destacar que <b>solo el doctor</b> que este marcado <b>en la cita  o un administrador</b> podrá editar o eliminar la misma, sin importar quien la creara.
                    </p>

                    <p>
                        En el formulario de edición notaremos que hay <b>dos opciones nuevas</b> que son <b>falta con aviso</b> y <b>falta sin aviso</b> (img. 70) las cuales ayudaran a valorar el compromiso de los pacientes en cuanto a sus asistencias.
                    </p>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/68.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/68.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 69</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/69.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/69.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 70</p>
                        </div>
                    </div>



                </article>
            </div>
        </section>

        <!-- Eliminar cita -->
        <section id="sec29" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h3><u><b>Eliminar una Cita</b></u></h3>

                    <p>
                        Para <b>eliminar una cita</b> se deberá presionar sobre la misma (img. 71.1), como en editar, y en los detalles seleccionar y <b>confirmar la eliminación</b> (img. 71.2).
                    </p>

                    <p>
                        Como se dijo anteriormente, <b>solo el doctor de la cita o un administrador pueden eliminar</b> las mismas, en caso de que sea un admin también se notificara al doctor de la eliminación (img. 72).
                    </p>


                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/71.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/71.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 71</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/72.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/72.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 72</p>
                        </div>
                    </div>
                </article>
            </div>
        </section>

        <!-- Pacientes - introduccion -->
        <section id="sec30" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Pacientes</h2>

                <article class="color-article">

                    <p>
                        Esta sección es de las principales del sistema y en ella encontraremos, en su primera página, una tabla con los pacientes de la clínica.
                    </p>

                    <p>
                        En ella tendremos opciones para <b>agregar</b> (img. 73.1), <b>editar</b> (img. 73.2) o <b>eliminar</b> (img. 73.3) pacientes más un botón para ver los <b>detalles</b> del mismo (img. 73.4) y otro para ver su <b>historia clínica</b>                        (img. 73.5).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/74.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/74.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 73</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Agregar paciente -->
        <section id="sec31" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Agregar paciente</h2>

                    <p>
                        Para <b>agregar un paciente</b> haremos click en el botón correspondiente (img. 73.1) y se nos redirigirá al formulario al que llenaremos con los datos del mismo.
                    </p>

                    <p>
                        Como ocurre en el caso del registro de doctores, aquí también podremos <b>agregar</b> pacientes <b>de otras clínicas</b> (img. 74.1) simplemente ingresando su correo si presionaremos el botón de agregar desde otra clínica.
                    </p>

                    <p>
                        Cabe destacar que, si llenamos el formulario y lo guardamos, pero el paciente ya se atendía en otra clínica se copiaran los datos ya existentes de todas formas.
                    </p>

                    <p>
                        Una vez ingresado el paciente al sistema <b>se le enviara un correo</b> electrónico para confirmar su cuenta si lo desea y poder ver su historia (img. 75).
                    </p>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/75.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/75.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 74</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/76.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/76.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 75</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- Editar paciente -->
        <section id="sec32" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Editar paciente</h2>

                    <p>
                        Para editar un paciente haremos click en el botón correspondiente (img. 73.2) y se nos presentara el formulario con los datos basicos editables del mismo (img. 76).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/77.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/77.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 76</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Eliminar paciente -->
        <section id="sec33" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Eliminar paciente</h2>

                    <p>
                        Para <b>eliminar un paciente</b> haremos click en el botón correspondiente (img. 77.1) y deberemos confirmar la acción (img. 77.2).
                    </p>

                    <p>
                        Cabe destacar que, si el paciente se atiende en varias clínicas solo se eliminara el mismo de la clínica actual en la que se está eliminando.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/78.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/78.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 77</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Historia clinica - introduccion -->
        <section id="sec34" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Historia Clínica</h2>

                <article class="color-article">

                    <p>
                        La página principal de la historia clínica nos presentara <b>3 secciones</b> principales:
                    </p>

                    <div class="d-flex">
                        <div>
                            <ul>
                                <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec35')">Resumen de Historia (img. 78.1)</button> </li>
                                <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec36')">Historia clínica (img. 78.2)</button> </li>
                                <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec37')">Consultas (img. 78.3)</button> </li>
                            </ul>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/79.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/79.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 78</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- Resumen de Historia -->
        <section id="sec35" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section"></h2>

                <article class="color-article">

                    <h3><u><b>Resumen de Historia</b></u></h3>

                    <p>
                        En la sección de <b>resumen de historia</b> tendremos primero un <b>resumen de los pagos</b> del paciente indicando los <b>pagos realizados</b> (img. 79.1), su <b>deuda actual</b> (img. 79.2) y un <b>total</b> (img. 79.3) de todos
                        los tratamientos.
                    </p>

                    <p>
                        Luego tendremos la sección de<button class="link-pagina sin-borde" (click)="scrollTo('#sec38')"><b>sesiones clínicas</b></button>(img. 79.4), en donde se <b>registrarán las actividades de cada sesión</b> por parte del doctor y
                        donde se ingresarán los pagos del mismo para cada sesión.
                    </p>

                    <p>
                        A continuación, veremos el<button class="link-pagina sin-borde" (click)="scrollTo('#sec39')"><b>historial de citas</b></button>(img. 79.5) donde tendremos un panorama del compromiso del paciente con sus tratamientos al asistir
                        a las consultas.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/80.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/80.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 79</p>
                    </div>
                </article>
            </div>
        </section>

        <!-- Historia clínica -->
        <section id="sec36" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section"></h2>

                <article class="color-article">
                    <h3><u><b>Historia clínica</b></u></h3>

                    <p>
                        En la sección de <b>historia clínica</b> encontraremos enlaces para todos los apartados de la misma, los cuales son:
                    </p>
                    <ol>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec40')"><b>Anamnesis:</b></button> estas serán las preguntas generales de la historia clínica.
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec41')"><b>Anexos de Historia:</b></button> aquí encontraremos todos los anexos con sus respectivas preguntas (ejemplo: anexo de endodoncia, anexo RX, anexo periodoncia,
                            etc.).
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec39')"><b>Lista de tratamientos:</b></button> aquí encontraremos la lista de tratamientos realizados y pendientes del paciente y es donde marcaremos los mismo como
                            terminados o no, o rechazados.
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec40')"><b>Trabajos de laboratorios:</b></button> aquí tendremos una lista con los trabajos de laboratorio para el paciente.
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec44')"><b>Odontograma</b></button>
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec45')"><b>Periodontogramas</b></button>
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec46')"><b>Índices de inflamación gingival</b></button>
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec47')"><b>Índices de Higiene oral simplificados</b> (I.H.O.S.)</button>
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec48')"><b>Imagenes</b></button>
                        </li>
                    </ol>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/81.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/81.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 80</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Consultas -->
        <section id="sec37" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section"></h2>

                <article class="color-article">
                    <h3><u><b>Consultas</b></u></h3>

                    <p>
                        Por último, encontraremos una tabla con la <b>lista de<button class="link-pagina sin-borde" (click)="scrollTo('#sec49')"><b>consultas</b></button>
                        </b>del paciente.
                    </p>

                    <p>
                        Cabe destacar que, <b>una consulta deberá tener su plan de tratamiento</b> , el cual será referenciado en las sesiones clínicas a la hora de agregarlas y las consultas por tanto será el <b>puntapié inicial</b>.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/82.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/82.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 81</p>
                    </div>

                    <p>
                        Todas las secciones anteriores se describirán a más adelante en profundidad.
                    </p>
                </article>
            </div>
        </section>

        <!-- Sesiones clinicas -->
        <section id="sec38" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Sesiones clínicas</h2>

                    <p>
                        En esta sección encontraremos un <b>resumen general</b> (img. 82.1) de lo abonado por el paciente y luego una <b>tabla con todas las sesiones</b> y actividades que se le realizaron al mismo y que doctor trabajo con él.
                    </p>

                    <p>
                        Tendremos <b>3 botones</b> a disposición en la lista que serán de <b>agregar</b> (img. 82.2), <b>editar</b> (img. 82.3) y <b>eliminar</b> (img. 82.4) una sesión.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/83.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/83.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 82</p>
                    </div>

                    <h3><u><b>Agregar sesión</b></u></h3>

                    <p>
                        Para agregar una nueva sesión clínica presionaremos el botón de <b>agregar</b> (img. 82.2) y luego completaremos los campos requeridos (img. 83).
                    </p>

                    <p>
                        Cabe destacar que uno de los requeridos es el <b>plan de tratamiento</b> al cual se refiere la sesión, por tanto, si el doctor no tiene un plan de tratamiento, no se podrá agregar una sesión.
                    </p>

                    <h3><u><b>Editar sesión</b></u></h3>

                    <p>
                        Para editar una sesión presionaremos el botón de <b>editar</b> (img. 82.3) y luego modificaremos los campos y <b>confirmaremos los cambios</b>.
                    </p>

                    <p>
                        <b>Solo se podrán</b> modificar las sesiones por el <b>doctor que las creo</b> o por un <b>administrador</b>.
                    </p>

                    <h3><u><b>Eliminar sesión</b></u></h3>

                    <p>
                        <b>La eliminación</b> de una sesión clínica está <b>restringida</b> <b>únicamente para administradores</b> por razones de seguridad.
                    </p>
                    <p>
                        Para eliminar una sesión simplemente presionaremos el botón correspondiente (img. 85.1) y confirmaremos la acción (img. 85.2).
                    </p>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/84.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/84.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 83</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/85.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/85.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 84</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/86.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/86.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 85</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- Historial de citas -->
        <section id="sec39" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Historial de citas</h2>

                    <p>
                        En esta sección encontraremos un <b>resumen general de las asistencias</b> del paciente a sus citas (img. 86.1) y luego una <b>tabla con el detalle</b> de las mismas.
                    </p>
                    <p>
                        También tendremos accesos directos para <b>agregar</b> (img. 86.2), <b>editar</b> (img. 86.3) o <b>eliminar</b> (img. 86.4) una cita, los cuales nos dirigirán a los formularios que vimos anteriormente en la sección de<button class="link-pagina sin-borde"
                            (click)="scrollTo('#sec26')"><b>agenda</b></button>.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/87.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/87.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 86</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Anamnesis -->
        <section id="sec40" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Anamnesis</h2>

                    <P>
                        Esta sección presentara dos opciones para su ingreso:
                    </P>

                    <ul>
                        <li>
                            <b>Editar:</b> aquí el doctor podrá realizar el cuestionario al paciente registrado las respuestas del mismo (img. 87.1).
                        </li>
                        <li>
                            <b>Resumen:</b> aquí se presentará las respuestas afirmativas del paciente y las preguntas abiertas que tuvieran respuesta (img. 87.2).
                        </li>
                    </ul>

                    <p>
                        Si al ingresar en la opción de editar, <b>no encuentra ninguna historia, cuestionario o preguntas</b> deberá dirigirse a la sección de<button class="link-pagina sin-borde" (click)="scrollTo('#sec13')"><b>administración de historias clínicas</b></button>para
                        la creación de la misma.
                    </p>


                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/88.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/88.jpg" style="height: 150px;" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 87</p>
                    </div>

                    <h3><u><b>Editar Anamnesis</b></u></h3>

                    <p>
                        En la página de editar anamnesis encontraremos un formulario con un <b>menú superior</b> (img. 88.1), en el que podremos <b>seleccionar el tipo de historia</b> clínica (si se dispusiera de más de una en la clínica), luego tendríamos
                        un <b>menú lateral</b> (img. 88.2) en el que encontraríamos los <b>cuestionarios de la historia</b>.
                    </p>
                    <p>
                        En la <b>parte central</b> se desplegarán las <b>preguntas</b> de cada cuestionario (img. 88.3) y un <b>botón de guardar</b> (img. 88.4).
                    </p>
                    <p>
                        Cabe destacar que es posible pasar por todos los cuestionarios registrando las respuestas y al final presionar guardar para persistir los datos o se puede ir presionando el botón cuando sea necesario para un guardado parcial.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/89.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/89.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 88</p>
                    </div>

                    <h3><u><b>Resumen de Anamnesis</b></u></h3>

                    <p>
                        En el resumen de la anamnesis encontraremos, como se dijo, todas las <b>preguntas</b> que fueron respondidas como <b>afirmativas</b> por el paciente o que tengan una <b>respuesta abierta contestada</b>.
                    </p>
                    <p>
                        Se podrá seleccionar el tipo de historia que nos interese, si la clínica tuviera mas de un formato para la misma (img. 89.1).
                    </p>
                    <p>
                        También dispondremos de un <b>botón para exportar</b> las respuestas del paciente a formato <b>pdf</b> (img. 89.2) y poder imprimir el cuestionario para que lo firme el profesional y el paciente (img. 90).
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/90.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/90.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 89</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/91.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/91.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 90 (pdf)</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- Eliminar sesion -->
        <section id="sec41" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Anexos de Historia Clínica</h2>
                    <p>
                        En esta sección encontraremos todos los anexos agregados por los administradores en la sección de historias clínicas<button class="link-pagina sin-borde" (click)="scrollTo('#sec13')"><b>(consultar aquí)</b></button>.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/92.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/92.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 91</p>
                    </div>
                    <br>

                    <p>
                        Al ingresar se nos presentaran todos los anexos, cada uno con 3 opciones, <b>nuevo</b> (img. 92.1), <b>editar</b> (img. 92.2) y <b>resúmenes</b> (img. 92.3).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/93.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/93.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 92</p>
                    </div>

                    <h3><u><b>Agregar un anexo</b></u></h3>

                    <p>
                        Al presionar el botón de <b>nuevo anexo</b> (img. 93.1) se nos pedirá un <b>titulo</b> para el mismo (ej. Pieza 44) y luego se nos desplegaran todos los cuestionarios y preguntas para ese anexo al igual que con la anamnesis general
                        (img. 94).
                    </p>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/94.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/94.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 93</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/95.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/95.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 94</p>
                        </div>
                    </div>

                    <h3><u><b>Editar o eliminar un anexo</b></u></h3>

                    <p>
                        Para <b>editar un anexo</b> primero presionaremos en el botón correspondiente (img. 92.2) de el que queremos y luego seleccionaremos el anexo que vamos a editar (img. 95.1).
                    </p>
                    <p>
                        Modificaremos lo que corresponda y presionaremos el botón de <b>modificar</b> (img. 95.2).
                    </p>
                    <p>
                        También tendremos en el menú lateral, en la parte superior del mismo, un botón para <b>editar el titulo</b> del anexo (img. 95.3) o <b>eliminarlo</b> (img. 95.4).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/96.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/96.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 95</p>
                    </div>

                    <h3><u><b>Resúmenes de anexos</b></u></h3>

                    <p>
                        En este apartado, como vimos en el de anamnesis veremos las respuestas afirmativas del paciente y las abiertas que haya respondido el mismo.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/97.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/97.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 96</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Lista de tratamientos -->
        <section id="sec42" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Lista de tratamientos</h2>

                    <p>
                        En esta sección veremos en el card principal un <b>resumen de tratamientos</b> pendientes (img. 97.1), realizados (img. 97.2) y rechazados por el paciente (img. 97.3).
                    </p>

                    <p>
                        Si presionamos el botón de <b>detalles</b> (img. 97.4) podremos modificar cada uno de los tratamientos que hayamos agregado al plan, lo cual veremos más adelante.
                    </p>
                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/98.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/98.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 97</p>
                    </div>
                    <br>

                    <p>
                        Al ingresar en los detalles de los tratamientos veremos dos listas, una con los <b>tratamientos pendientes</b> (img. 98.1) y otra con lo <b>realizados</b> (img. 98.2).
                    </p>
                    <p>
                        Si el usuario es un <b>administrador</b> podrá <b>cambiar el precio</b> del tratamiento, por uno diferente al que viene por defecto desde el arancel (img. 98.3).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/99.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/99.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 98 (Vista administradores)</p>
                    </div>

                    <h3><u><b>Editar un tratamiento</b></u></h3>

                    <p>
                        Para editar un tratamiento simplemente modificaremos los valores necesarios y presionaremos el botón de edición del mismo (img. 98.4).
                    </p>

                    <p>
                        En lo referente al estado de <b>“rechazado por el paciente”</b>, lo que realiza el mismo es <b>dejar constancia en la historia clínica</b> que el paciente no quiso realizarse el mismo, a pesar de que el profesional lo recomienda.
                    </p>

                    <p>
                        <b>El estado aparecerá en el consentimiento informado</b> del paciente el cual se deberá hacer firmar para evitar cualquier tipo de problema legal para el profesional (img. 99).
                    </p>


                    <h3><u><b>Eliminar un tratamiento</b></u></h3>

                    <p>
                        Para eliminar un tratamiento bastara con presionar el <b>botón de eliminar</b> y confirmar la acción (img. 98.5). Cabe destacar que <b>la eliminación</b> también <b>esta restringida solo para administradores</b>.
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/101.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/101.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 99</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/100.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/100.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 100 (Vista de doctores)</p>
                        </div>
                    </div>


                </article>
            </div>
        </section>

        <!-- Trabajos de Laboratorio -->
        <section id="sec43" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Trabajos de Laboratorio</h2>

                    <p>
                        En esta sección se podrán ingresar los trabajos de laboratorio para el paciente.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/102.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/102.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 101</p>
                    </div>
                    <br>

                    <p>
                        Al ingresar presionando <b>detalles</b> (img. 101.1) veremos una lista con los datos de los pedidos hechos para el paciente por los doctores (img. 102.1).
                    </p>

                    <p>
                        También encontraremos un acceso rápido a los aranceles de los laboratorios (img. 102.3) en la página o también podremos acceder a ellos desde el menú superior de accesos rápidos (img. 102.4) que nos proporcionara más posibilidades de filtrado.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/103.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/103.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 102</p>
                    </div>

                    <h3><u><b>Agregar trabajo</b></u></h3>

                    <p>
                        Para agregar un nuevo trabajo de laboratorio presionaremos el <b>botón de agregar</b> (img. 102.2) y completaremos los datos.
                    </p>
                    <p>
                        Para saber el <b>precio del trabajo</b>, consultaremos el <b>arancel de los laboratorios disponibles</b> (img. 102.3), es importante destacar que este <b class="resaltado">valor será adjudicado como gasto para el doctor </b>y que
                        el mismo será deducido luego del costo general del tratamiento.
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/104.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/104.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 103</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/105.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/105.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 104</p>
                        </div>
                    </div>

                    <h3><u><b>Editar trabajo</b></u></h3>

                    <p>
                        Al presionar el <b>botón de edición</b> en un trabajo (img. 102.5), iremos al formulario correspondiente, donde podremos hacer las modificaciones necesarias y en donde destaca el <b>estado del trabajo</b> (img. 105.1) y la <b>calificación del mismo</b>                        (img. 105.2).
                    </p>

                    <p>
                        Cabe destacar que solo el doctor que creo el trabajo o un administrador pueden modificar al mismo.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/106.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/106.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 105</p>
                    </div>

                    <p>
                        Los estados del trabajo son:
                    </p>

                    <ul>
                        <li>
                            <b>Para enviar:</b>por defecto los trabajos son marcados con este estado.
                        </li>

                        <li>
                            <b>Enviado:</b>son aquellos que fueron enviados a los laboratorios, ya sea en primera instancia o sucesivas.
                        </li>

                        <li>
                            <b>Recibido:</b>son aquellos que vuelven del laboratorio
                        </li>

                        <li>
                            <b>Pagado:</b>en cualquiera de los estados anteriores el trabajo puede estar pagado o no y esto servirá para el calculo de los gastos del doctor.
                        </li>

                    </ul>

                    <p>Cada vez que el <b>estado del trabajo cambie</b>, se le informara al doctor de dicho cambio a través de una notificación.</p>

                    <p>
                        Las <b>calificaciones</b> serán las que darán a los administradores información sobre cuales son los <b>mejores laboratorios</b> según los doctores y las mismas son:
                    </p>

                    <ul>
                        <li>Malo</li>
                        <li>Regular </li>
                        <li>Bueno</li>
                        <li>Muy bueno</li>
                        <li>Excelente</li>
                    </ul>
                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/107.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/107.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 106 (vista admin en laboratorios)</p>
                    </div>

                    <h3><u><b>Eliminar trabajo</b></u></h3>

                    <p>
                        Para eliminar un trabajo solo tendremos que presionar el <b>botón de eliminar</b> (img. 107.1) y <b>confirmar</b> la acción (img. 107.2).
                    </p>
                    <p>
                        Como siempre, solo el doctor que creo el trabajo o un administrador podrá borrar el mismo.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/108.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/108.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 107</p>
                    </div>

                    <h3><u><b>Plantilla para impresión </b></u></h3>

                    <p>
                        Por último, contaremos con un <b>botón para generar un pdf</b> con los datos del pedido y que pueda ser impreso y adjuntado para el envió del trabajo.
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/109.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/109.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 108</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/110.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/110.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 109 (plantilla pdf)</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- Odontograma -->
        <section id="sec44" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Odontograma</h2>

                    <p>
                        Al ingresar en la sección de odontogramas (img. 110.1) encontraremos dos pestañas superiores en la que tendremos acceso al odontograma <b>para adulto</b> (img. 111.1) y <b>para niños</b> (img. 111.2).
                    </p>
                    <p>
                        En ellos encontraremos un primer botón para <b>crear el odontograma inicial</b> (img. 111.3).
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/111.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/111.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 110</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/112.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/112.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 111</p>
                        </div>
                    </div>
                    <p>
                        Una vez creado el inicial tendremos la posibilidad de <b>crear un segundo de control</b> para el paciente (img. 113).
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/113.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/113.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 112 (odontograma adultos)</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/114.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/114.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 113 (odontograma niños)</p>
                        </div>
                    </div>

                    <h3><u><b>Descripción</b></u></h3>

                    <p>
                        En el odontograma encontraremos en la parte superior tres botones:
                    </p>
                    <ul>
                        <li>
                            <b>Eliminar:</b> como su nombre indica con este botón podremos eliminar el odontograma (img. 114.1).
                        </li>
                        <li>
                            <b>Marcar todos como perdidos:</b> para evitar marcar uno por uno las piezas como perdidas, en caso de que tengamos un paciente desdentado total podremos usar esta opción (img. 114.2).
                        </li>
                        <li>
                            <b>Desmarcar todos perdidos:</b> hace lo contrario del anterior (img. 114.3).
                        </li>
                    </ul>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/115.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/115.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 114</p>
                    </div>

                    <p>
                        Luego encontraremos el <b>índice de cariados, perdido y obturados</b> (CPOD, img. 114.1) que se calcula por los estados fundamentales de caras que ya vimos.
                    </p>

                    <h3><u><b>Estado de caras dentales</b> (img. 114.5)</u></h3>

                    <p>
                        En cada pieza del odontograma podremos hacer <b>click en sus caras para modificar su estado</b> (img. 115.1) y encontraremos las opciones disponibles para ello (img. 115.2).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/116.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/116.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 115</p>
                    </div>

                    <h3><u><b>Estado de general de piezas</b>  (img. 114.6)</u></h3>
                    <p>
                        Si hacemos click sobre el <b>número de la pieza</b> (img. 116.1) podremos colocar una <b>descripción</b> (img. 116.2), cambiar el <b>estado general de la pieza</b> (presente, perdida, ausente img. 116.2) y la <b>movilidad</b> de
                        la misma (img. 116.1).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/117.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/117.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 116</p>
                    </div>

                    <h3><u><b>Imágenes de piezas</b> (img. 114.7)</u></h3>

                    <p>
                        Sobre cada número de pieza encontraremos un botón que nos mostrara las imágenes que hubiera para la misma (img. 117).
                    </p>
                    <p>
                        El icono del botón cambiara de color si la pieza tiene imágenes (img. 117.1) o no (img. 117.2).
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/119.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/119.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 117</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/118.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/118.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 118</p>
                        </div>

                    </div>

                    <h3><u><b>Edición de imágenes de piezas</b></u></h3>

                    <p>
                        Al hacer click en los <b>detalles de la pieza</b> (img. 118.1), iremos a la página de imágenes donde encontraremos una tabla y como siempre tendremos tres botones, <b>agregar</b> (img. 119.1), <b>editar</b> (img. 119.2) o <b>eliminar</b>                        una imagen (img. 119.3).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/120.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/120.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 119</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Periodontograma -->
        <section id="sec45" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section"></h2>

                <article class="color-article">
                    <h2>Periodontograma</h2>

                    <p>
                        En la sección de periodontogramas podremos crear todos lo que sean necesarios para un paciente.
                    </p>
                    <p>
                        La página nos presentara un botón superior para <b>crear uno nuevo</b> (img. 120.1) y sobre cada periodontograma tendremos un botón para <b>eliminar</b> el mismo (img. 120.2).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/121.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/121.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 120</p>
                    </div>

                    <p>
                        Si hacemos click sobre el <b>número de pieza</b> (img. 120.3) tendremos acceso a las opciones básicas de la misma, donde podremos agregar una <b>descripción</b> (img. 121.1), cambiar el <b>estado de la piza</b> (img. 121.2) y marcar
                        si la misma <b>tiene furca o no</b> (img. 121.3).
                    </p>
                    <p>
                        Luego sobre cada <b>cara de la pieza</b>(líneas grises img. 120.4) podremos hacer click para indicar el número de medición que hiciera el doctor, el cual iría de 0 a 12 mm (img. 122).
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/122.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/122.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 121</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/123.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/123.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 122</p>
                        </div>
                    </div>
                    <br>
                    <p>En cuanto a <b>los colores</b> de las caras (img. 123) se guían según la bibliografía con los siguientes niveles:</p>

                    <ul>
                        <li>
                            <b>0-3 mm:</b> Normal <b>(negro)</b>
                        </li>
                        <li>
                            <b>4-6 mm:</b> Bolsa periodontal <span class="txt-naranja">(Naranja)</span>
                        </li>
                        <li>
                            <b>+6 mm:</b> Bolsa patológica <span class="txt-rojo">(Rojo)</span>
                        </li>
                    </ul>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/124.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/124.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 123</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Índice  de inflamación -->
        <section id="sec46" class="bg-gris p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Índice de inflamación</h2>

                    <p>
                        En esta sección, al igual que con los periodontogramas, tendremos la posibilidad de <b>crear todos los que necesitemos</b>.
                    </p>
                    <p>
                        En la parte superior encontraremos el botón para <b>crear nuevos</b> (img. 124.1) y <b>sobre cada índice</b> encontraremos un <b>botón para eliminar</b> el mismo (img. 124.2) y el <b>índice de sangrado gingival (ISG)</b> (img.
                        124.3) calculado automáticamente según la bibliografía sobre lo que se ingrese en la planilla.
                    </p>

                    <p>
                        En el índice podremos hacer click sobre el <b>número de las piezas</b> (img. 124.4) para agregar una descripción o modificar el estado de la misma.
                    </p>
                    <p>
                        Si hacemos <b>click sobre las caras</b> de las piezas podremos modificar los valores que serán los siguientes:
                    </p>

                    <ul>
                        <li>
                            <b>0: </b>Ausencia de inflamación.
                        </li>
                        <li>
                            <b>1: </b>inflamación leve (leve cambio de color y textura)
                        </li>
                        <li>
                            <b>2: </b>inflamación moderada (brillo moderado, enrojecimiento, edema e hipertrofia, sangrado al sondaje tras espera de 10 seg.).
                        </li>
                        <li>
                            <b>3: </b>inflamación severa (tendencia al sangrado espontaneo, ulceración).
                        </li>
                    </ul>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/125.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/125.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 124</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Índice de Higiene Oral Simplificado (I.H.O.S.) -->
        <section id="sec47" class="bg-blanco p-3">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Índice de Higiene Oral Simplificado (I.H.O.S.)</h2>

                    <p>
                        En esta sección encontraremos un botón superior para <b>crear nuevos índices</b> (img. 125.1) y debajo un <b>gráfico con la evolución del paciente</b> (img. 125.2), lo cual facilitara la visualización del progreso del mismo en
                        sus hábitos de higiene oral.
                    </p>

                    <p>
                        En los cuadros de índice tendremos un botón para <b>editar</b> (img. 125.3) y otro para <b>eliminar</b> (img. 125.4).
                    </p>
                    <p>
                        Al <b>cambiar los valores</b> (img. 125.5) en el formulario <b>presionaremos editar</b> (img. 125.3) para persistir los cambios.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/126.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/126.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 125</p>
                    </div>
                </article>
            </div>
        </section>

        <!-- Imágenes -->
        <section id="sec48" class="bg-gris p-3">
            <div class="container padding-sec">

                <h2 class="h2-section"></h2>

                <article class="color-article">
                    <h2>Imágenes del paciente</h2>

                    <p>
                        En esta sección encontraremos <b>todas las imágenes del paciente</b>, para todas las piezas, a modo de galería con la posibilidad de <b>agregar</b> (en caso de no pertenecer a una pieza en particular img. 126.1), <b>editar</b>                        (img. 126.2) o <b>eliminar</b> (img. 126.3) las mismas de manera sencilla.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/127.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/127.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 126</p>
                    </div>
                </article>
            </div>
        </section>

        <!-- Consultas -->
        <section id="sec49" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section"></h2>

                <article class="color-article">
                    <h2>Consultas</h2>

                    <p>
                        En la página de <b>historias clínicas</b>, al final de la misma encontraremos la <b>sección de consultas</b> la cual tendrá una tabla con todas las consultas del paciente.
                    </p>
                    <p>
                        <b>Cada consulta</b> será el punto inicial de los tratamientos y <b>contendrá un diagnóstico, un pronóstico y un plan de tratamiento</b> que permitirá el agregado de sesiones clínicas y trabajos de laboratorio para la misma.
                    </p>
                    <p>
                        En la tabla de consultas tendremos los siguientes botones:
                    </p>
                    <ul>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec50')"><b>Crear Consulta:</b></button> (img. 127.1)
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec54')"><b>Editar consulta:</b></button> aquí podremos cambiar los datos básicos de la misma (img. 127.5).
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec55')"><b>Eliminar consulta: </b></button> Este botón estará disponible solo para administradores (img. 127.6).
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec51')"><b>Cantidad de tratamientos:</b></button> nos mostrara el número de tratamientos del plan creado para esta consulta (img. 127.2).
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec52')"><b>Consentimiento informado PDF:</b></button> Aquí tendremos una plantilla para la impresión del consentimiento del paciente (img. 127.3).
                        </li>
                        <li>
                            <button class="link-pagina sin-borde" (click)="scrollTo('#sec53')"><b>Detalles de consulta:</b></button> aquí es donde ingresaremos un diagnóstico, pronostico y plan de tratamiento (img. 127.4).
                        </li>

                    </ul>
                    <p>
                        A continuación, se describirán en profundidad cada uno de ellos.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/128.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/128.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 127</p>
                    </div>

                    <!-- crear consulta -->
                    <div id="sec50">
                        <h3><u><b>Crear consulta</b></u></h3>
                        <p>Para crear una consulta simplemente tendremos que darle al botón correspondiente (img. 128.1) y confirmar la acción (img. 128.2). En este formulario <b>no hay datos requeridos</b>.</p>

                    </div>

                    <!-- Editar consulta -->
                    <div id="sec51">
                        <h3><u><b>Editar consulta</b></u></h3>

                        <p>Para editar los datos básicos de una consulta solo debemos seleccionar el botón correspondiente (img. 127.5) y realizar los cambios (img. 129). </p>


                    </div>
                    <!-- Eliminar consulta -->
                    <div id="sec52">
                        <h3><u><b>Eliminar consulta</b></u></h3>

                        <p>
                            La eliminación de las consultas solo las podrá hacer un administrador y para ello simplemente deberá presionar el botón de eliminar (img. 127.6) y confirmar la acción (img. 130.2).
                        </p>
                    </div>

                    <div class="row justify-content-center">
                        <div class="div-imagenes col-md-4">
                            <a href="assets/images/img-odonto/129.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/129.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 128</p>
                        </div>

                        <div class="div-imagenes col-md-4">
                            <a href="assets/images/img-odonto/139.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/139.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 129</p>
                        </div>
                        <div class="div-imagenes col-md-4">
                            <a href="assets/images/img-odonto/140.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/140.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 130</p>
                        </div>
                    </div>

                    <!-- Cantidad de tratamientos -->
                    <div id="sec53">
                        <h3><u><b>Cantidad de tratamientos</b></u></h3>

                        <p>
                            Este botón es un <b>acceso rápido a la lista de edición de los tratamientos</b> del plan de tratamiento (img. 131.1).
                        </p>
                        <p>
                            Cabe destacar que <b>si no tenemos un plan de tratamiento</b> creado este botón <b>arrojara error</b> (img. 131.2).
                        </p>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/130.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/130.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 131</p>
                        </div>
                    </div>

                    <!-- Consentimiento informado PDF -->
                    <div id="sec54">
                        <h3><u><b>Consentimiento informado PDF</b></u></h3>

                        <p>
                            El botón de consentimiento informado (img. 127.3) nos llevara a una plantilla para el mismo.
                        </p>

                        <p>
                            En la parte superior de la misma encontraremos un botón para crear el <b>pdf de impresión</b> (img. 132.1) y debajo una casilla para marcar si el <b>paciente es menor de edad o especial</b> (img. 132.2), lo cual habilitara
                            los campos de representante legal (tutor) y cedula del mismo para la firma del consentimiento (img. 132.3).
                        </p>

                        <p>
                            Debajo encontraremos los <b>tratamientos agregados</b> al plan de tratamiento con el detalle de <b>si alguno fue rechazado</b> por el paciente (img. 132.4) y el <b>importe</b> de cada uno.
                        </p>

                        <div class="row justify-content-center">
                            <div class="div-imagenes">
                                <a href="assets/images/img-odonto/131.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/131.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 132</p>
                            </div>

                            <div class="div-imagenes">
                                <a href="assets/images/img-odonto/132.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/132.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 133 (pdf creado)</p>
                            </div>
                        </div>


                    </div>

                    <!-- Detalles de consulta -->
                    <div id="sec55">
                        <h3><u><b>Detalles de consulta</b></u></h3>

                        <p>
                            Al ingresar en los <b>detalles de la consulta</b> (img. 127.4) veremos un <b>total del presupuesto</b> (img. 134.1), un botón para <b>exportar a pdf</b> (img. 134.2 y img. 135) y debajo los formularios para crear el <b>diagnóstico</b>                            (img. 134.3), <b>pronóstico</b> (img. 134.4) y <b>plan de tratamiento</b> (img. 134.5).
                        </p>

                        <div class="row justify-content-center">
                            <div class="div-imagenes">
                                <a href="assets/images/img-odonto/133.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/133.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 134</p>
                            </div>

                            <div class="div-imagenes">
                                <a href="assets/images/img-odonto/134.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/134.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 135 (pdf presupuesto)</p>
                            </div>
                        </div>
                        <br>
                        <h4><u><b>Plan de tratamiento</b></u></h4>
                        <p>
                            El plan de tratamiento es el más importante, ya que es quien nos dará el <b>valor a pagar por el paciente</b> y es el encargado de <b>permitir el agregado de sesiones clínicas y trabajos de laboratorio</b> .
                        </p>

                        <p>
                            Es <b>importante que se coloque una descripción</b> (img. 136.1) para el plan de tratamiento ya que esto <b>facilitara la identificación del mismo</b> a la hora de agregar las sesiones clínicas (img. 137) y trabajos de laboratorios
                            (img. 138) al plan.
                        </p>

                        <p>
                            Una vez creado el plan de tratamiento tendremos <b>3 botones</b>:
                        </p>

                        <ul>
                            <li>
                                <b>Detalles:</b> (img. 136.2) que nos llevará a la página donde agregaremos los tratamientos.
                            </li>
                            <li>
                                <b>Edición:</b> (img. 136.3) que nos permitirá cambiar la descripción del mismo.
                            </li>
                            <li>
                                <b>Eliminación:</b> (img. 136.4) el cual estar disponible solo para administradores.
                            </li>
                        </ul>

                        <div class="row justify-content-center">
                            <div class="div-imagenes col-md-4">
                                <a href="assets/images/img-odonto/135.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/135.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 136</p>
                            </div>

                            <div class="div-imagenes col-md-4">
                                <a href="assets/images/img-odonto/136.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/136.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 137 (agregado en sección de sesiones clinicas)</p>
                            </div>
                            <div class="div-imagenes col-md-4">
                                <a href="assets/images/img-odonto/137.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/137.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 138 (agregado en sección de trabajos de laboratorio)</p>
                            </div>
                        </div>
                        <br>


                        <b><u>Detalles del plan de tratamiento</u></b>

                        <p>
                            En la página de detalles del plan de tratamiento veremos dos tablas.
                        </p>

                        <p>
                            Una que contendrá los <b>tratamientos agregados</b> para el paciente (img. 139.1) y otra con los <b>tratamientos disponibles en el arancel</b> de la clínica (img. 139.2).
                        </p>

                        <p>
                            <b>Al agregar un tratamiento</b> para el paciente <b>podremos incluir una descripción</b> al mismo (img. 139.3) y en caso de ser <b>un administrador</b> se <b>podrá especificar un precio preferencial</b> para el tratamiento
                            (img. 139.4).
                        </p>

                        <p>
                            Al presionar en <b>agregar</b> (img. 139.5) se pasará el seleccionado a la lista de tratamientos del paciente en la que tendremos la posibilidad de <b>editar</b> (img. 139.6) o <b>eliminar</b> el mismo (img. 139.7).
                        </p>

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/138.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/138.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 139</p>
                        </div>

                    </div>

                </article>
            </div>
        </section>

        <!-- Especialidades -->
        <section id="sec56" class="bg-gris p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Especialidades</h2>

                <article class="color-article">

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/146.jpg" data-lightbox="photos" class="img-container p-3">
                            <img style="height: 200px;" class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/146.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 140</p>
                    </div>

                    <p>
                        En esta sección los administradores podrán crear las especialidades con las que cuente su clínica para luego poder asignarle a cada doctor las mismas (como se vio en la<button class="link-pagina sin-borde" (click)="scrollTo('#secEditDoc')">edición del doctor</button>).
                    </p>

                    <p>
                        Esto será de utilidad para que los doctores puedan identificar de manera rápida a los especialistas de cada área y hacer las respectivas interconsultas de ser necesario .
                    </p>

                    <p>
                        En esta página tendremos los siguientes botones:
                    </p>

                    <ul>
                        <li>
                            <b>Agregar especialidad</b> (img. 141.1)
                        </li>
                        <li>
                            <b>Editar especialidad</b> (img. 141.3)
                        </li>
                        <li>
                            <b>Eliminar especialidad</b> (img. 141.4)
                        </li>
                    </ul>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/141.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/141.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 141</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-odonto/142.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/142.jpg" alt="SURSOFT -  Odonto">
                            </a>
                            <p>Imagen 142 (vista de doctor)</p>
                        </div>
                    </div>

                    <br>

                    <p>
                        Estos 3 botones son exclusivos para administradores
                    </p>

                    <p>
                        Luego tendremos el botón de <b>detalles</b> (img. 141.2) en el cual se presentarán cada uno de los doctores que posean esa especialidad y sus horarios de trabajo en la clínica.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/143.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/143.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 143</p>
                    </div>

                </article>
            </div>
        </section>


        <!-- Arancel de clínica -->
        <section id="sec57" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Arancel de clínica</h2>

                <article class="color-article">

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/147.jpg" data-lightbox="photos" class="img-container p-3">
                            <img style="height: 200px;" class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/147.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 144</p>
                    </div>

                    <p>
                        En esta sección los <b>administradores  podrán crear las categorías y los tratamientos</b> a de las mismas por parte de los administradores.
                    </p>
                    <p>
                        Para ello tendremos en la parte superior un <b>botón para crear una categoría</b> (img. 145.1) en la que deberemos proporcionar un nombre para la misma.
                    </p>
                    <p>
                        Luego se nos presentara la lista para esa categoría en donde encontraremos en el cabezal 3 botones:
                    </p>

                    <ul>
                        <li>
                            <b>Crear tratamiento</b> (img. 145.2)
                        </li>
                        <li>
                            <b>Editar categoría</b> (img. 145.3)
                        </li>
                        <li>
                            <b>Eliminar categoría</b> (img. 145.4)
                        </li>
                    </ul>

                    <p>
                        Por último, <b>en cada línea de</b> la tabla correspondiente a un <b>tratamiento</b> tendremos dos botones mas para <b>editar</b> (img. 145.5) y <b>eliminar</b> el tratamiento (img. 145.6) .
                    </p>


                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/144.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/144.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 145</p>
                    </div>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/145.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/145.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 146 (vista de doctor)</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Aranceles de laboratorios -->
        <section id="sec58" class="bg-gris p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Aranceles de laboratorios</h2>

                <article class="color-article">

                    <p>
                        En esta sección tendremos una tabla con todos los trabajos que realizan los laboratorios (img. 147.1) y un botón de acceso rápido para acceder a la vista del laboratorio especifico (img. 147.2) si fuera necesario.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/149.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/149.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 147</p>
                    </div>

                </article>
            </div>
        </section>

        <!-- Correo interno -->
        <section id="sec59" class="bg-blanco p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Correo interno</h2>

                <article class="color-article">

                    <p>
                        El correo interno de la aplicación es accesible desde el menú superior. Si tenemos nuevos mensajes los mismos serán marcados en el icono correspondiente.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/150.jpg" data-lightbox="photos" class="img-container p-3">
                            <img style="height: 200px;" class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/150.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 148</p>
                    </div>
                    <br>
                    <p>
                        Al ingresar a la sección de correo veremos tres botones superiores:
                    </p>

                    <ol>
                        <li>
                            <b>Nuevo correo</b>: Aquí podremos enviar correo a los demás doctores ya sea de manera individual o general.

                            <div class="div-imagenes">
                                <a href="assets/images/img-odonto/151.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/151.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 149</p>
                            </div>
                        </li>
                        <li>
                            <b>Recibidos</b>: Esta será la bandeja de entrada donde tendremos todos los correos que nos envían. Al hacer <b>click sobre un mensaje</b> (img. 150.1) se desplegará su contenido en una ventana modal (img. 151).

                            <div class="div-imagenes">
                                <a href="assets/images/img-odonto/152.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/152.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 150</p>
                            </div>

                            <div class="div-imagenes">
                                <a href="assets/images/img-odonto/154.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/154.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 151</p>
                            </div>
                        </li>
                        <li>
                            <b>Enviados</b>: Esta sección es similar a la de recibidos solo que aquí tendremos los correos enviados.


                            <div class="div-imagenes">
                                <a href="assets/images/img-odonto/153.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/153.jpg" alt="SURSOFT -  Odonto">
                                </a>
                                <p>Imagen 152</p>
                            </div>
                        </li>
                    </ol>

                </article>
            </div>
        </section>

        <!-- Notificaciones -->
        <section id="sec60" class="bg-gris p-3">
            <div class="container padding-sec">

                <h2 class="h2-section">Notificaciones</h2>

                <article class="color-article">

                    <p>
                        Las notificaciones las podremos encontrar en el <b>menú superior</b> (img.153.1) en donde podremos hacer click y ver un <b>detalle de las mismas</b> (img.153.2), al final del desplegable encontraremos un botón de <b>ver todas</b>                        (img.153.3) el cual no redirigirá a la pagina donde veremos todas las que tuvimos
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/155.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/155.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 153</p>
                    </div>

                    <p>
                        En la página de detalles de las notificaciones tendremos dos botones superiores, uno para <b>marcarlas todas como leídas</b> (img. 154.1) y otro para <b>eliminarlas todas</b> (img. 154.2).
                    </p>
                    <p>
                        Luego tendremos una tabla con todas las notificaciones con la posibilidad de <b>marcar cada una como leída o no</b> (img. 154.3), <b>eliminarlas</b> (img. 154.4) o ver sus <b>detalles</b> (img. 154.5).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-odonto/156.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-odonto/156.jpg" alt="SURSOFT -  Odonto">
                        </a>
                        <p>Imagen 154</p>
                    </div>

                </article>
            </div>
        </section>

    </mat-drawer-content>
</mat-drawer-container>