<!--Tab de opciones-->
<div id="divCont" class="p-0 ">
    <div class="d-md-flex m-0">

        <!-- Opciones del tab-->
        <div id="div0" class="col-xl-2 col-md-3 col-12 px-0 ">
            <ul class="nav nav-tab md-pills  flex-md-column" role="tablist">
                <!--diseño-->
                <li class="nav-item col-md-12 col-4 p-0">
                    <a (click)="scrollTop()" class="nav-link {{isDisenioActivo}} d-md-flex align-items-center" data-toggle="tab" href="#panel1" role="tab">
                        <i class="fas fa-pen-nib mr-2 fa-2x"></i>
                        <span class="d-md-block d-none">Diseño</span>
                    </a>
                </li>
                <!--web-->
                <li class="nav-item col-md-12 col-4 p-0">
                    <a (click)="scrollTop()" class="nav-link {{isWebActivo}} d-md-flex align-items-center" data-toggle="tab" href="#panel2" role="tab">
                        <i class="fas fa-globe mr-2 fa-2x"></i>
                        <span class="d-md-block d-none">Desarrollo web</span>
                    </a>
                </li>
                <!--personalizadas-->
                <li class="nav-item col-md-12 col-4 p-0">
                    <a id="per" (click)="scrollTop()" class="nav-link {{isPersonalizadasActivo}} d-md-flex align-items-center " data-toggle="tab" href="#panel3" role="tab">
                        <i class="fas fa-laptop-code mr-2 fa-2x"></i>

                        <span class="d-md-block d-none text-wrap">Soluciones personalizadas</span>
                    </a>
                </li>

            </ul>
        </div>

        <!-- contenido de los tab-->
        <div id="div1" class="col-xl-10 col-md-9 col-12 tab-container scrollbar-hidden">

            <!-- Tab panels -->
            <div id="tabPanel" class="tab-content vertical">

                <!-- Panel 1 diseños-->
                <div class="tab-pane fade {{isDisenioActivo}}" id="panel1" role="tabpanel">

                    <!--titulo del panel-->
                    <div class="titulo-container">
                        <div class="titulo-mask bg-mask-disenio">
                            <div class="titulo-bg">
                                <span class="h1-titulo">Diseños Gráficos</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <mat-tab-group mat-align-tabs="center">
                            <!--todos-->
                            <mat-tab label="Todos" class="tab-container-disenio">
                                <div class="header-description bg-white">Estos son algunos de los muchos trabajos realizados</div>
                                <div class="row justify-content-center p-3 col-12 bg-white">
                                    <a *ngFor="let img of imagenesTodas" href="{{img.src}}" data-lightbox="photos" class="hoverable  img-disenios-container">
                                        <img class="img-disenios " src="{{img.src}}" alt="SURSOFT - Todos los diseños">
                                    </a>
                                </div>
                            </mat-tab>

                            <!--Modelos 3D-->
                            <mat-tab label="Modelos 3D" class="tab-container-disenio">
                                <div class="header-description bg-white">Estas son mis galerias en sketfab</div>
                                <div class="bg-white row justify-content-center col-12">

                                    <!--galeria miscelaneos-->
                                    <div class="frame3d-container">
                                        <iframe class="img-disenios-container frame3d-width z-depth-1" src="https://sketchfab.com/playlists/embed?collection=564a4b04ed264706ae78e6b759d1f417&autostart=0" frameborder="0" allow="autoplay; fullscreen; vr" allowvr="" allowfullscreen="" mozallowfullscreen="true"
                                            webkitallowfullscreen="true" onmousewheel="">
                                        </iframe>
                                        <p class="frame3d-description">
                                            Galeria de
                                            <a href="https://sketchfab.com/sebaualde/collections/miscellaneous" target="_blank" class="frame3d-link">Miscelaneos</a>por<a href="https://sketchfab.com/sebaualde" target="_blank" class="frame3d-link"> S.Ualde</a>
                                        </p>
                                    </div>

                                    <!--galeria esculturas-->
                                    <div class="frame3d-container">
                                        <iframe class="img-disenios-container frame3d-width z-depth-1" src="https://sketchfab.com/playlists/embed?collection=127de1195e3b473abf2e497c943b7623&autostart=0" frameborder="0" allow="autoplay; fullscreen; vr" allowvr="" allowfullscreen="" mozallowfullscreen="true"
                                            webkitallowfullscreen="true" onmousewheel="">
                                        </iframe>
                                        <p class="frame3d-description">
                                            Galeria de
                                            <a href="https://sketchfab.com/sebaualde/collections/esculturas" target="_blank" class="frame3d-link">Esculturas J.C. Ualde</a>por<a href="https://sketchfab.com/sebaualde" target="_blank" class="frame3d-link"> S.Ualde</a>
                                        </p>
                                    </div>

                                    <!--galeria anatomia-->
                                    <div class="frame3d-container">
                                        <iframe class="img-disenios-container frame3d-width z-depth-1" src="https://sketchfab.com/playlists/embed?collection=827dd5a931ea4c27992980a86daf76da&autostart=0" frameborder="0" allow="autoplay; fullscreen; vr" allowvr="" allowfullscreen="" mozallowfullscreen="true"
                                            webkitallowfullscreen="true" onmousewheel="">
                                    </iframe>
                                        <p class="frame3d-description">
                                            Galeria de
                                            <a href="https://sketchfab.com/sebaualde/collections/anatomy" target="_blank" class="frame3d-link">Anatomía</a>por<a href="https://sketchfab.com/sebaualde" target="_blank" class="frame3d-link"> S.Ualde</a>
                                        </p>
                                    </div>


                                </div>

                            </mat-tab>

                            <!--Banners-->
                            <mat-tab label="Banners" class="tab-container-disenio">
                                <div class="tab-container-disenio">
                                    <div class="header-description bg-white">Estos son algunos de los banners realizados</div>
                                    <div class="tab-container-disenio">
                                        <a *ngFor="let img of imagenesDeBanners" href="{{img.src}}" data-lightbox="photos" class="img-disenios-container hoverable">
                                            <img class="img-disenios" src="{{img.src}}" alt="SURSOFT - Diseños de Banners">
                                        </a>
                                    </div>
                                </div>
                            </mat-tab>

                            <!--Tarjetas-->
                            <mat-tab label="Tarjetas" class="tab-container-disenio">
                                <div class="tab-container-disenio">
                                    <div class="header-description bg-white">Estos son algunos de las tarjetas realizadas</div>
                                    <div class="tab-container-disenio">
                                        <a *ngFor="let img of imagenesDeTarjetas" href="{{img.src}}" data-lightbox="photos" class="hoverable img-disenios-container">
                                            <img class="img-disenios" src="{{img.src}}" alt="SURSOFT - Diseños de Tarjetas">
                                        </a>
                                    </div>
                                </div>
                            </mat-tab>

                            <!--Restauraciones-->
                            <mat-tab label="Restauraciones" class="tab-container-disenio">
                                <div class="tab-container-disenio">
                                    <div class="header-description bg-white">Estos son algunas de las fotos restauradas</div>
                                    <div class="tab-container-disenio">
                                        <a *ngFor="let img of imagenesDeRestauraciones" href="{{img.src}}" data-lightbox="photos" class="hoverable img-disenios-container">
                                            <img class="img-disenios" src="{{img.src}}" alt="SURSOFT - Fotos Restauradas">
                                        </a>
                                    </div>
                                </div>
                            </mat-tab>

                            <!--Logos-->
                            <mat-tab label="Logos" class="tab-container-disenio">
                                <div class="tab-container-disenio">
                                    <div class="header-description bg-white">Estos son algunos de los logos realizados</div>
                                    <div class="tab-container-disenio">
                                        <a *ngFor="let img of imagenesDeLogos" href="{{img.src}}" data-lightbox="photos" class="hoverable img-disenios-container">
                                            <img class="img-disenios" src="{{img.src}}" alt="SURSOFT - Fotos Restauradas">
                                        </a>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>

                    </div>

                </div>

                <!-- Panel 2 webs templates -->
                <div class="tab-pane fade {{isWebActivo}}" id="panel2" role="tabpanel">
                    <!--Contenido-->
                    <div>
                        <!--titulo del panel-->
                        <div class="titulo-container">
                            <div class="titulo-mask bg-mask-webs">
                                <div class="titulo-bg">
                                    <span class="h1-titulo">Desarrollos Web</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <p class="header-description container">
                            A continuación, encontraras algunas plantillas de paginas realizadas en Angular 11 y Razor Pages en las cuales he trabajado.
                        </p>

                        <!--cards-->
                        <div class="panel2-container pb-4">

                            <!--card 1-->
                            <div class="col-md-4 col-sm-6 panel2-card-container">
                                <div class="card hoverable h-100">

                                    <!-- Card image -->
                                    <img class="card-img-top" src="../../assets/images/templates/img1.jpg" alt="Card image cap">

                                    <!-- Card content -->
                                    <div class="card-body">

                                        <!-- Title -->
                                        <h2 class="header-description text-center p-0">Template 1</h2>
                                    </div>
                                    <!-- Card footer -->
                                    <div class="card-footer text-muted text-center">
                                        <div class="d-flex justify-content-end">
                                            <a href="http://templates.sursoft.org/template1" target="blank" mat-flat-button class="link waves-effect">
                                                <i class="fas fa-link mr-2"></i>Ver Demo
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!--card 2-->
                            <div class="col-md-4 col-sm-6 panel2-card-container">
                                <div class="card hoverable h-100">

                                    <!-- Card image -->
                                    <img class="card-img-top" src="../../assets/images/templates/img2.jpg" alt="Card image cap">

                                    <!-- Card content -->
                                    <div class="card-body">

                                        <!-- Title -->
                                        <h2 class="header-description text-center p-0">Template 2</h2>
                                    </div>
                                    <!-- Card footer -->
                                    <div class="card-footer text-muted text-center">
                                        <div class="d-flex justify-content-end">
                                            <a href="http://templates.sursoft.org/template2" mat-flat-button target="blank" class="link waves-effect">
                                                <i class="fas fa-link mr-2"></i>Ver Demo
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!--card 3-->
                            <div class="col-md-4 col-sm-6 panel2-card-container">
                                <div class="card hoverable h-100">

                                    <!-- Card image -->
                                    <img class="card-img-top" src="../../assets/images/templates/img3.jpg" alt="Card image cap">

                                    <!-- Card content -->
                                    <div class="card-body">

                                        <!-- Title -->
                                        <h2 class="header-description text-center p-0">Template 3</h2>

                                    </div>
                                    <!-- Card footer -->
                                    <div class="card-footer text-muted text-center">
                                        <div class="d-flex justify-content-end">
                                            <a href="http://templates.sursoft.org/template3" mat-flat-button target="blank" class="link waves-effect">
                                                <i class="fas fa-link mr-2"></i>Ver Demo
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--card 4-->
                            <div class="col-md-4 col-sm-6 panel2-card-container">
                                <div class="card hoverable h-100">

                                    <!-- Card image -->
                                    <img class="card-img-top" src="../../assets/images/templates/img4.jpg" alt="Card image cap">

                                    <!-- Card content -->
                                    <div class="card-body">

                                        <!-- Title -->
                                        <h2 class="header-description text-center p-0">Template 4</h2>
                                    </div>
                                    <!-- Card footer -->
                                    <div class="card-footer text-muted text-center">
                                        <div class="d-flex justify-content-end">
                                            <a href="http://templates.sursoft.org/template4" mat-flat-button target="blank" class="link waves-effect">
                                                <i class="fas fa-link mr-2"></i>Ver Demo
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--card 5-->
                            <div class="col-md-4 col-sm-6 panel2-card-container">
                                <div class="card hoverable h-100">

                                    <!-- Card image -->
                                    <img class="card-img-top" src="../../assets/images/templates/img5.jpg" alt="Card image cap">

                                    <!-- Card content -->
                                    <div class="card-body">
                                        <!-- Title -->
                                        <h2 class="header-description text-center p-0">Template 5</h2>
                                    </div>

                                    <!-- Card footer -->
                                    <div class="card-footer text-muted text-center">
                                        <div class="d-flex justify-content-end">
                                            <a href="http://templates.sursoft.org/template5" mat-flat-button target="blank" class="link waves-effect">
                                                <i class="fas fa-link mr-2"></i>Ver Demo
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!--card 6-->
                            <div class="col-md-4 col-sm-6 panel2-card-container">
                                <div class="card hoverable h-100">

                                    <!-- Card image -->
                                    <img class="card-img-top" src="../../assets/images/templates/img6.jpg" alt="Card image cap">

                                    <!-- Card content -->
                                    <div class="card-body">

                                        <!-- Title -->
                                        <h2 class="header-description text-center p-0">Template 6</h2>

                                    </div>
                                    <!-- Card footer -->
                                    <div class="card-footer text-muted text-center">
                                        <div class="d-flex justify-content-end">
                                            <a href="http://domos-argentina-demo.sursoft.org/" mat-flat-button target="blank" class="link waves-effect">
                                                <i class="fas fa-link mr-2"></i>Ver Demo
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--card 7-->
                            <div class="col-md-4 col-sm-6 panel2-card-container">
                                <div class="card hoverable h-100">

                                    <!-- Card image -->
                                    <img class="card-img-top" src="../../assets/images/templates/img7.jpg" alt="Card image cap">

                                    <!-- Card content -->
                                    <div class="card-body">

                                        <!-- Title -->
                                        <h2 class="header-description text-center p-0">Template 7</h2>

                                    </div>
                                    <!-- Card footer -->
                                    <div class="card-footer text-muted text-center">
                                        <div class="d-flex justify-content-end">
                                            <a href="http://www.madrigueralibros.sursoft.org" mat-flat-button target="blank" class="link waves-effect">
                                                <i class="fas fa-link mr-2"></i>Ver Demo
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--card 8-->
                            <div class="col-md-4 col-sm-6 panel2-card-container">
                                <div class="card hoverable h-100">

                                    <!-- Card image -->
                                    <img class="card-img-top" src="../../assets/images/templates/img8.jpg" alt="Card image cap">

                                    <!-- Card content -->
                                    <div class="card-body">

                                        <!-- Title -->
                                        <h2 class="header-description text-center p-0">Template 8</h2>

                                    </div>
                                    <!-- Card footer -->
                                    <div class="card-footer text-muted text-center">
                                        <div class="d-flex justify-content-end">
                                            <a href="http://dentalnet.sursoft.org/" mat-flat-button target="blank" class="link waves-effect">
                                                <i class="fas fa-link mr-2"></i>Ver Demo
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--card 9-->
                            <div class="col-md-4 col-sm-6 panel2-card-container">
                                <div class="card hoverable h-100">

                                    <!-- Card image -->
                                    <img class="card-img-top" src="../../assets/images/templates/img9.jpg" alt="Card image cap">

                                    <!-- Card content -->
                                    <div class="card-body">

                                        <!-- Title -->
                                        <h2 class="header-description text-center p-0">Template 9</h2>

                                    </div>
                                    <!-- Card footer -->
                                    <div class="card-footer text-muted text-center">
                                        <div class="d-flex justify-content-end">
                                            <a href="http://lunchboxdemo.sursoft.org/" mat-flat-button target="blank" class="link waves-effect">
                                                <i class="fas fa-link mr-2"></i>Ver Demo
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--card 10-->
                            <div class="col-md-4 col-sm-6 panel2-card-container">
                                <div class="card hoverable h-100">

                                    <!-- Card image -->
                                    <img class="card-img-top" src="../../assets/images/jcuweb.jpg" alt="Card image cap">

                                    <!-- Card content -->
                                    <div class="card-body">

                                        <!-- Title -->
                                        <h2 class="header-description text-center p-0">Template 10</h2>

                                    </div>
                                    <!-- Card footer -->
                                    <div class="card-footer text-muted text-center">
                                        <div class="d-flex justify-content-end">
                                            <a href="https://juancarlosualde.com/" mat-flat-button target="blank" class="link waves-effect">
                                                <i class="fas fa-link mr-2"></i>Ver página
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <!-- Panel 3 soluciones personalizadas-->
                <div class="tab-pane fade {{isPersonalizadasActivo}}" id="panel3" role="tabpanel">

                    <!--titulo del panel-->
                    <div class="titulo-container">
                        <div class="titulo-mask bg-mask-soluciones">
                            <div class="titulo-bg">
                                <span class="h1-titulo">Soluciones personalizadas</span>
                            </div>
                        </div>
                    </div>

                    <!--navbar -->
                    <div class="classic-tabs">

                        <ul class="nav list-tab-personalizado" role="tablist">

                            <li class="nav-item">
                                <a class="nav-link  waves-light active show" id="odontoGo" (click)="scrollTo('#odonto')" data-toggle="tab">Poryecto Odonto Integral</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link waves-light" id="madrigueraGo" (click)="scrollTo('#madriguera')" data-toggle="tab">Proyecto Madriguera Libros</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link waves-light" id="lunchGo" (click)="scrollTo('#lunch')" data-toggle="tab">Proyecto Lunch Box</a>
                            </li>
                        </ul>

                    </div>

                    <!--cabezal-->
                    <div class="container header-personalizadas">

                        <!--descripcion-->
                        <p class="header-description pt-0">
                            En esta sección conocerás más sobre los proyectos que realice, ajustados a los requerimientos de clientes, cubriendo sus necesidades y logrando aplicaciones funcionales para mejorar la organización de sus empresas.
                        </p>


                        <!--botones de seleccion-->
                        <div class="d-sm-flex d-none justify-content-center ">

                            <!--odonto integral-->
                            <a id="odontoGo" (click)="scrollTo('#odonto')" class="animated  fadeInLeft waves-effect hoverable btn-selector-section mr-3">

                                <img src="assets/images/odontoLogo.svg" alt="SURSOFT - Proyecto Odonto Integral" class="img-btn-selectors ">
                            </a>

                            <!--madriguera libros-->
                            <a id="madrigueraGo" (click)="scrollTo('#madriguera')" class="animated  fadeInLeft waves-effect hoverable btn-selector-section mr-3">

                                <img src="assets/images/logoMadriguera.svg" alt="SURSOFT - Proyecto Madriguera Libros" class="img-btn-selectors">
                            </a>

                            <!--Lunch Box-->
                            <a id="lunchGo" (click)="scrollTo('#lunch')" class="animated  fadeInLeft waves-effect hoverable btn-selector-section mr-3">

                                <img src="assets/images/logoLunch.svg" alt="SURSOFT - Proyecto Lunch Box" class="img-btn-selectors ">
                            </a>

                        </div>


                    </div>

                    <!--Odonto Integral contenido-->
                    <section id="odonto" class="bg-Gris p-3">
                        <!--logo-->
                        <div class="container d-flex justify-content-center">
                            <div class="mt-sm-0 mt-5 ">
                                <img class="img-section" src="assets/images/tituloOdonto.svg" alt="SURSOFT -  Odonto Integral Proyect">
                            </div>
                        </div>
                        <br>
                        <h2 class="h2-section">Proyecto Odonto Integral</h2>

                        <!--cuerpo-->
                        <div class="d-xl-flex">
                            <!--descripcion-->
                            <article class="color-article">
                                <p>
                                    Este proyecto nació por la necesidad de contar con una aplicación que ofreciera a los administradores y doctores de la clínica, un <b>centrado en la información</b>, tanto en lo referente a el <b>control financiero</b>                                    de esta, como del <b>registro de historias clínicas</b> y actividades de los doctores.
                                </p>
                                <h3 class="mb-0"><b><u>RESUMEN</u></b></h3>
                                <p>
                                    Para llevar a cabo el proyecto utilice .Net Core 2.1 en principio la cual recibió actualizaciones a 3.1 y .Net Core 5 en la actualidad, implementando la interfaz de usuario mediante razor pages, MVC, Boostrap y con SQL Server para la persistencia de datos.
                                </p>
                                <p>En este proyecto desarrolle diferentes <b>funcionalidades</b> como:</p>
                                <ul class="mb-0">
                                    <li>
                                        <p>
                                            <b>Historias clínicas:</b> las mismas son totalmente personalizables en cuanto a anamnesis de las mismas.
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            <b>Funcionalidades para laboratorios con los que se trabaje:</b> en esta se proporciona la posibilidad de registrar los aranceles de los laboratorios y se añade la posibilidad a cada doctor de calificar los
                                            trabajos entregados, dando una visión general de cuales son los mejor puntuados.
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            <b>Agenda:</b> se proporciona a los doctores una agenda en la cual se puede sincronizar las citas, ya sea si trabajara en una o varias clínicas de la empresa, de esta manera se evita la superposición de consultas
                                            en las mismas
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            <b>Gestión de gastos:</b> se proporciona a administradores la posibilidad de gestionar los servicios, impuestos, insumos y proveedores.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Aranceles de la clínica:</b> en esta sección se brinda la posibilidad de crear categorías y tratamientos para que los doctores puedan seleccionar en los planes de tratamiento de tal manera que se va generando
                                            el costo del mismo.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Resúmenes financieros:</b> se proporciona un dashboard desde el cual se puede acceder a toda la actividad y resúmenes financieros de la clínica como de los doctores en particular, también brindando listas
                                            de deudores, gastos, ingresos y ganancias.
                                        </p>
                                    </li>
                                </ul>
                            </article>

                            <!--imagenes-->
                            <div class="col-xl-5 d-xl-block text-center">
                                <a href="assets/images/odonto.png" data-lightbox="photos">
                                    <img class="img-section" src="assets/images/odonto.png" alt="SURSOFT -  Odonto integral 1">
                                </a>
                                <a href="assets/images/odonto2.png" data-lightbox="photos">
                                    <img class="img-section mt-4" src="assets/images/odonto2.png" alt="SURSOFT -  Odonto integral 1">
                                </a>
                            </div>
                        </div>

                        <!--botones saber mas y demo-->
                        <div class="pt-3 col-12">
                            <!--mas detalles-->
                            <div class="d-flex mb-0">
                                <i class="fas fa-angle-double-right arrow-icon"></i>
                                <h3 class="mb-0 color-article">
                                    Si quieres profundizar más puedes hacerlo aquí…
                                    <button (click)="navegarAPagina('detalles-odonto')" mat-flat-button class="link">
                                            <i class="fas fa-link mr-2"></i>MÁS DETALLES
                                        </button>

                                </h3>
                            </div>
                            <!--ir a demo-->
                            <div class="d-flex mb-0 color-article">
                                <i class="fas fa-angle-double-right arrow-icon"></i>
                                <h3 class="mb-0">
                                    Si quieres probar la demo, solicítame un usuario de pruebas e ingresa a la página de demo siguiente…

                                    <a href="http://dentalnet.sursoft.org/" target="blank" mat-flat-button class="link">
                                        <i class="fas fa-link mr-2"></i>VER DEMO
                                    </a>

                                </h3>
                            </div>
                        </div>
                    </section>

                    <!--Madriguera Libros-->
                    <section id="madriguera" class="p-3">
                        <!--logo-->
                        <div class="container d-flex justify-content-center">
                            <div class="mt-sm-0 mt-5">
                                <img class="img-section" src="assets/images/tituloMadriguera.svg" alt="SURSOFT -  La Madriguera Libros">
                            </div>
                        </div>
                        <br>
                        <h2 class="h2-section">Proyecto La Madriguera Libros</h2>

                        <!--cuerpo-->
                        <div class="d-xl-flex">

                            <!--descripcion-->
                            <article class="color-article">

                                <p>
                                    La aplicación de lamadrigueralibros.com fue desarrollada originalmente como una <b>herramienta de gestión</b> para el manejo interno de la empresa, posteriormente el cliente solicito una expansión de la misma, en donde
                                    se pudiera incorporar una funcionalidad de tipo <b>e-Commerce</b> y el agregado de una nueva categoría (juegos de mesa) con <b>integración a Mercado Pago</b> para la recepción de los pagos.
                                </p>

                                <h3 class="mb-0"><b><u>RESUMEN</u></b></h3>
                                <p>
                                    Para llevar a cabo el proyecto utilice .Net Core 5, razor pages, MVC, Boostrap, SQL Server para la DB e integración con Mercado Pago para las compras online.
                                </p>
                                <p>En este proyecto se ofrecen las siguientes <b>funcionalidades</b>:</p>
                                <ul class="mb-0">
                                    <li>Control de <b>usuarios y proveedores</b></li>
                                    <li>Agilización de las tareas de <b>control de stock</b> libros, Cds y juegos de mesa</li>
                                    <li>CRUD para <b>gestión de catálogos masivos</b></li>
                                    <li>CRUD para <b>post de la página</b> principal</li>
                                    <li>Marcado de precios de los productos a través de <b>códigos QR</b></li>
                                    <li> <b>Administración de ventas</b> </li>
                                    <li><b>Carrito de compras</b> integrado a Mercado Pago</li>
                                    <li><b>Reportes</b> de:
                                        <ul>
                                            <li>Artículos más vendidos</li>
                                            <li>Cantidad de ventas</li>
                                            <li>Recaudación de la empresa</li>
                                            <li>Ventas por proveedor</li>
                                            <li>Comparación de ventas Online vs Local</li>
                                            <li>Resumen de ganancias</li>
                                        </ul>
                                    </li>

                                </ul>
                            </article>

                            <!--imagenes-->
                            <div class="col-xl-5 d-xl-block text-center">
                                <a href="assets/images/madriguera.jpg" data-lightbox="photos">
                                    <img class="img-section" src="assets/images/madriguera.jpg" alt="SURSOFT -  Madriguera Libros">
                                </a>
                                <a href="assets/images/madriguera2.png" data-lightbox="photos">
                                    <img class="img-section mt-4" src="assets/images/madriguera2.png" alt="SURSOFT -  Madriguera Libros">
                                </a>
                            </div>
                        </div>

                        <!--botones saber mas y demo-->
                        <div class="pt-3 col-12">
                            <!--mas detalles-->
                            <div class="d-flex mb-0">
                                <i class="fas fa-angle-double-right arrow-icon"></i>
                                <h4 class="mb-0 color-article">
                                    Si quieres profundizar más sobre este proyecto puedes hacerlo aquí…

                                    <button (click)="navegarAPagina('detalles-madriguera')" mat-flat-button class="link">
                                            <i class="fas fa-link mr-2"></i>MÁS DETALLES
                                        </button>

                                </h4>
                            </div>
                            <!--ir a demo-->
                            <div class="d-flex mb-0">
                                <i class="fas fa-angle-double-right arrow-icon"></i>
                                <h4 class="mb-0 color-article">
                                    Si quieres probar la demo, solicítame un usuario de pruebas e ingresa a la página de demo siguiente…

                                    <a href="https://www.madrigueralibros.sursoft.org/" mat-flat-button target="blank" class="link">
                                        <i class="fas fa-link mr-2"></i>VER DEMO
                                    </a>

                                </h4>
                            </div>
                        </div>
                    </section>

                    <!--Lunch Box-->
                    <section id="lunch" class="bg-Gris p-3">
                        <!--logo-->
                        <div class="container d-flex justify-content-center">
                            <div class="mt-sm-0 mt-5">
                                <img class="img-section" src="assets/images/tituloLunch.svg" alt="SURSOFT -  .NET 5">
                            </div>
                        </div>
                        <br>
                        <h2 class="h2-section">Proyecto Lunch Box Catering</h2>

                        <!--cuerpo-->
                        <div class="d-xl-flex">

                            <!--descripcion-->
                            <article class="color-article">

                                <p>
                                    La aplicación de <b>Lunch Box</b> fue diseñada según los requerimientos del cliente, para el <b>manejo interno de la empresa</b> mejorando la organización de la misma y brindando la oportunidad de llevar una <b>administración clara</b>                                    de todos los aspectos que la componian.
                                </p>

                                <h3 class="mb-0"><b><u>RESUMEN</u></b></h3>
                                <p>
                                    Este proyecto fue desarrollado en .Net Core 2.1, C#, razor pages, MVC, Boostrap y SQL Server para la DB.
                                </p>
                                <p>Este desarrollo conto con las siguientes <b>funcionalidades</b>:</p>
                                <ul class="mb-0">
                                    <li><b>Recepción de pedidos online</b></li>
                                    <li><b>Control de productividad</b> de los empleados</li>
                                    <li><b>Creación de presupuestos:</b> los administradores podian crear presupuestos para eventos los cuales proporcionaban cantidades y gastos para la confección de los mismos</li>
                                    <li><b>Control de stock:</b> tanto para la materia prima utilizada en la confección de los productos, como de los producto ya elaborados.</li>
                                    <li>Marcado de precios de los productos a través de <b>códigos QR</b></li>
                                    <li> <b>Recetario:</b> aqui se proporcionaban, cantidades, ingredientes y descripción de preparación para los productos y según lo indicado en las mismas el stock de ingredientes era modificado.</li>
                                    <li><b>Control de insumos y servicios</b></li>
                                    <li><b>Control de proveedores</b></li>
                                    <li><b>Manejo de pedidos:</b> control en el que confluian tanto pedidos online como para presupuestos.</li>
                                    <li><b>Check List</b> para ingredientes de presupuestos</li>
                                    <li><b>Reportes</b> de:
                                        <ul>
                                            <li>Resumen general financiero</li>
                                            <li>Productos más vendidos</li>
                                            <li>Productos más pedidos en eventos</li>
                                            <li>Productividad de empleados</li>
                                            <li>Ventas por mes</li>
                                            <li>Opiniones de usuarios</li>
                                        </ul>
                                    </li>

                                </ul>
                            </article>

                            <!--imagenes-->
                            <div class="col-xl-5 d-xl-block text-center">
                                <a href="assets/images/lunch.png" data-lightbox="photos">
                                    <img class="img-section" src="assets/images/lunch.png" alt="SURSOFT -  Lunch Box Catering">
                                </a>
                                <a href="assets/images/lunch2.png" data-lightbox="photos">
                                    <img class="img-section mt-4" src="assets/images/lunch2.png" alt="SURSOFT -  Lunch Box Catering">
                                </a>
                            </div>
                        </div>

                        <!--botones saber mas y demo-->
                        <div class="pt-3 col-12">
                            <!--mas detalles-->
                            <div class="d-flex mb-0">
                                <i class="fas fa-angle-double-right arrow-icon"></i>
                                <h4 class="mb-0 color-article">
                                    Si quieres profundizar más sobre este proyecto puedes hacerlo aquí…

                                    <button (click)="navegarAPagina('detalles-lunchbox')" mat-flat-button class="link">
                                            <i class="fas fa-link mr-2"></i>MÁS DETALLES
                                        </button>

                                </h4>
                            </div>
                            <!--ir a demo-->
                            <div class="d-flex mb-0">
                                <i class="fas fa-angle-double-right arrow-icon"></i>
                                <h4 class="mb-0 color-article">
                                    Si quieres probar la demo, solicítame un usuario de pruebas e ingresa a la página de demo siguiente…

                                    <a href="http://lunchboxdemo.sursoft.org/" mat-flat-button target="blank" class="link">
                                        <i class="fas fa-link mr-2"></i>VER DEMO
                                    </a>

                                </h4>
                            </div>
                        </div>
                        <br>
                    </section>
                </div>

            </div>
        </div>
    </div>
</div>

<button id="scrollBtn" (click)="scrollTop();" class="btn btn-floating btn-scrollUp waves-effect wow  fadeInUp" hidden>
    <i class="fas fa-arrow-up"></i>
</button>