import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { TemaNode, IndiceFlatNode } from '../utilidades/tree-model';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-detalles-madriguera',
  templateUrl: './detalles-madriguera.component.html',
  styleUrls: ['./detalles-madriguera.component.css']
})

export class DetallesMadrigueraComponent implements OnInit, OnDestroy {

  panelOpenState = false;

  
  constructor(private router: Router) { }
 
  ngOnInit(): void {
    //seteamos en el datosourse del arbol los datosD
    this.dataSource.data = this.INDICE_DATA;
    window.addEventListener("scroll", this.scrollEvent, true);
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  volver(){
    this.router.navigate(['portafolio/3/madriguera']);
  }

  scrollEvent() {
    const position = document.body.getBoundingClientRect().top;
    const btnScroll = document.getElementById("scrollBtn");
    const btnIndice = document.getElementById("indiceBtn");
    //console.log(position);
    if(position < -530){
      btnScroll.hidden = false;
    }else{
      btnScroll.hidden = true;
    }

    if(position < -65){
      btnIndice.hidden = false;
    }else{
      btnIndice.hidden = true;
    }
  }

  scrollTop(){
    window.scrollTo(0, 0);
  }

  @ViewChild('drawer3') sidenav: MatSidenav;

  scrollTo(elementId: string){
    //console.log(elementId);
    //obtenemos la posicion del scroll actual
   const scrollActual = window.scrollY;

   //obtenemos la posicion del elemento buscado y le restamos 63 del alto del menu y le sumamos el scroll actual que tuviera al momento de presionar el boton
   const scrollY = document.querySelector(elementId).getBoundingClientRect().top -63 +scrollActual;

    //nos desplazamos a la posicion dentro del div correspondiente
   window.scrollTo(0, scrollY);

   this.sidenav.close();
  }


  //cosas para el indice
  INDICE_DATA: TemaNode[] = [
    {
      id: "#sec1",
      name: 'Introducción al sistema',
    },
    {
      id: "#sec2",
      name: 'Usuarios del sistema',
    }, 
    {
      id: "#sec3",
      name: 'Registro de clientes'
    }, 
    {
      id: "#sec4",
      name: 'Logueo',
    }, 
    {
      id: "#sec5",
      name: 'Secciones de la aplicación',
      children: [
        {id: "#sec5",name: 'Introducción'},
        {id: "#sec6",name: 'Menu Horizontal'},
        {id: "#sec7",name: 'Menu Lateral'}
      ]
    }, 
    {
      id: "#sec8",
      name: 'Páginas Publicas',
      children: [
        {id: "#sec8", name: 'Introducción'},
        {id: "#sec9", name: 'Página de inicio'},
        {id: "#sec10", name: 'Página de libros'},
        {id: "#sec11", name: 'Página de musica'},
        {id: "#sec12", name: 'Página de juegos'},
        {id: "#sec13", name: 'Página de recomendados'},
        {id: "#sec14", name: 'Página de destacados'},
        {id: "#sec15", name: 'Página de Nosotros'},
        {id: "#sec16", name: 'Página de contácto'}
      ]
    }, 
    {
      id: "#sec17",
      name: 'Páginas Privadas',
      children: [
        {id: "#sec17",name: 'Introducción'},
        {id: "#sec18",name: 'Página de escritorio'},
        {id: "#sec19",name: 'Página de usuarios'},
        {id: "#sec20",name: 'Página de administradores'},
        {id: "#sec21",name: 'Cambiar password'},
        {id: "#sec22",name: 'Recuperar password'},
        {id: "#sec23", name: 'Página de proveedores'},
        {id: "#sec24", name: 'Catálogo de proveedor',
          children: [
            {id: "#sec24",name: 'Introducción'},
            {id: "#sec25", name: 'Agregar productos con Excel'},
            {id: "#sec26", name: 'Editar productos con Excel'},
            {id: "#sec27", name: 'Eliminación de catalogo'},
            {id: "#sec28", name: 'Agregar un producto'},
            {id: "#sec29", name: 'Editar un producto'},
            {id: "#sec30", name: 'Eliminar un producto'},
            {id: "#sec31", name: 'Creación de códigos QR'},
            
          ]
        },
        {id: "#sec32", name: 'Página de ventas'},
        {id: "#sec38", name: 'Página Recomendados Admin'},
        {id: "#sec39", name: 'Página destacados Admin'},
        {id: "#sec40", name: 'Página posts Admin'},
        {id: "#sec41", name: 'Deshabilitar compras web'},
      ]
    }
  ];

  //recibe un nodo y un numero de nivel y determina si es expandible porque si tiene hijos en su array, setea elñ nombre y el nivel
  private _transformer = (node: TemaNode, level: number) => {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  //encapsula el nivel y si es expansible el nodo
  treeControl = new FlatTreeControl<IndiceFlatNode>(node => node.level, node => node.expandable);

  //normaliza los datos del nodo, nivel, expansion e hijos
  treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.children);

  //encapsula el control de expansion del arbol y los nodos normalizados
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: IndiceFlatNode) => node.expandable;

}
