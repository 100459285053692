import { EventEmitter } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit,ViewEncapsulation  } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav'; 
import { trigger, transition } from '@angular/animations';  
import { slideAnimation } from '../utilidades/animation';
import * as AOS from 'aos';
@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.css'],  
  encapsulation: ViewEncapsulation.None,  
    animations: [  
        trigger('routerAnimations', [  
          transition('* => *', slideAnimation) 
        ])  
    ]  
})

export class MasterPageComponent implements OnInit{

  constructor() { }

  ngOnInit(): void {
    AOS.init();
    
  }

  public prepareRouteTransition(outlet): Animation {  
    const animation = outlet.activatedRouteData['animation'] || {};  
    return animation['value'] || null;  
  }  

  sidenavToggle = new EventEmitter();
  @ViewChild('drawer') sidenav: MatSidenav;
  seleccionado: string;
  
  cerrar() {
    this.sidenav.close();
  }

}
