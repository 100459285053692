<footer style="background-color: rgb(23, 23, 23); width: 100%;">

    <!-- Footer Links -->
    <div class="container ">
        <div class="row">

            <!-- Mensaje -->
            <div class="col-md-6 d-none d-md-block">
                <h4 class="estilo-h4">&copy; SUR SOFT</h4>
                <mat-divider></mat-divider>
                <p class="pt-3 text-justify parrafo-color">Gracias por visitar este sitio, estoy a tu disposición por cualquier consulta que tengas, sobre presupuestos y proyectos que hagan avanzar y desarrollar a tu empresa.</p>
            </div>

            <!-- contacto -->
            <div class="col-md-3 text-md-left text-center mb-3">
                <h4 class="estilo-h4" style="margin-left: 1.2rem;">Dev. Sebastián Ualde Ramírez</h4>
                <mat-divider></mat-divider>
                <div class="pt-2 ">
                    <a mat-button routerLink="contact">
                        <img data-toggle="tooltip" data-placement="top" title="Telegram | Email" src="assets/contacto.svg" style="width: 100%;" />
                    </a>
                </div>
            </div>

            <!-- Ultimos proyectos -->
            <div class="col-md-3 text-md-left text-center">
                <h4 class="estilo-h4">Últimos proyectos</h4>
                <mat-divider></mat-divider>
                <ul class="lista-proyectos">
                    <li><a class="link-color" target="_blank" href="https://odontointegraluy.com/">Odonto Integral - Odontología</a></li>
                    <li><a class="link-color" target="_blank" href="https://www.lamadrigueralibros.com/">La Madriguera Libros</a></li>
                    <li><a class="link-color" target="_blank" href="http://lunchboxdemo.sursoft.org/">Lunch Box Catering</a></li>
                </ul>
            </div>
        </div>
    </div>

    <!-- Copyright -->
    <div class="copyright">
        <div>
            <span>&copy;Copyright 2016 - {{fechaHoy}} SUR-SOFT SOLUCIONES</span> <a class="link-color" mat-button routerLink="inicio">by SURSOFT.org </a>
        </div>
    </div>

</footer>