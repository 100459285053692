import { _DisposeViewRepeaterStrategy } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { CargarScriptsService, ScriptModel } from '../utilidades/cargar-scripts.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private cargarScriptsService: CargarScriptsService) { }

  fechaHoy = new Date().getFullYear();

  scriptsNecesarios: ScriptModel[] = [
    {name: "tooltips", src: "assets/bootstrap/js/InitTooltips.js", loaded: false}
  ];

  ngOnInit(): void {
    this.cargarScriptsService.cargarScripts(this.scriptsNecesarios);

  }

  
}
