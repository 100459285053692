import { Component, Input } from '@angular/core';  
import { trigger, state, style, animate, transition, keyframes, group } from '@angular/animations';  
  
@Component({  
  selector: 'app-demo-animation',  
  template: `  
        <div class="mybox mx-auto" [@changeState]="currentState" ></div>  
    `,  
  styles :[`  
    .mybox {  
        background-color : #47748f;  
        width : 200px;  
        height : 200px;  
        border-radius : 6px;  
        margin : 6rem  
    }  
    .msgbox {  
        margin: 2rem;  
        padding-top:2rem;  
        font-size: 1.8rem;  
        text-align: center;  
    }  
  `],  
  animations: [  
    trigger('changeState', [  
        state('original', style({  
            backgroundColor: '#47748f',  
            transform: 'scale(1)'  
        })),  
        state('basic', style({  
            backgroundColor: '#440000',  
            transform: 'scale(1.4)'  
        })),  
        state('delaying', style({  
            backgroundColor: '#812170',  
            transform: 'scale(1.6)'  
        })),  
        transition('* => basic', animate('800ms')),  
        transition('* => original', animate('200ms')),  
        transition('* => delaying', animate('800ms 2000ms ease-out'))  
    ])]  
})  
  
export class DemoAnimationComponent {  
    @Input() currentState : string;     
}