import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { TemaNode, IndiceFlatNode } from '../utilidades/tree-model';

@Component({
  selector: 'app-detalles-lunchbox',
  templateUrl: './detalles-lunchbox.component.html',
  styleUrls: ['./detalles-lunchbox.component.css']
})
export class DetallesLunchboxComponent implements OnInit, OnDestroy {

  constructor(private router: Router) { }
 
  ngOnInit(): void {
    //seteamos en el datosourse del arbol los datosD
    this.dataSource.data = this.INDICE_DATA;
    window.addEventListener("scroll", this.scrollEvent, true);
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  volver(){
    this.router.navigate(['portafolio/3/lunch']);
  }

  scrollEvent() {
    const position = document.body.getBoundingClientRect().top;
    const btnScroll = document.getElementById("scrollBtn");
    const btnIndice = document.getElementById("indiceBtn");
    //console.log(position);
    if(position < -530){
      btnScroll.hidden = false;
    }else{
      btnScroll.hidden = true;
    }

    if(position < -65){
      btnIndice.hidden = false;
    }else{
      btnIndice.hidden = true;
    }
  }

  scrollTop(){
    window.scrollTo(0, 0);
  }

  @ViewChild('drawer4') sidenav: MatSidenav;
  
  scrollTo(elementId: string){
    //console.log(elementId);
    //obtenemos la posicion del scroll actual
   const scrollActual = window.scrollY;

   //obtenemos la posicion del elemento buscado y le restamos 63 del alto del menu y le sumamos el scroll actual que tuviera al momento de presionar el boton
   const scrollY = document.querySelector(elementId).getBoundingClientRect().top -63 +scrollActual;

    //nos desplazamos a la posicion dentro del div correspondiente
   window.scrollTo(0, scrollY);

   this.sidenav.close();
  }

  //cosas para el indice
  INDICE_DATA: TemaNode[] = [
    { id: "#sec1", name: 'Introducción al sistema'},
    { id: "#sec2", name: 'Usuarios del sistema',
      children: [
        {id: "#sec2", name: 'Introducción'},
        {id: "#sec3", name: 'Clientes'},
        {id: "#sec4", name: 'Empleados'},
        {id: "#sec7", name: 'Administradores'}
      ]
    }, 
    { id: "#sec8", name: 'Administración',
      children: [
        {id: "#sec8", name: 'Introducción'},
        {id: "#sec9", name: 'Usuarios'},
        {id: "#sec10", name: 'Categorías'},
        {id: "#sec11", name: 'Productos'},
        {id: "#sec12", name: 'Ingredientes'},
        {id: "#sec13", name: 'Insumos'},
        {id: "#sec14", name: 'Servicios'},
        {id: "#sec15", name: 'Proveedores'}
      ]
    }, 
    { id: "#sec16", name: 'Presupuestos',
      children: [
        {id: "#sec16", name: 'Introducción'},
        {id: "#sec17", name: 'Lista de Stocks'},
        {id: "#sec18", name: 'Check List'},
        {id: "#sec19", name: 'Factura'},
        {id: "#sec20", name: 'Edición del presup.'},
      ]
    }, 
    { id: "#sec21", name: 'Dias de elaboración',
      children: [
        {id: "#sec21", name: 'Introducción'},
        {id: "#sec22", name: 'Ordenes comunes'},
        {id: "#sec23", name: 'Ordenes presupuestos'},
      ]
    }, 
    { id: "#sec24", name: 'Estadisticas',
      children: [
        {id: "#sec24", name: 'Introducción'},
        {id: "#sec25", name: 'Resumen Financiero'},
        {id: "#sec26", name: 'Más vendidos'},
        {id: "#sec27", name: 'Más pedidos en Eventos'},
        {id: "#sec28", name: 'Productividad de empleados'},
        {id: "#sec29", name: 'Ventas por mes'},
        {id: "#sec30", name: 'Opiniones de usuarios'},
      ]
    }, 

  ];

  //recibe un nodo y un numero de nivel y determina si es expandible porque si tiene hijos en su array, setea elñ nombre y el nivel
  private _transformer = (node: TemaNode, level: number) => {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  //encapsula el nivel y si es expansible el nodo
  treeControl = new FlatTreeControl<IndiceFlatNode>(node => node.level, node => node.expandable);

  //normaliza los datos del nodo, nivel, expansion e hijos
  treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.children);

  //encapsula el control de expansion del arbol y los nodos normalizados
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: IndiceFlatNode) => node.expandable;

}
