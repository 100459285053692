import { Component, Input, OnInit } from '@angular/core';
import { CargarScriptsService, ScriptModel } from '../cargar-scripts.service';


@Component({
  selector: 'app-parallax',
  templateUrl: './parallax.component.html',
  styleUrls: ['./parallax.component.css'],
})

export class ParallaxComponent implements OnInit {

  constructor(private cargarSrciptsService: CargarScriptsService) { 
    
  }

  @Input() imgUrl:string;
  @Input() minHeight:string;
  @Input() nameId:string="";
  @Input() ponerLogo:boolean;
  @Input() titulo:string;
  @Input() descripcion:string;

  scriptsNecesarios: ScriptModel[] = [
    {name: "mdb", src: "assets/bootstrap/js/mdb.js", loaded: false}
  ];
  
  ngOnInit(): void {
    this.cargarSrciptsService.cargarScripts(this.scriptsNecesarios);
  }

}
