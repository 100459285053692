<div class="contenedor">
    <div class="col-md-10 d-sm-flex p-4 contenedor-Datos">
        <!--descripcion-->
        <div class="text-sm-left contenedor-descripcion mb-sm-0 mb-4 col-sm-5 ">

            <div class="mb-4 ">
                <p class="titulo color-orange">Contácto</p>
                <p class="descripcion">Si tienes cualquier pregunta, puedes hacérmela llegar por los siguientes medios o a través del formulario de esta página y te responderé lo antes posible.</p>
            </div>

            <div class="contenedor-iconos">
                <div class="d-flex justify-content-sm-start justify-content-center">
                    <div class="text-center">
                        <i class="fas fa-envelope fa-2x color-orange"></i>
                        <p class="color-orange mb-0"><b>Correo</b></p>
                    </div>
                </div>
                <div>
                    <img src="../../assets/images/sualdemail.svg" alt="" style="width: 9rem;">
                </div>
            </div>
            <div class="contenedor-iconos">
                <div class="d-flex justify-content-sm-start justify-content-center">
                    <div class="text-center">
                        <i class="fas fa-paper-plane fa-2x color-orange"></i>
                        <p class="color-orange mb-0"><b>Telegram</b></p>
                    </div>
                </div>
                <div>
                    <img src="../../assets/images/sualde.svg" alt="" style="width: 4rem;">
                </div>
            </div>
        </div>

        <!--formulario-->
        <div class="p-3 col-sm-7">

            <!--Mensaje success-->
            <div *ngIf="responseMessageSuccess" class="alert alert-success alert-dismissible fade show text-center" role="alert">
                {{responseMessageSuccess}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!--Mensaje error-->
            <div *ngIf="responseMessageError" class="alert alert-danger alert-dismissible fade show text-center" role="alert">
                {{responseMessageError}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!--loader para cuando se envia-->
            <div *ngIf="isSending" id="iconoCarga" class="row justify-content-center align-items-center h-100">
                <div id="loading-div" class="ui-corner-all">

                    <div id="loadingbar" class="row justify-content-center">

                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-blue-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <h2 style="color:gray;font-weight:normal;">Enviando mensaje...</h2>
                </div>
            </div>

            <!--formulario contacto-->
            <div *ngIf="!isSending">
                <form (submit)="enviarMensaje();" [formGroup]="form">
                    <!--nombre-->
                    <mat-form-field appearance="outline">
                        <mat-label>Nombre*</mat-label>
                        <input formControlName="nombre" matInput/>
                        <mat-error *ngIf="form.invalid">{{obtenerErrorNombre()}}</mat-error>
                    </mat-form-field>

                    <!--email-->
                    <mat-form-field appearance="outline">
                        <mat-label>Email*</mat-label>
                        <input formControlName="email" matInput/>
                        <mat-error>{{obtenerErrorEmail()}}</mat-error>
                    </mat-form-field>

                    <!--mensaje-->
                    <mat-form-field appearance="outline">
                        <mat-label>Mensaje*</mat-label>
                        <textarea id="mensaje" formControlName="mensaje" maxlength="500" matInput placeholder="Escribe tu consulta aqui..." (ngModelChange)="onCommentChange()"></textarea>
                        <mat-hint class="color-contador-textarea">{{textarea.length || 0}}/500</mat-hint>
                        <mat-error *ngIf="form.invalid">{{obtenerErrorMensaje()}}</mat-error>
                    </mat-form-field>

                    <!--captcha-->
                    <ngx-recaptcha2 class="captcha-overflow w-100 d-flex justify-content-center mb-4" #captchaElem [theme]="theme" [siteKey]="siteKey" [language]="es" [Theme]="Dark" [size]="size" formControlName="recaptcha">
                    </ngx-recaptcha2>

                    <!--submit button-->
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-orange  btnSubmit rounded-pill d-flex jsutify-content-center align-items-center" [disabled]="form.invalid">Enviar
                        </button>
                    </div>

                </form>
            </div>


        </div>


    </div>
</div>