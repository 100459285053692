import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { TemaNode, IndiceFlatNode } from '../utilidades/tree-model';

@Component({
  selector: 'app-detalles-odonto',
  templateUrl: './detalles-odonto.component.html',
  styleUrls: ['./detalles-odonto.component.css']
})

export class DetallesOdontoComponent implements OnInit, OnDestroy {

  constructor(private router: Router) { 
    
  }
 
  ngOnInit(): void {
    //seteamos en el datosourse del arbol los datosD
    this.dataSource.data = this.INDICE_DATA;
    window.addEventListener("scroll", this.scrollEvent, true);
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  volver(){
    this.router.navigate(['portafolio/3/odonto']);
  }

  scrollEvent() {
    const position = document.body.getBoundingClientRect().top;
    const btnScroll = document.getElementById("scrollBtn");
    const btnIndice = document.getElementById("indiceBtn");
    //console.log(position);
    if(position < -530){
      btnScroll.hidden = false;
    }else{
      btnScroll.hidden = true;
    }

    if(position < -65){
      btnIndice.hidden = false;
    }else{
      btnIndice.hidden = true;
    }
  }

  scrollTop(){
    window.scrollTo(0, 0);
  }

  @ViewChild('drawer2') sidenav: MatSidenav;

  scrollTo(elementId: string){
    //console.log(elementId);
    //obtenemos la posicion del scroll actual
   const scrollActual = window.scrollY;

   //obtenemos la posicion del elemento buscado y le restamos 63 del alto del menu y le sumamos el scroll actual que tuviera al momento de presionar el boton
   const scrollY = document.querySelector(elementId).getBoundingClientRect().top -63 +scrollActual;

    //nos desplazamos a la posicion dentro del div correspondiente
   window.scrollTo(0, scrollY);

   this.sidenav.close();

  }



  //cosas para el indice
  INDICE_DATA: TemaNode[] = [
    {
      id: "#sec1",
      name: 'Introducción al sistema',
    },
    {
      id: "#sec2",
      name: 'Secciones Principales',
    }, 
    {
      id: "",
      name: 'Usuarios del sistema',
      children: [
        {id: "#sec3",name: 'Introducción'},
        {id: "#sec4", name: 'SA y Admin'},
        {id: "#sec5",name: 'Doctores',},
        {id: "#sec6",name: 'Pacientes',},
      ]
    }, 
    {
      id: "",
      name: 'Administración de clínica',
      children: [
        {id: "#sec7",name: 'Introducción'},
        {id: "#sec8",name: 'Doctores'},
        {id: "#sec9",name: 'Laboratorios'},
        {id: "#sec10",name: 'Insumos'},
        {id: "#sec11",name: 'Servicios'},
        {id: "#sec12",name: 'Impuestos'},
        {id: "#sec13",name: 'Historias Clínicas '},
        {id: "#sec14",name: 'Caras Dentales'},
        {id: "#sec15",name: 'Consultorios'},
        {id: "#sec16",name: 'Metodos de pago'},
        {id: "#sec17",name: 'Proveedores'},
      ]
    }, 
    {
      id: "",
      name: 'Secciones para Doctores y Admins',
      children: [
        {id: "#sec18",name: 'Introducción'},
        {id: "",name: 'Página Inicio Clínica',
          children: [
            {id: "#sec19",name: 'Introducción'},
            {id: "#sec20",name: 'Reporte de doctores'},
            {id: "#sec21",name: 'Reporte de deudores'},
            {id: "#sec22",name: 'Reporte de ingresos'},
            {id: "#sec23",name: 'Reporte de gastos'},
            {id: "#sec24",name: 'Reporte de ganancias'},
            {id: "#sec25",name: 'Resumen general'},
          ]
        },

        {id: "",name: 'Agenda',
          children: [
            {id: "#sec26",name: 'Introducción'},
            {id: "#sec27",name: 'Agregar cita'},
            {id: "#sec28",name: 'Editar cita'},
            {id: "#sec29",name: 'Eliminar cita'},
          ]
        },
        {id: "",name: 'Pacientes',
          children: [
            {id: "#sec30",name: 'Introducción'},
            {id: "#sec31",name: 'Agregar paciente'},
            {id: "#sec32",name: 'Editar paciente'},
            {id: "#sec33",name: 'Eliminar paciente'},
          ]
        },
        {id: "",name: 'Historia Clínica',
          children: [
            {id: "#sec34",name: 'Introducción'},
            {id: "#sec38",name: 'Sesiones Clínicas'},
            {id: "#sec39",name: 'Historial de citas'},
            {id: "#sec40",name: 'Anamnesis'},
            {id: "#sec41",name: 'Anexos de historia'},
            {id: "#sec42",name: 'Lista de tratamientos'},
            {id: "#sec43",name: 'Trabajos de Laboratorio'},
            {id: "#sec44",name: 'Odontograma'},
            {id: "#sec45",name: 'Periodontograma'},
            {id: "#sec46",name: 'Índice de inflamación'},
            {id: "#sec47",name: 'I.H.O.S.'},
            {id: "#sec48",name: 'Imágenes'},
            {id: "#sec49",name: 'Consultas'},
          ]
        },
        {id: "#sec56",name: 'Especialidades'},
        {id: "#sec57",name: 'Arancel de clínica'},
        {id: "#sec58",name: 'Aranceles de laboratorios'},
        {id: "#sec59",name: 'Correo interno'},
        {id: "#sec60",name: 'Notificaciones'},
      ]
    }, 
  ];

  //recibe un nodo y un numero de nivel y determina si es expandible porque si tiene hijos en su array, setea elñ nombre y el nivel
  private _transformer = (node: TemaNode, level: number) => {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  //encapsula el nivel y si es expansible el nodo
  treeControl = new FlatTreeControl<IndiceFlatNode>(node => node.level, node => node.expandable);

  //normaliza los datos del nodo, nivel, expansion e hijos
  treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.children);

  //encapsula el control de expansion del arbol y los nodos normalizados
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: IndiceFlatNode) => node.expandable;

}
