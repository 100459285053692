<div id="{{carouselId}}" class="carousel slide " data-ride="carousel">

    <ol class="carousel-indicators">
        <li *ngFor="let slide of slides" [attr.data-target]="carouselTarget" [attr.data-slide-to]="slide.slideNumber" class="{{slide.isActive}}"></li>
    </ol>

    <div class="carousel-inner ">
        <div id="slide" *ngFor="let slide of slides" class="carousel-item  mb-3 {{slide.isActive}}" [style.height]="carHeight">
            <div class="carSlide">

                <div class=" d-sm-flex carousel-container z-depth-1">
                    <!--imagen-->
                    <div class="col-sm-4 p-0">
                        <img src="{{slide.image}}" class="h-100" alt="" style="width: inherit;">
                    </div>

                    <!--descripcion-->
                    <div class="col-sm-8 p-3 d-sm-flex align-items-center">

                        <div class="p-2">
                            <span class=" h1-responsive">{{slide.titulo}}</span>
                            <hr />
                            <p class="carousel-h2">{{slide.descripcion}}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Controles-->
    <a class="carousel-control-prev" href="{{carouselTarget}}" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
    </a>
    <a class="carousel-control-next" href="{{carouselTarget}}" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
    </a>
</div>