<div data-jarallax data-speed="0.2" class="jarallax carSlide" [id]="nameId" [style.background-image]="imgUrl" [style.min-height]="minHeight">

    <!--LOGO-->
    <div *ngIf="ponerLogo" class="mascara">

        <div class="container h-100 d-flex  align-items-center">

            <div class="row justify-content-center">

                <div>
                    <img class="img-fluid" data-aos="fade-right" data-aos-duration="2000" src="assets/images/S.svg" />
                </div>

                <div class="mt-4 p-2">
                    <p class="mb-1">
                        <img class="img-fluid" data-aos="fade-down" data-aos-duration="1500" src="assets/images/sursoft.svg" />
                    </p>
                    <p data-aos="zoom-in" data-aos-duration="1600" class="mb-5 ">
                        <img src="assets/images/soluciones.svg" class="img-fluid" />
                    </p>
                </div>
            </div>

        </div>


    </div>
    <div data-aos="fade-in" data-aos-duration="1000">
        <div *ngIf="titulo" class="p-3 carousel-texto">
            <h1>{{titulo}}</h1>
            <h2>{{descripcion}}</h2>
        </div>
    </div>
</div>