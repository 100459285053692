import { HttpClient } from '@angular/common/http';
import { ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ContactComponent implements OnInit  {

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  
  constructor(private FormBuilder: FormBuilder, private httpClient: HttpClient) { }

  form: FormGroup;
  responseMessageError: string ="";
  responseMessageSuccess: string ="";
  isSending:boolean = false;
  
  //key del captcha de desarrollo
  siteKey:string = environment.captchaSiteKey;
  theme:string="Dark";
  
  //direccion de la app que se genera en google para los mails
  private MY_GOOGLE_WEB_APP: string = environment.appDeMailGoogle;

  ngOnInit(): void {
    this.form = this.FormBuilder.group({
          nombre:['', 
          {
            validators: [Validators.required, Validators.minLength(3)]
          }],
          email:['', 
          {
            validators: [Validators.required, Validators.email]
          }],
          mensaje:['', 
          {
            validators: [Validators.required, Validators.minLength(3), Validators.maxLength(500)]
          }],
          recaptcha: ['', Validators.required]
      });
  }

  enviarMensaje(): void{

    if(this.form.valid){
      // desactiva el formulario para evitar el envio de multiples submits
      this.isSending=true;
      
      //obtenemos los datos del formulario
      var formData: any = new FormData();
      formData.append("name", this.form.get("nombre").value);
      formData.append("email", this.form.get("email").value);
      formData.append("message", this.form.get("mensaje").value);
        
      //hacemos la llamada a nuestro app de google
      this.httpClient.post(this.MY_GOOGLE_WEB_APP, formData)
      .subscribe((response) => {
        //selector de la respuesta del mensaje
        if(response["result"] == "success"){
          this.responseMessageSuccess = "Gracias por tu mensaje! Te respondere lo antes posible!";      
        } else {
          this.responseMessageError= "Oops!! Algo salió mal... recarga la página e intentalo de nuevo.";
        }

        this.limpiarFormulario();
      },
      (error) => {
        this.responseMessageError = "Oops! Ocurrió un error inesperado... Recarga la página e intentalo nuevamente";
        this.limpiarFormulario();
      });   
    }
  }

  limpiarFormulario(){
    this.isSending=false;
    this.form.reset();  
  }
  
  //contador de caracteres para el text area
  textarea:string= "";
  onCommentChange(){
    var text = this.form.get('mensaje');
    this.textarea= text.value;
  }

  obtenerErrorNombre(){ 
    var campo = this.form.get('nombre');

    if(campo.hasError('required')){
      return 'El campo nombre es requerido.';
    }

    if(campo.hasError('minlength')){
      return 'El nombre debe tener 3 caracteres como minimo.';
    }

    return '';
  }

  obtenerErrorEmail(){ 
    var campo = this.form.get('email');

    if(campo.hasError('required')){
      return 'El campo email es requerido.';
    }

    return 'El email no es válido';

  }

  obtenerErrorMensaje(){
    var campo = this.form.get('mensaje');

    if(campo.hasError('required')){
      return 'El campo Mensaje es requerido';
    }

    if(campo.hasError('minlength')){
      return 'El mensaje debe tener 3 caracteres como minimo.';
    }

    if(campo.hasError('maxlength')){
      return 'El mensaje debe tener 500 caracteres como máximo.';
    }

    return '';
  }

}
