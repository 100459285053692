
    import { Component } from '@angular/core';  
    import { trigger, state, style, animate, transition } from '@angular/animations';  
      
    @Component({  
      selector: 'mouse-animation',  
      template: `  
            <div class="mymouse mx-auto" (mouseenter)="changeMouseState('hover')" (mousedown)="changeMouseState('press')"   
                (mouseleave)="changeMouseState('rest')" [@changeState]="currentState"></div>  
        `,  
      styles: [`  
        .mymouse {  
            background-color: #5c8f52;  
            width: 100px;  
            height: 100px;  
            border-radius: 200px;  
            margin: 6rem;  
        }  
      `],  
        animations: [  
        trigger('changeState', [  
            state('rest', style({  
                transform: 'scale(1)'  
            })),  
            state('hover',   style({  
                transform: 'scale(1.8)'  
            })),  
            state('press',   style({  
                transform: 'scale(3.0)',  
                backgroundColor: '#8f5a7a'  
            })),  
            transition('rest => hover', animate('400ms ease-in')),  
            transition('hover => rest', animate('200ms ease-out')),  
            transition('hover => press', animate('400ms ease-in')),  
            transition('press => rest', animate('200ms ease-out'))  
        ])  
      
        ]  
    })  
      
    export class MouseAnimationComponent {  
        currentState:string = "rest";  
      
        changeMouseState(state:string) :void {  
            this.currentState = state;  
        }  
      
    }  

