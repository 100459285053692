<div id="{{carouselId}}" class="carousel slide" data-ride="carousel">

    <ol class="carousel-indicators">
        <li *ngFor="let slide of slides" [attr.data-target]="carouselTarget" [attr.data-slide-to]="slide.slideNumber" class="{{slide.isActive}}"></li>
    </ol>

    <div class="carousel-inner">
        <div id="slide" *ngFor="let slide of slides" class="carousel-item  {{slide.isActive}}" [style.height]="carHeight">
            <div class="carSlide" style="background-image: url({{slide.image}})">

                <div *ngIf="slide.titulo" class="p-3 carousel-texto animated fadeInRight">
                    <h1>{{slide.titulo}}</h1>
                    <h2>{{slide.descripcion}}</h2>
                </div>
            </div>
        </div>
    </div>

    <!--Controles-->
    <a class="carousel-control-prev" href="{{carouselTarget}}" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
    </a>
    <a class="carousel-control-next" href="{{carouselTarget}}" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
    </a>
</div>