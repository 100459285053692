//modulos
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatCarouselModule } from '@ngmodule/material-carousel'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { ToastrModule } from 'ngx-toastr';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {ReactiveFormsModule} from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { NgxCaptchaModule } from 'ngx-captcha';

//paginas
import { MasterPageComponent } from './master-page/master-page.component';
import { InicioComponent } from './inicio/inicio.component';
import { TecnologiasComponent } from './tecnologias/tecnologias.component';
//import { LaboratorioComponent } from './laboratorio/laboratorio.component';
import { PortafolioComponent } from './portafolio/portafolio.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
//import { PruebasComponent } from './pruebas/pruebas.component';

//componentes personalizados
import { AlertComponent } from './utilidades/alert/alert.component';
import { FooterComponent } from './footer/footer.component';
import { ParallaxComponent } from './utilidades/parallax/parallax.component';
import { DemoAnimationComponent } from './utilidades/demo-animation/demo-animation.component'
import { MouseAnimationComponent} from './utilidades/mouse-animation/mouse-animation.component';
import { CarouselComponent} from './utilidades/carousel/carousel.component';
import { CardCustomComponent } from './utilidades/card-custom/card-custom.component';
import { Carousel2Component } from './utilidades/carousel2/carousel2.component';
import { CKEditorComponent } from './utilidades/ckeditor/ckeditor.component';
import { DetallesMadrigueraComponent } from './detalles-madriguera/detalles-madriguera.component';
import { DetallesOdontoComponent } from './detalles-odonto/detalles-odonto.component';
import { DetallesLunchboxComponent } from './detalles-lunchbox/detalles-lunchbox.component';

@NgModule({
  declarations: [
    AppComponent,
    MasterPageComponent,
    InicioComponent,
    PortafolioComponent,
    AboutComponent,
    ContactComponent,
    //PruebasComponent,
    AlertComponent,
    FooterComponent,
    ParallaxComponent,
    DemoAnimationComponent,
    MouseAnimationComponent,
    CarouselComponent,
    CardCustomComponent,
    Carousel2Component,
    TecnologiasComponent,
    //LaboratorioComponent,
    CKEditorComponent,
    DetallesMadrigueraComponent,
    DetallesOdontoComponent,
    DetallesLunchboxComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatCarouselModule.forRoot(),
    ToastrModule.forRoot(),
    CKEditorModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxCaptchaModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
