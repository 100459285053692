import { Component, OnDestroy, OnInit } from '@angular/core';
import { CargarScriptsService, ScriptModel } from '../../utilidades/cargar-scripts.service';

@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.css']
})
export class CKEditorComponent implements OnInit, OnDestroy {
  scriptsNecesarios: ScriptModel[] = [
    {name: "tooltips", src: "assets/bootstrap/js/InitTooltips.js", loaded: false}
  ];
  constructor(private cargarScriptsService: CargarScriptsService) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.cargarScriptsService.cargarScripts(this.scriptsNecesarios);
  }

}
