import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CargarScriptsService, ScriptModel } from '../utilidades/cargar-scripts.service';

@Component({
  selector: 'app-portafolio',
  templateUrl: './portafolio.component.html',
  styleUrls: ['./portafolio.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PortafolioComponent implements OnInit, OnDestroy{

  isDisenioActivo: string = "";
  isWebActivo: string ="";
  isPersonalizadasActivo: string="";
  
  constructor(private cargarScriptsService: CargarScriptsService,private route: ActivatedRoute, private router: Router) { }

  scriptsNecesarios: ScriptModel[] = [
    {name: "tooltips", src: "assets/bootstrap/js/InitTooltips.js", loaded: false}
  ];
  
  ngOnInit(): void {
    document.getElementById("div1").addEventListener("scroll",this.scrollEvent,true );
    this.cargarScriptsService.cargarScripts(this.scriptsNecesarios);
    this.seleccionarTabActivo();
    this.cargarImagenes();

  }

  ngOnDestroy(): void {
    document.getElementById("div1").removeEventListener("scroll",this.scrollEvent,true ); 
  }

  navegarAPagina(pagina:string){
    this.router.navigate(['/'+pagina]);
  }

  scrollEvent(){
    const position = document.getElementById("div1").scrollTop;
    const button = document.getElementById("scrollBtn");

    //console.log(position);

    if(position > 408){
      //console.log("mayor a 408");
      button.hidden = false;
    }else{
      button.hidden = true;
    }
  }
  
  scrollTop(){
    document.getElementById("div1").scrollTop = 0;
  }

  scrollTo(elementId: string){

   //si la pantalla es redimensionada se toma el scroll hasta llegar a los botones de navegacion y se los suma a la posicion de Y para compensar
    const scrollActual = document.getElementById("div1").scrollTop;

    //obtenemos la posicion del elemento y le restamos 63 del menu horizontal y le sumamos el posible scroll que haya hecho el usuario
    var posY = document.querySelector(elementId).getBoundingClientRect().top -63 + scrollActual;

    //nos desplazamos a la posicion dentro del div correspondiente
    document.getElementById("div1").scrollTop = posY;
  }

  seleccionarTabActivo(){
    var tabActivo = this.route.snapshot.paramMap.get("tabActivo");

    switch (tabActivo){
      case "1":
        this.isDisenioActivo = "show active";    
      break;
      case "2":
        this.isWebActivo ="show active";
      break;
      case "3":
        this.isPersonalizadasActivo ="show active"
      break;
      default:
        this.isDisenioActivo = "show active";
      break;
    }
 
    //periodo de tiempo para que se cargue y se seleccione el tab correspondiente, sino no se hace click
    var seleccionado = this.route.snapshot.paramMap.get("seleccionado")+"Go";

    if(seleccionado != "nullGo"){
      setTimeout(function () {
        document.getElementById(seleccionado).click();
      }, 100);
    }
   
  }

  //#region diseños graficos

  imagenesTodas: any[] = [];
  imagenesDeBanners: any[] = [];
  imagenesDeTarjetas: any[] = [];
  imagenesDeRestauraciones: any[] = [];
  imagenesDeLogos: any[] = [];

  cargarImagenes(){
    this.cargarLista(this.imagenesDeBanners, 53, "banners");
    this.cargarLista(this.imagenesDeTarjetas, 21, "tarjetas");
    this.cargarLista(this.imagenesDeRestauraciones, 12, "rest-fotos");
    this.cargarLista(this.imagenesDeLogos, 26, "logos");
    this.cargarImgTodas();
  }

  cargarLista(lista:any[], numItems:number, capeta:string){
    for (let i = 1; i < numItems; i++) {
      lista.push({src:`assets/images/disenios/${capeta}/${i}.jpg`, descripcion:''});
    }
  }

  cargarImgTodas(){
    this.imagenesTodas.push({src:"assets/images/disenios/tri.jpg", descripcion:''});
    this.imagenesTodas.push({src:"assets/images/disenios/luciana.jpg", descripcion:''});
    this.imagenesTodas.push.apply(this.imagenesTodas, this.imagenesDeBanners);
    this.imagenesTodas.push.apply(this.imagenesTodas,this.imagenesDeTarjetas);
    this.imagenesTodas.push.apply(this.imagenesTodas,this.imagenesDeRestauraciones);
    this.imagenesTodas.push.apply(this.imagenesTodas,this.imagenesDeLogos);
  }

  //#endregion
 
}
