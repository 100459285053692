import { Component, OnInit } from '@angular/core';
import { CargarScriptsService, ScriptModel } from '../utilidades/cargar-scripts.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  scriptsNecesarios: ScriptModel[] = [
    {name: "tooltips", src: "assets/bootstrap/js/InitTooltips.js", loaded: false}
  ];

  constructor(private cargarScriptsService: CargarScriptsService) { }

  ngOnInit(): void {
    window.addEventListener("scroll", this.scrollEvent, true);
    this.cargarScriptsService.cargarScripts(this.scriptsNecesarios);
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  scrollEvent() {
    const position = document.body.getBoundingClientRect().top;
    const button = document.getElementById("scrollBtn");
    //console.log(position);
    if(position < -408){
      button.hidden = false;
    }else{
      button.hidden = true;
    }
  }
  
  scrollTop(){
    window.scrollTo(0, 0);
  }

}
