import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TecnologiasComponent } from './tecnologias/tecnologias.component'
//import { LaboratorioComponent } from './laboratorio/laboratorio.component';
import { InicioComponent} from './inicio/inicio.component';
import { PortafolioComponent } from './portafolio/portafolio.component';
//import { PruebasComponent } from './pruebas/pruebas.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { DetallesLunchboxComponent } from './detalles-lunchbox/detalles-lunchbox.component';
import { DetallesMadrigueraComponent } from './detalles-madriguera/detalles-madriguera.component';
import { DetallesOdontoComponent } from './detalles-odonto/detalles-odonto.component';

const routes: Routes = [
  {path: '', component: InicioComponent},
  {path: 'inicio', component: InicioComponent, data: {animation: { value: 'inicio',}}},
  {path: 'tecnologias', component: TecnologiasComponent, data: {animation: { value: 'tecnologias',}}},
  //{path: 'laboratorio', component: LaboratorioComponent, data: {animation: { value: 'laboratorio',}}},
  {path: 'portafolio', component: PortafolioComponent, data: {animation: { value: 'portafolio',}}},
  {path: 'portafolio/:tabActivo', component: PortafolioComponent, data: {animation: { value: 'portafolio',}}},
  {path: 'portafolio/:tabActivo/:seleccionado', component: PortafolioComponent, data: {animation: { value: 'portafolio',}}},
  //{path: 'pruebas', component: PruebasComponent, data: {animation: { value: 'pruebas',}}},
  {path: 'about', component: AboutComponent, data: {animation: { value: 'about',}}},
  {path: 'contact', component: ContactComponent, data: {animation: { value: 'contact',}}},
  {path: 'detalles-lunchbox', component: DetallesLunchboxComponent, data: {animation: { value: 'detalles-lunchbox',}}},
  {path: 'detalles-madriguera', component: DetallesMadrigueraComponent, data: {animation: { value: 'detalles-madriguera',}}},
  {path: 'detalles-odonto', component: DetallesOdontoComponent, data: {animation: { value: 'detalles-odonto',}}},
  
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
