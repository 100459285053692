import { Injectable } from '@angular/core';

//esto es para usa jquery
var $;

@Injectable({
  providedIn: 'root'
})


export class CargarScriptsService {

  constructor() { }

  cargarScripts(scriptsUrls: ScriptModel[]){

    scriptsUrls.forEach(element => {

      //limpiamos si ya estuviera cargado el script
      var existe = document.getElementById(element.name);

      if(existe != undefined){
        existe.remove();
      }

      // cargamos el script nuevo 
      let scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.src = element.src;        
      scriptElement.id = element.name;
      
      scriptElement.onload;     
      scriptElement.onerror = (error: any) => {
        console.log("no se encontro el script");
      };
      
      //document.getElementsByTagName('body')[0].appendChild(scriptElement);  
      document.getElementById('myScripts').appendChild(scriptElement);
    }); 
  }

}

export interface ScriptModel {
  name: string,
  src: string,
  loaded: boolean
}
