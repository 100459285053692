import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-custom',
  templateUrl: './card-custom.component.html',
  styleUrls: ['./card-custom.component.css']
})
export class CardCustomComponent implements OnInit {
  
  constructor() {
  }

  @Input() imageSrc: string;
  @Input() title: string;
  @Input() description: string;

  @Input() srcSaberMas: string = "";

  //boton de demo
  @Input() btnText: string = "ver Demo";
  @Input() demoSrc: string;
  @Input() targetDemo: string = "";

  ngOnInit(): void {
  }

}
