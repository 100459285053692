import { Component, OnDestroy, OnInit } from '@angular/core';
import { CargarScriptsService, ScriptModel } from '../utilidades/cargar-scripts.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})

export class InicioComponent implements OnInit, OnDestroy {

  scriptsNecesarios: ScriptModel[] = [
    {name: "tooltips", src: "assets/bootstrap/js/InitTooltips.js", loaded: false}
  ];
  
  constructor(private cargarScriptsService: CargarScriptsService) { 
  }

  ngOnDestroy(): void {
    //console.log('Cleared');
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  ngOnInit(): void {
    window.addEventListener("scroll", this.scrollEvent, true);
    this.cargarScriptsService.cargarScripts(this.scriptsNecesarios);
  }

  scrollEvent() {

    const position = document.body.getBoundingClientRect().top;
    const button = document.getElementById("scrollBtn");

    if(position < -610){
      button.hidden = false;
    }else{
      button.hidden = true;
    }
  }
}
