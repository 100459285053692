<div class="d-flex">

    <button class="btn-menu bgcolor-indice pl-md-4 py-3" mat-flat-button (click)="drawer4.toggle()">Índice</button>

    <div class="col titulo-madriguera">

    </div>

    <button class="btn-menu bgcolor-volver pr-md-4 py-3" mat-flat-button (click)="volver()">Volver</button>
</div>


<mat-drawer-container class="indice-container" [hasBackdrop]="'True'">

    <!-- menu lateral -->
    <mat-drawer class="menu-indice sidenav-fixed" #drawer4 [mode]="'over'">
        <div class="contenedor-menu">

            <!-- cabezal del titulo -->
            <div class="cerrar-Indice-container">
                <h1 class="titulo-cerrar">Índice</h1>
                <a class="btn-cerrar" (click)="drawer4.toggle()">
                    <i class="fas fa-times color-naranja"></i>
                </a>
            </div>

            <!-- arbol de contenido -->
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

                <!-- esta es para nodos sin hijos -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>

                    <a (click)="scrollTo(node.id)" class="btn-nodo sin-hijos text-wrap" mat-flat-button>
                        <i class="fas fa-bookmark mr-2"></i> {{node.name}}
                    </a>
                </mat-tree-node>

                <!-- esta es para los nodos que tienen hijos -->
                <mat-tree-node class="nodo-hijo" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                    <button class="btn-nodo" mat-flat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                        <span>
                            {{node.name}}
                        </span>
                    </button>

                </mat-tree-node>
            </mat-tree>
        </div>
    </mat-drawer>

    <!-- contenido -->
    <mat-drawer-content>
        <a id="indiceBtn" (click)="drawer4.toggle()" class="btn btn-indice-onscroll waves-effect wow fadeInLeft" hidden>
            Ver Índice
        </a>

        <a id="scrollBtn" (click)="scrollTop()" class="btn btn-floating btn-scrollUp waves-effect wow fadeInUp" hidden>
            <i class="fas fa-arrow-up"></i>
        </a>

        <!--Introduccion-->
        <section id="sec1" class="bg-blanco p-3">

            <!--logo-->
            <div class="container d-flex justify-content-center">
                <div class="mt-sm-0 mt-5">
                    <img class="img-section" src="assets/images/tituloLunch.svg" alt="SURSOFT -  .NET 5">
                </div>
            </div>
            <br>

            <div class="container padding-sec">

                <h2 class="h2-section">Introducción</h2>

                <!--cuerpo-->
                <div class="d-xl-flex">

                    <!--descripcion-->
                    <article class="color-article">

                        <p>
                            La aplicación de <b>Lunch Box</b> fue diseñada según los requerimientos del cliente, para el <b>manejo interno de la empresa</b> mejorando la organización de la misma y brindando la oportunidad de llevar una <b>administración clara</b>                            de todos los aspectos que la componian.
                        </p>
                        <p>
                            Este proyecto fue desarrollado en .Net Core 2.1, C#, razor pages, MVC, Boostrap y SQL Server para la DB.
                        </p>
                        <p>Este desarrollo conto con las siguientes <b>funcionalidades</b>:</p>
                        <ul class="mb-0">
                            <li><b>Recepción de pedidos online</b></li>
                            <li><b>Control de productividad</b> de los empleados</li>
                            <li><b>Creación de presupuestos:</b> los administradores podian crear presupuestos para eventos los cuales proporcionaban cantidades y gastos para la confección de los mismos</li>
                            <li><b>Control de stock:</b> tanto para la materia prima utilizada en la confección de los productos, como de los producto ya elaborados.</li>
                            <li>Marcado de precios de los productos a través de <b>códigos QR</b></li>
                            <li> <b>Recetario:</b> aqui se proporcionaban, cantidades, ingredientes y descripción de preparación para los productos y según lo indicado en las mismas el stock de ingredientes era modificado.</li>
                            <li><b>Control de insumos y servicios</b></li>
                            <li><b>Control de proveedores</b></li>
                            <li><b>Manejo de pedidos:</b> control en el que confluian tanto pedidos online como para presupuestos.</li>
                            <li><b>Check List</b> para ingredientes de presupuestos</li>
                            <li><b>Reportes</b> de:
                                <ul>
                                    <li>Resumen general financiero</li>
                                    <li>Productos más vendidos</li>
                                    <li>Productos más pedidos en eventos</li>
                                    <li>Productividad de empleados</li>
                                    <li>Ventas por mes</li>
                                    <li>Opiniones de usuarios</li>
                                </ul>
                            </li>

                        </ul>
                    </article>

                    <!--imagenes-->
                    <div class="col-xl-5 d-xl-block text-center">
                        <a href="assets/images/lunch.png" data-lightbox="photos">
                            <img class="img-section" src="assets/images/lunch.png" alt="SURSOFT -  Lunch Box Catering">
                        </a>
                        <a href="assets/images/lunch2.png" data-lightbox="photos">
                            <img class="img-section mt-4" src="assets/images/lunch2.png" alt="SURSOFT -  Lunch Box Catering">
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <!-- Usuarios del sistema -->
        <section id="sec2" class="bg-gris">
            <div class="container padding-sec">
                <h2 class="h2-section">Usuarios del sistema</h2>

                <article class="color-article">
                    <p>
                        Los usuarios del sistema se dividen en 3:
                    </p>
                    <ul>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec3')">Cliente</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec4')">Empleado</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec5')">Administrador</button> </li>
                    </ul>
                    <p>
                        Esta diferenciación marca las responsabilidades de cada actor dentro del sistema, proporcionando para cada uno su rol y sus credenciales que le permitiran ver las secciones especificas del mismo.
                    </p>

                    <div class="row justify-content-center">
                        <div class="div-imagenes">
                            <a href="assets/images/img-lunch/1.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/1.jpg" alt="SURSOFT -  Lunch Box Demo">
                            </a>
                            <p>Vista Cliente</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-lunch/2.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/2.jpg" alt="SURSOFT -  Lunch Box Demo">
                            </a>
                            <p>Vista Admin</p>
                        </div>
                        <div class="div-imagenes">
                            <a href="assets/images/img-lunch/3.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/3.jpg" alt="SURSOFT -  Lunch Box Demo">
                            </a>
                            <p>Vista Empleado</p>
                        </div>
                    </div>

                </article>
            </div>
        </section>

        <!-- Clientes -->
        <section id="sec3" class="bg-blanco">
            <div class="container padding-sec">
                <h2 class="h2-section">Clientes</h2>

                <article class="color-article">
                    <h2>Registro</h2>
                    <p>
                        Los clientes son aquellos usuarios del sistema con <b>menores credenciales</b>, los cuales tendrán acceso a la lista de productos y a la posibilidad de realizar pedidos a la empresa, solo si se registran en el sistema.
                    </p>

                    <p>Para ellos deberán ir a la página de logueo (imagen 4 .1).</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/4.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/4.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 4</p>
                    </div>

                    <p>En caso de no estar registrados se le pedirán los siguientes datos (imagen 5):</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/5.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/5.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 5</p>
                    </div>

                    <p>Nota: En un principio también se requería la cedula de identidad del cliente como medida de seguridad extra para el registro, pero el cliente decidió no hacer uso de la misma, quedando de la forma anteriormente indicada.</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/6.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/6.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 6</p>
                    </div>

                    <p>
                        Una vez registrados los datos en el sistema, se envía un correo electrónico al cliente para la verificación de su casilla de email, en el cual, se le proporciona un link (imagen 7 .1) para la confirmación de su cuenta con lo cual se activa su usuario.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/7.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/7.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 7</p>
                    </div>

                    <p>Una vez confirmado el cliente ya podrá acceder a hacer sus pedidos.</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/8.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/8.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <h2>Realización de pedidos</h2>

                    <p>
                        La realización de pedidos de los clientes es sencilla, como en cualquier página. Para ello el mismo dispondrá de un catálogo de productos disponibles, separados por categorías y subcategorías pudiendo elegir cualquiera y agregarlos a su carrito de compras.
                    </p>

                    <p>
                        Cada producto tiene las opciones de marcar la cantidad solicitada (imagen 8. A), la posibilidad de seleccionar opciones del mismo (en caso de que las tuviera – imagen 8. B) y la posibilidad de agregar una aclaración o comentario referente al producto
                        que quiera (ejemplo: lo quiero sin sal – imagen 8 .C).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/9.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/9.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 8</p>
                    </div>

                    <p>
                        Una vez seleccionado el producto y marcadas las opciones y aclaraciones al darle al botón de agregar el mismo será incluido en el carrito de compras, con la posibilidad de realizar cualquier cambio al mismo.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/10.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/10.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 9</p>
                    </div>

                    <p>
                        Como vemos en el carrito de compras (imagen 9) el cliente vera un resumen de su pedido en donde tendrá una lista con los productos solicitados y donde se detallan las opciones Extras seleccionadas junto con su valor, el total del pedido (imagen 9 .D),
                        la posibilidad de editar la línea en particular (imagen 9 .A) o eliminar la línea del carrito (imagen 9 .B).
                    </p>

                    <p>
                        También cabe destacar que un administrador puede otorgar a un cliente un cupón de descuento por un porcentaje especifico el cual podrá ser agregado en el carrito cuando el cliente lo desee (imagen 9 .E).
                    </p>

                    <p>
                        Este código de descuento será visible para el cliente en el propio carrito de estar disponible como se muestra a continuación.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/11.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/11.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>Cabe destacar que estos cupones son de un único uso, una vez confirmado el pedido ya no se podrá usar ese cupón y también debemos aclarar que el cupón es intransferible entre usuarios.</p>

                    <h2>Confirmación de pedido</h2>

                    <p>
                        Al confirmar el pedido se solicitará el día de entrega y la hora del mismo.
                    </p>

                    <p>
                        Según lo indicado por el cliente la fecha de entrega debe ser con una antelación de por lo menos 7 días desde la confirmación.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/12.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/12.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>Una vez confirmado el pedido, el cliente podra ver el estado del mismo en la seccion de pedidos.</p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/13.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/13.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/14.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/14.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 10</p>
                    </div>

                    <p>
                        Como vemos en la imagen 10 .A el cliente puede ver fecha y hora de entrega, precio, el estado de su pedido (en preparación, preparado, pagado) y los detalles del mismo.
                    </p>

                    <p>
                        También como podemos observar el cliente una vez de que reciba su pedido tendrá la posibilidad de contar con un botón de feedback o comentario (imagen 10 .B) en donde podrá acceder a una pagina para calificar el servicio.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/15.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/15.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Esta información será solo visible para el administrador, según los requerimientos del cliente, teniendo un informe de las calificaciones generales de los servicios los cuales describiremos más adelante en la sección de Estadísticas.
                    </p>

                </article>
            </div>
        </section>

        <!--Empleados -->
        <section id="sec4" class="bg-gris">
            <div class="container padding-sec">

                <h2 class="h2-section">Empleados</h2>
                <article class="color-article">

                    <p>
                        En el apartado de empleado podemos ver que dentro de los cambios que presenta el mas relevante se da en la sección de pedidos.
                    </p>

                    <p>
                        Ahí encontrara, aparte de la lista de sus propios pedidos que pudiera realizar a la empresa (imagen 11 .C), ya que un empleado también puede ser un cliente, dos botones de trabajo.
                    </p>

                    <p>Estos botones son:</p>
                    <ul>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec5')">Órdenes para entregar (imagen 11 .A)</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec6')">Trabajos para hoy (imagen 11 .B)</button> </li>
                    </ul>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/16.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/16.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 11</p>
                    </div>
                </article>
            </div>

        </section>

        <!--Órdenes para entregar -->
        <section id="sec5" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Órdenes para entregar</h2>

                    <p>
                        En órdenes para entregar, encontrara todos los pedidos que estén preparados y listos para su entrega.
                    </p>

                    <p>
                        En esta lista se presentan los siguientes botones para cada línea:
                    </p>

                    <ul>
                        <li>
                            <b>Botón de dirección</b>: (imagen 12 .A) este botón proporciona un link que redirecciona a Google maps el cual nos devolverá un mapa con la dirección del cliente para facilitar el viaje de entrega del empleado que tenga que
                            repartir los pedidos, agilizando el reparto.
                        </li>
                        <li>
                            <b>botón de pagado</b>: (imagen 12 .B) esté botón es el que confirma el pago del cliente el cual según lo establecido en las reuniones el pedido puede ser entregado sin ser pagado según sea el cliente.
                        </li>
                        <li>
                            <b>botón de entregado</b>: (imagen 12 .C) una vez marcado el pedido como entregado el mismo desaparecerá de la lista en. En caso de error el estado de entregado solo podrá ser cambiado por un administrador.
                        </li>
                        <li>
                            <b>botón de detalles</b>: (imagen 12 .D) el mismo proporciona la información del pedido en caso de dudas del cliente o del empleado que entrega el pedido, otorgando la información del mismo.
                        </li>
                    </ul>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/17.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/17.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 12</p>
                    </div>
                </article>
            </div>

        </section>

        <!-- Trabajos para hoy -->
        <section id="sec6" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">
                    <h2>Trabajos para hoy</h2>

                    <p>
                        En trabajos para hoy, (imagen 11 .B) el empleado encontrara todos los trabajos que el administrador indique para hacer en el día. Los mismos irán apareciendo de manera automática si fueron asignados, como dijimos, por un administrador anteriormente.
                    </p>

                    <p>
                        En esta página tendremos dos listas las cuales dividirán los trabajos de ordenes comunes y los productos de eventos que hayan sido confirmados y asignados a el día actual.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/18.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/18.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Una vez desplegada alguna de las listas podremos ver los detalles de la misma, en donde el empleado podrá ver las posibles aclaraciones del pedido y haciendo click sobre la imagen podrá acceder a la receta del producto en donde vera las cantidades de
                        la receta en particular y las cantidades totales requeridas según lo solicitado por el cliente.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/19.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/19.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Antes de empezar un trabajo, el empleado deberá darle click al botón de <b>Comenzar a preparar</b>, esto hará que el mismo quede registrado como trabajo del empleado brindando los datos para la evaluación de rendimiento del mismo.
                    </p>
                    <p>
                        Cabe destacar que una vez marcado como <b>en preparación</b> por un empleado, solo ese empleado podrá marcarlo como preparado. Esto es para evitar que otro empleado aproveche y marque trabajos que no preparo como propios quitando
                        el crédito a quien si lo preparo.
                    </p>
                    <p>
                        En caso de errores en el marcado de preparación solo los administradores podrán cambiar el estado del mismo.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/20.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/20.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Una vez que el producto esté preparado desaparecerá de la lista y si el pedido total esta completo el mismo aparecerá en la lista de Ordenes para entregar (imagen 11 .A).
                    </p>

                </article>
            </div>

        </section>

        <!--Administradores -->
        <section id="sec7" class="bg-gris">
            <div class="container padding-sec">

                <h2 class="h2-section">Administradores</h2>
                <article class="color-article">

                    <p>
                        Los administradores son los usuarios del sistema con más privilegios en cuanto a visualización de la información y edición de la misma.
                    </p>

                    <p>
                        En este apartado mostraremos la variada información en relación a los anteriores usuarios (clientes y empleados) que pueden ver los administradores en la sección de pedidos.
                    </p>

                    <p>
                        En ella como podemos ver el administrador tiene una lista de todos los pedidos del sistema (imagen 13 .H), junto con una serie de botones que cumplen diferentes funciones que pasaremos a describir a continuación.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/21.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/21.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 13</p>
                    </div>

                    <p>
                        Como vemos en la lista de los pedidos, los administradores cuentan con un botón que brinda la posibilidad de Rechazar el pedido (imagen 13 .G) en caso de un incumplimiento o de sobrepaso de trabajo.
                    </p>

                    <p>
                        Si se hace uso del mismo se debería tener la delicadeza de consultar el mail del cliente y enviar un correo informando del rechazo del pedido y los motivos del mismo.
                    </p>

                    <p>
                        Como vimos anteriormente en el caso de los empleados el administrador posee también los dos botones Ordenes para entregar (imagen 13 .A) y Trabajos Para Hoy (imagen 13 .B) los cuales ya describimos anteriormente, por tanto nos enfocaremos a los botones
                        restantes.
                    </p>

                    <h2>Botón Ordenes entregadas y pagadas</h2>

                    <p>
                        Este botón (imagen 13 .C), nos llevara a una lista de las ordenes que ya fueron pagadas y entregadas de donde podremos navegar a través de los detalles de cada una para saber quién preparo la misma y todos los datos del pedido.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/22.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/22.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <h2>Botón Ordenes entregadas SIN pagar</h2>

                    <p>
                        Este botón (imagen 13 .D) nos entregara una lista de aquellos pedidos que tienen pendiente su pago y que ya fueron entregados a su respectivo cliente.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/23.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/23.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <h2>Botón ordenes comunes sin preparar</h2>

                    <p>
                        Este botón (imagen 13 .E), no entrega una lista con aquellos pedidos comunes que todavía no fueron preparados, con el objetivo de filtrar y aclarar al administrador los trabajos pendientes comunes que existen en el sistema.
                    </p>

                    <p>
                        En esta lista también se encuentran botones rápidos para, en caso de que se marcaran por error, corregir los estados de las ordenes en cuanto a si fueron pagados y entregados.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/24.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/24.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <h2>Botón de Ordenes de presupuestos para preparar</h2>

                    <p>
                        Este botón nos devuelve una lista de los productos agregados en presupuestos pendientes de preparación. Tomar en cuenta que aquí aparecerán los productos SOLO si el presupuesto fue marcado como confirmado.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/25.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/25.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                </article>
            </div>

        </section>

        <!-- Administración -->
        <section id="sec8" class="bg-blanco">
            <div class="container padding-sec">

                <h2 class="h2-section">Administración</h2>

                <article class="color-article">

                    <p>
                        En el siguiente apartado se mostrará paso a paso el manejo de las diferentes categorías del sistema en relación a la administración interna de la empresa, como son:
                    </p>

                    <ol>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec9')">Usuarios</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec10')">Categorías</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec11')">Productos</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec12')">Ingredientes</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec13')">Insumos</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec14')">Servicios</button> </li>
                        <li><button class="link-pagina sin-borde" (click)="scrollTo('#sec15')">Proveedores</button> </li>
                    </ol>

                    <p>
                        Todos los puntos anteriores son competencia de los administradores. Los mismos son las personas con más privilegios sobre el sistema y quienes tienen acceso a toda la información y funcionalidades del mismo.
                    </p>

                    <p>
                        Dentro del sistema por razones de seguridad existen dos super administradores los cuales no pueden ser borrados por otros admins.
                    </p>

                </article>
            </div>

        </section>

        <!-- Administración de Usuarios -->
        <section id="sec9" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>1. Administración de Usuarios</h2>

                    <p>
                        Para acceder a la sección de administración de usuarios tendremos que ir al menú lateral y seleccionar Administración (imagen 14 .A) y luego Usuarios (imagen 14 .B).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/26.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/26.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 14</p>
                    </div>

                    <p>
                        En esta página tendremos 3 botones para el manejo de los 3 tipos de usuarios del sistema, los cuales son empleados, administradores y clientes.
                    </p>

                    <h3><u>Administración de empleados</u></h3>

                    <p>
                        En esta página podremos ver una lista de los empleados que tenemos actualmente en el sistema (imagen 15 .C) con la posibilidad de ver sus datos en el botón de detalles (imagen 15 .D) o de editarlo con el botón de edición (imagen 15 .E).
                    </p>

                    <p>
                        También dispondremos de dos botones, Contratar (imagen 15.A) y agregar (imagen 15 .B).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/27.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/27.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 15</p>
                    </div>

                    <h3><em>Botón Contratar</em></h3>

                    <p>
                        Con este botón accederemos a una página con los usuarios actuales del sistema de los cuales podremos contratarlos mediante el botón de contratar (imagen 16 .A).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/28.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/28.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 16</p>
                    </div>

                    <h3><em>Botón agregar</em> </h3>

                    <p>
                        Teniendo en cuenta a empleados que no tengan conocimientos suficientes en informática podremos agregar un usuario sin tener que hacer todo el procedimiento de registro.
                    </p>

                    <p>
                        Para ello daremos click en el botón de agregar (imagen 15 .B) e ingresaremos los datos del empleado. Se debe tener en cuenta que una vez agregado el administrador deberá proporcionarle los datos de ingreso al empleado los cuales serán el email utilizado
                        para agregarlo al sistema y el password o contraseña indicadas.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/29.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/29.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <h3><em>Edición de empleado</em> </h3>

                    <p>
                        Para cada empleado dispondremos de la opción de modificar ciertos valores del mismo (imagen 17 .A), así como acceder a la información referente a su productividad en la empresa (imagen 17 .C), como a el manejo de sus líneas de sueldo (imagen 17 .D).
                    </p>

                    <p>
                        También desde esta página de edición podremos despedir al empleado o volver al mismo administrador del sistema (imagen 17 .B).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/30.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/30.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 17</p>
                    </div>

                    <p>
                        Para agregar líneas de pago para el empleado simplemente tendremos que hacer click en el botón de Agregar nuevo pago (imagen 17 .D), el cual no llevara a la página para crear el mismo.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/31.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/31.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Estos datos serán usados para la parte de finanzas en donde tendremos resúmenes de gastos en sueldos entre otros.
                    </p>

                    <h3><u>Administración de administradores</u></h3>

                    <p>
                        En esta página encontraremos la lista de los administradores, de los cuales los super admins serán imposibles de eliminar (imagen 18 .A) .
                    </p>

                    <p>
                        También tomando en cuenta lo anterior y para facilitar el proceso se podrá marcar a un empleado como admin mediante el botón de agregar (imagen 18 .B) el cual nos presentará una lista de los empleados actuales.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/32.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/32.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 18</p>
                    </div>

                    <p>
                        En esta sección también podremos ver los detalles de los admins con los botones de detalle (imagen 18 .C) y podremos eliminarlos del rol admin mediante el botón de eliminar Admin (imagen 18 .D).
                    </p>

                    <h3><u>Administración de clientes</u></h3>

                    <p>
                        En esta página nos encontraremos con la lista de todos los clientes del sistema (imagen 19 .A), en donde tendremos la información de cada uno (imagen 19 .C), junto con la cantidad de compras realizadas (imagen 19 .B), la posibilidad de bloquear a algún
                        cliente que no haya cumplido con sus responsabilidades (imagen 19 .E) y también podremos asignar cupones de descuento para aquellos clientes que deseemos (imagen 19 .D).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/33.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/33.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 19</p>
                    </div>

                    <h3><em>Bloquear cliente</em> </h3>

                    <p>
                        Al bloquear un cliente el sistema no pedirá una razón para el bloqueo, lo cual nos proporciona, en caso del reclamo del mismo, poder averiguar la razón que tuvo algún admin para el bloqueo y poder consultar con el mismo si el bloqueo tenía lugar o no.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/34.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/34.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <h3><em>Asignar cupón de descuento</em> </h3>

                    <p>
                        En esta sección también podremos crear cupones de descuento según el criterio que se tenga en la empresa, ya sea para mejores compradores o clientes preferenciales.
                    </p>

                    <p>
                        Para ello buscaremos el cliente en particular y daremos click en el botón de cupones de descuento (imagen 19 .B).
                    </p>

                    <p>
                        En la página de los cupones tendremos una lista con los cupones asignados a ese cliente (imagen 20 .A) y el botón de crear un nuevo cupón (imagen 20 .B).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/35.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/35.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 20</p>
                    </div>

                    <p>
                        Como vemos en la imagen 20, la lista nos detalla el porcentaje de descuento (C), el código del cupón (D), la orden del cupón (E) en caso de que este asignado, la posibilidad de editar el cupón en cuanto a su porcentaje siempre y cuando el cupón no esté
                        utilizado ya (F) y la posibilidad de eliminar el cupón (G) también siempre y cuando no haya sido asignado a una orden aun.
                    </p>

                </article>
            </div>

        </section>

        <!-- Administración de administradores -->
        <section id="sec10" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>2. Administración de categorías</h2>

                    <p>
                        En la siguiente sección podremos crear categorías y subcategorías para asignar productos a las mismas y ofrecer un ordenamiento para los clientes, facilitando así la búsqueda de los productos para los mismos.
                    </p>

                    <p>
                        Para ello podremos acceder a esta página desde el menú lateral haciendo click en Administración (imagen 21 .A) y luego en Categorías (imagen 21 .B).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/36.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/36.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 21</p>
                    </div>

                    <p>
                        Una vez en la página de Administración de categorías veremos una lista con las categorías madres, ya que cada categoría madre puede tener varias hijas (imagen 21 .C).
                    </p>

                    <p>
                        También veremos un botón de Crear nueva (imagen 21 .D) el cual nos dará la posibilidad de crear una categoría madre.
                    </p>

                    <p>
                        Las categorías madre son las que se verán tanto en la página principal como en el menú lateral (imagen 22), las categorías hijas serán aquellas que se verán en las paginas madres respectivas.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/37.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/37.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 22</p>
                    </div>

                    <h3><em>Crear nueva categoría</em></h3>

                    <p>
                        Para crear una nueva categoría haremos click en el botón Crear nueva (imagen 21 .D) lo que nos llevara a la página de creación de la misma.
                    </p>

                    <p>
                        En esta página podremos poner una imagen, un nombre y una descripción de la misma.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/38.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/38.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Una vez agregada la categoría ya estará disponible en el menú lateral y en la sección de administración de productos para poder ser asignada a los mismos.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/39.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/39.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 23</p>
                    </div>

                    <p>
                        Como vemos en la imagen 23, tenemos la nueva categoría madre agregada (A). Además, en cada una tendremos un botón de edición (B) y un botón de eliminación (C).
                    </p>

                    <p>
                        En el botón de Edición podremos cambiar el nombre, descripción e imagen de la categoría, como también podremos agregar las subcategorías que deseemos mediante el botón de agrega nueva subcategoría.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/40.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/40.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Una vez agregada una subcategoría la podremos ver en la lista de la categoría madre y podremos editar la misma o eliminarla.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/41.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/41.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        También la misma se agregará como filtro en las páginas de categorías madre para los clientes.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/42.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/42.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                </article>
            </div>

        </section>

        <!-- Administración de productos -->
        <section id="sec11" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>3. Administración de productos</h2>

                    <p>
                        En esta sección es donde agregaremos todos los productos que pondremos a la venta.
                    </p>

                    <p>
                        Para ellos iremos en el menú lateral a Administración (imagen 24 .A) y luego a Productos (imagen 24 .B).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/43.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/43.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 24</p>
                    </div>

                    <p>
                        En la página de productos tendremos una lista con todos los productos disponibles en el sistema (imagen 24 .D) en donde por cada uno de ellos tendremos 3 botones, para ver los detalles del producto (imagen 24 .E), para editar el producto (imagen 24 .F)
                        y para eliminar el mismo (imagen 24 .G).
                    </p>

                    <p>
                        En la parte superior encontraremos un botón para crear un nuevo producto (imagen 24 .C).
                    </p>

                    <h3><em>Crear nuevo producto</em></h3>

                    <p>
                        Como vimos antes para crear un nuevo producto iremos al botón crear nuevo, lo cual nos llevara a la página correspondiente en donde deberemos ingresar los datos del mismo.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/44.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/44.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Una vez creado el producto, el mismo aparecerá en la página de Administración de productos (imagen 25 .A)
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/45.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/45.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 25</p>
                    </div>

                    <h3><em>Editar producto</em></h3>

                    <p>
                        Como dijimos anteriormente cada producto puede ser editado mediante el botón de edición (imagen 24 .F) en donde podremos cambiar los datos básicos del producto (imagen 26 .A).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/46.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/46.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 26</p>
                    </div>

                    <p>
                        También aquí podemos crear promociones agregando un porcentaje de descuento a los productos (imagen 26 .B). Estos descuentos serán aplicados automáticamente a los productos y mostrados en las tarjetas del producto a los clientes marcando el descuento.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/47.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/47.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <h3><em>Agregar opción a producto</em></h3>

                    <p>
                        En esta página tendremos la posibilidad de agregar opciones adicionales a los productos.
                    </p>

                    <p>
                        Para ello en la parte inferior de la página encontraremos una sección llamada opciones de producto agregadas (imagen 27), en la que encontraremos una lista y un botón para agregar opción.
                    </p>

                    <p>
                        El precio de las opciones se agregará al precio del producto en los carritos de compra de los clientes.
                    </p>

                    <p>
                        En la lista de opciones (imagen 27 .B), tendremos la posibilidad de modificar (imagen 27 .C) o eliminar dicha opción (imagen 27 .D).
                    </p>

                    <p>
                        Para agregar una opción, simplemente daremos click al botón Agregar nueva opción y colocaremos los datos requeridos. En caso de que la opción no tenga un costo extra tendremos que colocar cero como precio.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/48.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/48.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 27</p>
                    </div>

                    <h3><em>Editar receta del producto</em></h3>

                    <p>
                        También en esta sección podremos editar la receta para el mismo (imagen 26 .C), la cual es sumamente importante para el control de stock de ingredientes de la empresa. Cabe destacar que no es requerido agregar una receta, pero si se quiere llevar un control
                        de stock preciso en el sistema, es absolutamente necesario completar como mínimo los ingredientes de la receta.
                    </p>

                    <p>
                        Para ello haremos click en Detalles de receta (imagen 26 .C) y en la ventana que nos abre daremos click en Editar.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/49.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/49.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Esto nos llevara a la página de creación de la receta.
                    </p>

                    <p>
                        En ella podremos cambiar el nombre (imagen 28 .A), agregar la preparación de la misma (imagen 28 .B). Recomendamos que la preparación sea detallada para brindarle toda la información necesaria a los empleados que no tengan claro la preparación del producto.
                    </p>

                    <p>
                        Por último, tenemos el agregado de los ingredientes de la receta (imagen 28 .C).
                    </p>

                    <p>
                        En la parte inferior de la pantalla encontraremos dos listas:
                    </p>

                    <ul>
                        <li>
                            Agregar ingredientes (imagen 28 .D), en donde encontraremos todos los ingredientes del sistema (en caso que no encontremos el ingrediente requerido deberemos ir a Administración/Ingredientes y agregarlo ahí para poder incluirlo en la receta).
                        </li>
                        <li>
                            Ingredientes Agregados (imagen 28 .E), en esta lista encontraremos los ingredientes que agregamos a la receta y donde podremos modificar los mismo.
                        </li>
                    </ul>

                    <p class="bg-danger text-white rounded p-3 text-center">

                        NOTA: tener en cuenta y prestar mucha atención a las unidades con las que se maneja cada ingrediente ya que de no tener cuidado se podría ingresar valores incorrectos, como por ejemplo si tenemos el ingrediente Harina que tiene como unidad gr y nosotros
                        en la receta agregamos 1kg de Harina lo que se restara en el stock de harina será 1gr, para que todo sea correcto si la receta lleva 1kg deberíamos colocar 1000gr. Recomendamos que las unidades a manejar en ingredientes sean pequeñas
                        ya que esto favorece el proceso de creación de recetas, debido a que a menor unidad más precisión en las medidas.

                    </p>

                    <h3><em>Editar insumos del producto</em></h3>

                    <p>
                        También en esta sección, al igual que con la receta podemos agregar insumos al producto haciendo click en Insumos del producto (imagen 26 .D) y en la ventana que nos aparece hacer click en editar.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/50.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/50.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Los mismos no son una información relevante para los clientes los cuales no verán dicha información, pero si es importante para el administrador del sistema para llevar un control de stock de los mismo. Como ejemplo de esto colocaremos como insumo en
                        las pizzas las cajas de pizza.
                    </p>

                    <p>
                        El formato de esta página es igual al de la receta, en donde encontraremos un resumen de los insumos agregado (imagen 29 .A) y dos listas en la parte inferior:
                    </p>

                    <ul>
                        <li>
                            Agregar insumo (imagen 29 .B): en esta lista tendremos todos los insumos agregados en el sistema, de no encontrar el requerido deberemos ir a Administración/Insumos y ahí agregar el insumo para luego volver a este producto y poder agregarlo.
                        </li>
                        <li>
                            Insumos Agregados (imagen 29 .C): esta es la lista en la que podremos modificar o eliminar el o los insumos del producto.
                        </li>
                    </ul>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-lunch/51.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/51.jpg" alt="SURSOFT -  Lunch Box Demo">
                            </a>
                            <p>Imagen 29</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-lunch/52.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/52.jpg" alt="SURSOFT -  Lunch Box Demo">
                            </a>
                        </div>
                    </div>

                </article>
            </div>

        </section>

        <!-- Administración de Ingredientes -->
        <section id="sec12" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>4. Administración de Ingredientes</h2>

                    <p>
                        En esta sección podremos administrar todos los ingredientes que son necesario para la confección de los productos a vender.
                    </p>

                    <p>
                        Para ello tendremos que ir al menú lateral y seleccionar Administración (imagen 30 .A) y luego Ingredientes (imagen 30 .B).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/53.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/53.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 30</p>
                    </div>

                    <p>
                        En esta página tendremos un botón para agregar nuevos ingredientes (imagen 30 .C) como también encontraremos una lista con todos los ingredientes agregados en el sistema (imagen 30 .D).
                    </p>

                    <p>
                        Cada uno de los ingredientes tendrá la opción de detalles del mismo (imagen 30 .E), edición del ingrediente (imagen 30 .F) y eliminación del mismo (imagen 30 .G).
                    </p>

                    <h3>Crear ingrediente</h3>

                    <p>
                        Para crear un ingrediente haremos click en el botón Crear nuevo (imagen 30 .C).
                    </p>

                    <p>
                        Se nos abrirá una nueva página en donde ingresaremos los datos básicos del ingrediente.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/54.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/54.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Como dijimos anteriormente, se debe tener en cuenta la unidad en la que mediremos el stock del ingrediente ya que podría llevar a errores al crear las recetas.
                    </p>

                    <p>
                        Como dijimos si agregamos un ingrediente harina y aquí le colocamos como unidad kg, luego vamos a la receta y la misma requiere 200 gr no podremos usar este ingrediente ya que su unidad esta fuera de escala ya que si colocamos 200 en la receta serian
                        200kg.
                    </p>

                    <p>
                        Como dijimos antes, es recomendable que las unidades sean pequeñas para abarcar todas las posibilidades.
                    </p>

                    <h3>Editar ingrediente</h3>

                    <p>
                        En esta sección podremos editar los datos básicos del ingrediente (imagen 31 .A) así como agregar compras al mismo para aumentar el stock total.
                    </p>

                    <p>
                        En la parte inferior encontraremos un botón para agregar nuevas compras (imagen 31 .B) y una lista de líneas de compras de ese ingrediente con toda la información del mismo (imagen 31 .C).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/55.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/55.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 31</p>
                    </div>

                    <h3><em>Agregar nueva compra</em></h3>

                    <p>
                        En esta sección se nos pedirá los datos de la compra realizada y el proveedor al que se le compra la misma.
                    </p>

                    <p>
                        En caso de no encontrar el proveedor en la lista deberá agregarlo en el sistema yendo a Administración/Proveedores en el menú lateral.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/56.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/56.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <h3><em>Editar línea de compra</em></h3>

                    <p>
                        En esta página podremos editar los datos de la compra en caso de errores al agregarla y pudiendo además agregar de forma extra los datos de desperdicios del ingrediente comprado.
                    </p>

                    <p>
                        Este parámetro fue requerido por el cliente.
                    </p>

                </article>
            </div>

        </section>

        <!-- Administración de Insumos -->
        <section id="sec13" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>5. Administración de Insumos</h2>

                    <p>
                        Esta sección es similar a la de Ingredientes en cuanto a su funcionamiento.
                    </p>

                    <p>
                        Para acceder a ella debemos ir al menú lateral y seleccionar Administración (imagen 32 .A) y luego Insumos (imagen 32 .B).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/57.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/57.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 32</p>
                    </div>

                    <p>
                        En esta sección como en la de ingredientes encontraremos un botón de agregar nuevo insumo (imagen 32 .C) y una lista con todos los insumos agregados en el sistema (imagen 32 .D) siendo que para cada uno de ellos podremos ver sus detalles (imagen 32 .E),
                        editarlos (imagen 32 .F) o eliminarlos (imagen 32 .G).
                    </p>

                    <h3>Agregar insumo</h3>

                    <p>
                        Al darle click al botón de Agregar nuevo (imagen 32 .C), se nos mostrara una página en donde nos pedirán los datos básicos del insumo.
                    </p>


                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/58.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/58.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <h3>Editar insumo</h3>

                    <p>
                        En esta página podremos modificar los datos básicos (imagen 33 .A) y también agregar nuevas líneas de compra del mismo (imagen 33 .B) o modificar las que ya tuviéramos si fuese necesario (imagen 33 .C).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/59.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/59.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 33</p>
                    </div>

                </article>
            </div>

        </section>

        <!-- Administración de Servicios -->
        <section id="sec14" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>6. Administración de Servicios</h2>

                    <p>
                        En esta sección manejaremos todo lo referente a servicios que tengamos en la empresa, como puedan ser contratación de mozos, servicios de energía eléctrica, gas, agua, alquileres, etc.
                    </p>

                    <p>
                        Para acceder a esta sección tendremos que ir al menú lateral, administración (imagen 34 .A) y luego a Servicios (imagen 34 .B).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/60.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/60.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 34</p>
                    </div>

                    <p>
                        El manejo de esta sección es similar a las anteriores encontrando un botón de crear nuevo servicio (imagen 34 .C) y una lista con todos los servicios del sistema (imagen 34 .D) contando para cada línea con las opciones de detalles, edición y eliminación.
                    </p>

                    <h3>Agregar nuevo servicio</h3>

                    <p>
                        Para agregar nuevo servicio haremos click en el botón Crear nuevo (imagen 34 .C) lo cual nos llevará a la página en donde se nos pedirá los datos básicos del mismo.
                    </p>


                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/61.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/61.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        Así como en las anteriores, si no encontramos el proveedor tendremos que ir a agregarlo primero a Administración/Proveedores
                    </p>

                    <h3>Editar servicio</h3>

                    <p>
                        En esta página podremos editar los datos básicos del servicio (imagen 35 .A), como también agregar nuevas líneas del mismo (imagen 35 .B), por ejemplo, si nuestro servicio fuera Energía eléctrica podríamos agregar una línea por cada factura que recibiéramos.
                    </p>

                    <p>
                        Y encontraremos una lista con todas las líneas de servicio agregadas (imagen 35 .B) con la posibilidad de editarlas o eliminarlas.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/62.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/62.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 35</p>
                    </div>

                </article>
            </div>

        </section>

        <!-- Administración de Proveedores -->
        <section id="sec15" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>7. Administración de Proveedores</h2>

                    <p>
                        En esta sección encontraremos todos los proveedores agregados en el sistema, los cuales son necesarios para la creación de ingredientes, insumos y servicios.
                    </p>

                    <p>
                        Para ingresar en esta sección tendremos que ir al menú lateral, luego Administración (imagen 36 .A) y por último a Proveedores (imagen 36 .B).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/63.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/63.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 36</p>
                    </div>

                    <p>
                        Ya en la página de Administración de proveedores nos encontraremos con lo normal. Un botón de crear nuevo (imagen 36 .C) y una lista con todos los proveedores agregados en el sistema (imagen 36 .D) junto con sus botones de detalles, edición y eliminación
                        para cada uno.
                    </p>

                    <h3>Crear proveedor</h3>

                    <p>
                        Al darle click al botón de Crear nuevo (imagen 36 .C), iremos a la página donde nos pedirán los datos básicos del proveedor.
                    </p>


                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/64.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/64.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <h3>Detalles del proveedor</h3>

                    <p>
                        En esta sección encontraremos todos los datos de compras para el proveedor seleccionado, tanto sus datos básicos, como tablas de ingredientes, insumos o servicios del mismo con sus respectivas gráficas, pudiendo ser filtradas por mes y año.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/65.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/65.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                    <p>
                        En cada una de las tablas tendremos un botón de detalles (imagen 37 .A) de las compras la cual nos dará una evolución de precios del ingrediente, insumo o servicio seleccionado.
                    </p>

                    <div class="row justify-content-center">

                        <div class="div-imagenes">
                            <a href="assets/images/img-lunch/66.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/66.jpg" alt="SURSOFT -  Lunch Box Demo">
                            </a>
                            <p>Imagen 37</p>
                        </div>

                        <div class="div-imagenes">
                            <a href="assets/images/img-lunch/67.jpg" data-lightbox="photos" class="img-container p-3">
                                <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/67.jpg" alt="SURSOFT -  Lunch Box Demo">
                            </a>
                        </div>
                    </div>

                    <p>
                        Nota: en este caso la gráfica no nos da mucha información ya que solo contamos con 1 dato de prueba. A medida que se tengan más datos la gráfica será más precisa y permitirá tener una prospección de el rumbo de precios de ese proveedor sobre ese ingrediente,
                        insumo o servicio.
                    </p>


                </article>
            </div>

        </section>

        <!-- Presupuestos -->
        <section id="sec16" class="bg-blanco">
            <div class="container padding-sec">

                <h2 class="h2-section">Presupuestos</h2>

                <article class="color-article">

                    <h2>Introducción</h2>

                    <p>
                        En esta sección del sistema tendremos la posibilidad de crear un presupuesto, pudiendo incluir en el mismo cualquier producto, ingrediente, insumo o servicio que tengamos en el sistema, generando así automáticamente un presupuesto con el detalle de todos
                        los aspectos del mismo, para finalmente ser enviado al cliente para su aprobación.
                    </p>

                    <p>
                        Para acceder a esta sección debemos ir al menú lateral y hacer click en Presupuestos (imagen 37 .A).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/68.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/68.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 37</p>
                    </div>

                    <p>
                        Ya en la página de administración de presupuestos encontraremos un botón de Crear Nuevo (imagen 37 .B), También encontraremos una lista de los presupuestos que existen en el sistema (imagen 37 .C).
                    </p>

                    <p>
                        En cada una de las líneas de la lista (es decir, por cada presupuesto) encontraremos una serie de botones que describiremos a continuación
                    </p>

                    <ul>
                        <li>
                            <b>Botón de Confirmación (imagen 37 .D):</b>con este botón podremos marcar el presupuesto como confirmado, lo cual habilitara (en la sección de Días de elaboración) a todos los productos del mismo para que se puedan incluir
                            en días de elaboración.
                        </li>
                        <li>
                            <b>Botón de lista de ítems (imagen 37 .E):</b>con este botón accederemos a una página (que detallaremos más adelante) en la que tendremos toda la información sobre los ingredientes e insumos necesarios para la confección de
                            todos los productos del presupuesto en total. Debemos tener en cuenta que para que esto funcione las recetas de los productos deben estar realizadas para el correcto conteo del stock de cada ingrediente requerido.
                        </li>
                        <li>
                            <b>Botón de CheckList (imagen 37 .F):</b>este botón nos proporciona una lista con los ítems del presupuesto para ir marcando a medida que cada uno este realmente pronto y poder estar seguro el día antes al evento que todo está
                            en orden. Cabe destacar que esta lista no tiene impacto en ningún aspecto del presupuesto, es solo una lista de control para el usuario.
                        </li>
                        <li>
                            <b>Botón de Factura (imagen 37 .G):</b>este botón nos llevara a la página de factura en donde tendremos la información de cantidades y precios finales de cada ítem incluido en el presupuesto. Es desde aquí donde podremos crear
                            el pdf para poder enviar al cliente para su estudio y posterior confirmación (esta página se detallará más adelante).
                        </li>
                        <li>
                            <b>Botón de Edición (imagen 37 .H):</b>este es uno de los botones más importantes, que nos dirige a la página de edición del presupuesto, en donde podremos agregar todos los ítems del mismo, ingredientes, productos, insumos
                            y servicios, así como modificar los datos básicos del presupuesto. Detallaremos esta página más adelante.
                        </li>
                        <li>
                            <b>Botón de Eliminar (imagen 37 .I):</b> Como indica su nombre este botón nos permite eliminar el presupuesto del sistema siempre y cuando el mismo NO este confirmado.
                        </li>
                    </ul>

                </article>
            </div>

        </section>

        <!-- Lista de Stocks -->
        <section id="sec17" class="bg-gris">
            <div class="container padding-sec">


                <article class="color-article">

                    <h2>Lista de Stocks</h2>

                    <p>
                        Como mencionamos anteriormente, esta página es accedida a través del botón Lista de ítems (imagen 37 .E) .
                    </p>

                    <p>
                        En ella encontraremos un par de listas, las cuales nos indicaran los ingredientes e insumos necesarios para la realización de los productos del evento, marcando al confirmar el mismo si hay faltante de stock de alguno de ellos en la misma.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/69.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/69.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 38</p>
                    </div>

                    <p>
                        En la parte superior vemos 3 botones, uno para volver a la lista de presupuesto, otro para ir a la edición de este presupuesto (imagen 38 .A) , y un botón de cancelar o confirmar el presupuesto (imagen 38 .B).
                    </p>

                    <p>
                        Mas abajo encontramos el nombre del cliente que solicita el presupuesto y un botón con los detalles del mismo (imagen 38 .C).
                    </p>

                    <p>
                        Y por último nos encontramos con la lista de ingredientes (imagen 38 .D) e insumos (imagen 38 .E) necesarios para llevar a cabo el evento. Estos ítems provienen tanto de las recetas de los productos como de los insumos e ingredientes individuales que
                        se agreguen en el presupuesto.
                    </p>

                    <p>
                        Nótese que si hay faltante de stock el mismo será marcado en rojo junto con la cantidad faltante (imagen 38 .F). Hay que tener en cuenta que esto SOLO es posible de ver si el presupuesto está confirmado ya que es en ese momento donde se realizan las actualizaciones
                        de stock en esas categorías.
                    </p>

                </article>
            </div>

        </section>

        <!-- Lista de Stocks -->
        <section id="sec18" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Check List</h2>

                    <p>
                        En esta página encontraremos como describimos anteriormente una lista con todas las cosas que ingresamos en el presupuesto del evento.
                    </p>

                    <p>
                        Esto servirá para la organización a la hora de hacer el inventario del evento y comprobar que todo lo necesario esté presente.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/70.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/70.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                </article>
            </div>

        </section>

        <!-- Factura del presupuesto -->
        <section id="sec19" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Factura del presupuesto</h2>

                    <p>
                        En esta página veremos un resumen de todos los costos del presupuesto junto con los datos del cliente.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/71.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/71.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 39</p>
                    </div>

                    <p>
                        En la parte superior encontramos una serie de botones:
                    </p>

                    <ul>
                        <li>
                            <b>Botón de Editar (imagen 39 .A)</b>: con este botón iremos a la página de edición del presupuesto, la cual detallaremos más adelante.
                        </li>
                        <li>
                            <b>Botón Crear Pdf (imagen 39 .B):</b>con este botón podremos generar un pdf de la factura para poder enviar al cliente y el mismo cuente con toda la información necesaria para decidir sobre la confirmación del mismo.
                        </li>
                        <li>
                            <b>Botón Productos (imagen 39 .A):</b>con este botón podremos acceder a la lista de productos del presupuesto. El objetivo de la misma es darle la posibilidad al administrador que en caso de errores en el marcado de preparación
                            o preparado, se pueda reestablecer el estado de los productos en dichos campos.
                        </li>
                    </ul>

                    <p>
                        Por último, encontramos los datos del cliente (imagen 39 .D), junto con el detalle de la factura para el presupuesto (imagen 39 .E).
                    </p>

                </article>
            </div>

        </section>

        <!-- Edición del presupuesto -->
        <section id="sec20" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Edición del presupuesto</h2>

                    <p>
                        Esta página es el corazón del presupuesto, ya que en ella es donde se agregarán tanto productos como ingredientes, insumos y servicios al mismo.
                    </p>

                    <p>
                        Cabe destacar que cada una de las cosas que se deseen agregar a un presupuesto, tendrán que haber sido ingresadas en el sistema en sus respectivas páginas de administración, por lo que si, por ejemplo, no se encuentra un ingrediente deberá ir a Administración/Ingredientes
                        y agregar ahí el mismo para luego volver a agregarlo en el presupuesto.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/72.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/72.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 40</p>
                    </div>

                    <p>
                        En la parte superior de la página encontraremos un resumen del presupuesto en general (imagen 40 .A) en donde se marcaran el precio del presupuesto, el descuento (si lo hubiera) y el precio final del mismo.
                    </p>

                    <p>
                        Luego encontramos una serie de botones:
                    </p>

                    <ul>
                        <li>
                            <b>Botón de Volver (imagen 40 .B):</b> con este botón se regresa a la lista de todos los presupuestos.
                        </li>
                        <li>
                            <b>Botón Factura (imagen 40 .C):</b> este botón es un acceso rápido a la página de factura que vimos anteriormente.
                        </li>
                        <li>
                            <b>Botón Ítems (imagen 40 .D):</b> este botón nos dirige a la página de Stocks requeridos para el presupuesto que también vimos en apartados anteriores.
                        </li>
                        <li>
                            <b>Botón Reiniciar (imagen 40 .E):</b> con este botón se borraran todos los productos, ingredientes, insumos y servicios agregados al presupuesto. Tener en cuenta que solo se podrá reiniciar el presupuesto si el mismo NO está
                            confirmado.
                        </li>
                    </ul>

                    <h3><em><u>Editar datos básicos</u></em></h3>

                    <p>
                        Debajo de los botones anteriores tendremos una pestaña (imagen 41 .A) que al ser desplegada nos brindara toda la información básica del presupuesto, la cual podremos editar.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/73.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/73.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 41</p>
                    </div>

                    <p>
                        En esta sección es donde podremos agregar un descuento al presupuesto si así lo desea el administrador (imagen 41 .B).
                    </p>

                    <p>
                        Una vez editado los cambios de los datos básicos tendrá que darle click al botón guardar para que los cambios tengan efecto (imagen 41 .C).
                    </p>

                    <p>
                        Inmediatamente después de los datos básicos encontramos la sección de agregado de productos (imagen 40 .G), ingredientes (imagen 40 .H), insumos (imagen 40 .I) y servicios (imagen 40 .J).
                    </p>

                    <h3><em><u>Editar productos</u></em></h3>

                    <p>
                        En esta pestaña (imagen 40 .G) encontramos primero un resumen con la cantidad de productos agregados junto con el precio total de los mismo (imagen 40 .K).
                    </p>

                    <p>
                        Debajo de esta información general encontraremos dos listas:
                    </p>

                    <ul>
                        <li>
                            <b>Productos Agregados (imagen 42 .A):</b>n esta lista encontraremos todos los productos agregados.
                            <div class="div-imagenes">
                                <a href="assets/images/img-lunch/74.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/74.jpg" alt="SURSOFT -  Lunch Box Demo">
                                </a>
                                <p>Imagen 42</p>
                            </div>

                            <p>
                                Esta lista nos proporcionara por cada producto el precio unitario del mismo y el precio total (imagen 42 .B) , y también podremos modificar el mismo agregando alguna aclaración (imagen 42 .C) o el cambio de cantidad del mismo (imagen 42 .D).
                            </p>
                            <p>
                                Para efectuar estos cambios deberemos hacer click en el botón de editar (imagen 42 .E). Si queremos eliminarlo del presupuesto simplemente haremos click en el botón de eliminar (imagen 42 .F).
                            </p>
                        </li>

                        <li>
                            <b>Agregar Productos (imagen 40 .M):</b>en esta lista encontraremos todos los productos del sistema, los cuales podremos agregar a los presupuestos.
                            <div class="div-imagenes">
                                <a href="assets/images/img-lunch/75.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/75.jpg" alt="SURSOFT -  Lunch Box Demo">
                                </a>
                            </div>
                        </li>
                    </ul>

                    <h3> <em><u>Editar ingredientes</u></em></h3>

                    <p>
                        En esta pestaña podremos agregar ingredientes particulares al presupuesto.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/76.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/76.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 43</p>
                    </div>

                    <p>
                        El funcionamiento es similar al anterior descripto, en donde encontramos un resumen general con la cantidad de ingredientes agregados y el precio total de los mismos (imagen 43 .A).
                    </p>

                    <p>
                        Debajo del resumen encontramos las listas de:
                    </p>

                    <ul>
                        <li>
                            <b>Ingredientes Agregados (imagen 43 .B):</b> en esta lista encontraremos los ingredientes agregados al presupuesto, donde se nos dará la información sobre la línea de compra seleccionada para el mismo y el precio final calculado
                            según la cantidad indicada (imagen 44 .A).

                            <p>También podremos editar la cantidad modificando su valor (imagen 44 .B) y haciendo click en el botón de editar (imagen 44 .C) o eliminar el ingrediente de la lista (imagen 44 .D).</p>

                            <div class="div-imagenes">
                                <a href="assets/images/img-lunch/77.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/77.jpg" alt="SURSOFT -  Lunch Box Demo">
                                </a>
                                <p>Imagen 44</p>
                            </div>
                        </li>
                        <li>
                            <b>Agregar Ingredientes (imagen 43 .C):</b> en esta lista encontraremos todos los ingredientes disponibles del sistema.
                            <p>
                                Aquí deberemos ingresar la cantidad del mismo (imagen 45 .A) y seleccionar la línea de compra (imagen 45 .B) para facilitar un precio de referencia para el mismo y poder agregarlos mediante el botón agregar (imagen 45 .C).
                            </p>

                            <div class="div-imagenes">
                                <a href="assets/images/img-lunch/78.jpg" data-lightbox="photos" class="img-container p-3">
                                    <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/78.jpg" alt="SURSOFT -  Lunch Box Demo">
                                </a>
                                <p>Imagen 45</p>
                            </div>
                        </li>
                    </ul>

                    <h3> <em><u>Editar insumos</u></em></h3>

                    <p>
                        Esta pestaña es similar a la de ingredientes y cuenta con las mismas funcionalidades.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/79.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/79.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 46</p>
                    </div>

                    <p>
                        Un resumen general de cantidad y precio de los insumos agregados (imagen 46 .A) y dos listas:
                    </p>

                    <ul>
                        <li>
                            <b>Insumos Agregados (imagen 46 .B):</b> esta lista presenta todos los insumos que agregamos al presupuesto con el mismo formato de los ingredientes.
                        </li>
                        <li>
                            <b>Agregar Insumos (imagen 46 .C):</b> en esta lista encontramos todos los insumos disponibles en el sistema, los cuales podemos agregar al presupuesto ingresando la cantidad y la línea de compra para establecer el precio de
                            referencia para los mismos.
                        </li>
                    </ul>

                    <h3><em><u>Editar Servicios</u></em> </h3>

                    <p>
                        En esta pestaña podremos manejar los servicios agregados y para agregar del presupuesto.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/90.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/90.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 47</p>
                    </div>

                    <p>
                        Como vemos el formato es similar a los anteriores descriptos en donde vemos un resumen general con la cantidad de servicios agregados y el precio de los mismos (imagen 47 .A).
                    </p>

                    <p>
                        Luego encontramos dos pestañas en donde tendremos las listas:
                    </p>

                    <ul>
                        <li>
                            <b>Servicios Agregados (imagen 47 .B):</b> aquí encontraremos el detalle de los servicios agregados al presupuesto y la posibilidad de modificar los mismos o eliminarlos.
                        </li>
                        <li>
                            <b>Agregar Servicios (imagen 47 .C):</b> en esta lista encontraremos todos los servicios disponibles para ser agregados en el presupuesto.
                        </li>
                    </ul>

                </article>
            </div>

        </section>

        <!-- Dias de elaboración - intro -->
        <section id="sec21" class="bg-gris">
            <div class="container padding-sec">

                <h2 class="h2-section">Dias de elaboración</h2>

                <article class="color-article">

                    <h2>Introducción</h2>

                    <p>
                        En esta sección podremos indicar que día se deberán preparar los productos, tanto de ordenes comunes como de productos del presupuesto.
                    </p>

                    <p>
                        Es importante destacar que para que los empleados vean en la lista de pedidos para preparar los productos, es necesario que se indiquen los días de elaboración de los mismos en esta sección por parte de los administradores, de lo contrario los empleaos
                        NO tendrán acceso a los productos que preparar en el día.
                    </p>

                    <p>
                        También debemos decir que los días de elaboración se pueden indicar con antelación y en diferentes días, los cuales irán apareciendo en la lista de los empleados según el día indicado, pudiendo planificar el trabajo de la semana en un día por parte del
                        administrador y olvidarse de ello, siendo que irán apareciendo los trabajos automáticamente según el día.
                    </p>

                    <p>
                        Para acceder a esta sección iremos al menú lateral y haremos click en Días de elaboración (imagen 48 .A).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/80.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/80.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 48</p>
                    </div>

                </article>
            </div>

        </section>

        <!-- Dias de elaboración - ordenes comunes -->
        <section id="sec22" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Ordenes comunes</h2>

                    <p>
                        En esta pestaña encontraremos una lista con todos los productos de pedidos comunes que no han sido preparados.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/81.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/81.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 49</p>
                    </div>

                    <p>
                        En la lista tendremos el nombre del cliente, la fecha de entrega de la orden y los productos de la misma.
                    </p>

                    <p>
                        Por cada producto podremos indicar su día de elaboración (imagen 49 .A) asignando la fecha correspondiente y haciendo click en el botón de guardar (imagen 49 .B). También podremos eliminar dicha fecha (imagen 49 .C) siempre y cuando el producto no se
                        encuentre ya en proceso de elaboración.
                    </p>

                    <p>
                        Si el pedido tuviera varios productos se mostrará una línea extra para asignar de manera general la fecha a todos los productos del pedido. Esta variante la mostraremos en la siguiente pestaña.
                    </p>

                </article>
            </div>

        </section>

        <!-- Dias de elaboración - ordenes Presupuestos -->
        <section id="sec23" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Ordenes de Presupuestos</h2>

                    <p>
                        En esta pestaña encontraremos la lista con todos los productos de eventos que fueron confirmados y que no han sido preparados.
                    </p>

                    <p>
                        Remarcamos que para que los productos aparezcan en esta lista el presupuesto del evento debe estar marcado como Confirmado.
                    </p>

                    <p>
                        Al igual que en la anterior lista podremos asignar de manera individual los días de elaboración de cada producto (imagen 50 .B) o de manera general asignarles una fecha a todos los productos (imagen 50 .A).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/82.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/82.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagen 50</p>
                    </div>

                </article>
            </div>

        </section>

        <!-- Estadisticas - intro -->
        <section id="sec24" class="bg-blanco">
            <div class="container padding-sec">

                <h2 class="h2-section">Estadisticas</h2>

                <article class="color-article">

                    <h2>Introducción</h2>

                    <p>
                        En esta página podremos ver tablas y graficas generales referentes a los números de la empresa en diferentes categorías.
                    </p>

                    <p>
                        Para acceder a esta sección iremos al menú lateral y haremos click en Estadísticas.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/83.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/83.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                </article>
            </div>

        </section>

        <!-- Estadisticas - Resumen Financiero -->
        <section id="sec25" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Resumen Financiero</h2>

                    <p>
                        En esta sección podremos ver una tabla con el resumen de Gasto, ingresos, Ganancias y porcentaje para el desarrollador por mes (imagen 51 .B), junto con una gráfica correspondiente a la misma (imagen 51 .C).
                    </p>

                    <p>
                        Debajo de esta encontraremos otra tabla en donde se detalla los gastos referentes a Sueldos, ingredientes, insumos y servicios (imagen 51 .D), junto con una gráfica con los valores totales (imagen 51 .E).
                    </p>

                    <p>
                        Estos datos pueden ser filtrados por mes y años (imagen 51 .A).
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/84.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/84.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                        <p>Imagén 51</p>
                    </div>

                </article>
            </div>

        </section>

        <!-- Estadisticas - Más vendidos -->
        <section id="sec26" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Más vendidos</h2>

                    <p>
                        En esta página encontraremos una tabla y una gráfica con el top 10 de los productos más vendidos en ordenes comunes pudiendo ser filtrados por mes y año.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/85.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/85.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                </article>
            </div>

        </section>

        <!-- Estadisticas - Más pedidos en Eventos -->
        <section id="sec27" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Más pedidos en Eventos</h2>

                    <p>
                        Al igual que la anterior, aquí encontraremos una lista del top 10 de productos más pedidos en presupuestos de eventos, junto con una gráfica de los mismos.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/86.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/86.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                </article>
            </div>

        </section>

        <!-- Estadisticas - Productividad de empleados -->
        <section id="sec28" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Productividad de empleados</h2>

                    <p>
                        En esta página veremos una tabla con los trabajos realizados por los empleados según lo registrado por el sistema dándole la posibilidad al administrador de ver los desempeños de los mismos, así como el detalle de cada uno en cuanto a que productos son
                        los que preparan.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/87.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/87.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                </article>
            </div>

        </section>

        <!-- Estadisticas - Ventas por mes -->
        <section id="sec29" class="bg-gris">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Ventas por mes</h2>

                    <p>
                        En esta sección podemos ver una tabla con la cantidad de ventas por mes de la empresa junto con una gráfica para la prospección de las mismas.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/88.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/88.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                </article>
            </div>

        </section>

        <!-- Estadisticas - Opiniones de usuarios -->
        <section id="sec30" class="bg-blanco">
            <div class="container padding-sec">

                <article class="color-article">

                    <h2>Opiniones de usuarios</h2>

                    <p>
                        En esta página podremos ver el feedback que nos dan los usuarios, calificaciones de los mismos y detalles de sus comentarios para ayudar a mejor el servicio.
                    </p>

                    <div class="div-imagenes">
                        <a href="assets/images/img-lunch/89.jpg" data-lightbox="photos" class="img-container p-3">
                            <img class="img-thumbnail img-articulo z-depth-1 mb-3" src="assets/images/img-lunch/89.jpg" alt="SURSOFT -  Lunch Box Demo">
                        </a>
                    </div>

                </article>
            </div>

        </section>

    </mat-drawer-content>
</mat-drawer-container>