import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel2',
  templateUrl: './carousel2.component.html',
  styleUrls: ['./carousel2.component.css']
})
export class Carousel2Component implements OnInit {

  @Input() carouselId:string ="";
  @Input() carHeight:string ="400px";
  
  //formato: {slideNumber:0, image: 'assets/carousel/1.jpg', isActive: 'active', titulo: 'Un titulo de slide', descripcion: 'una descripcion'}
  @Input() slides: string[] = [];

  carouselTarget: string;

  constructor() { }

  ngOnInit(): void {
    this.carouselTarget= "#" + this.carouselId;
  }

}
